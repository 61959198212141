import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'
import { ErrorMessage, Select } from '../../elements'
import client from '../../api/api'

class States extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      states: [],
    }
  }

  componentDidMount() {
    this.getStates()
  }

  getStates = async () => {
    const { data } = await client.fetcher.fetch({
      method: 'GET',
      url: 'api/v1/states/shipping',
    })

    this.setState({
      states: data,
    })
  }

  render() {
    const { disabled=false, errorTranslationId, onChange, responseFieldName='abbr', value } = this.props

    return (
      <Form.Group controlId="state">
        <Form.Label md="4">
          <FormattedMessage id="state" />*
        </Form.Label>

        <Select
          as="select"
          className={(disabled ? 'form-control disabled' : '') || (errorTranslationId ? 'invalid-form-control' : '')}
          disabled={disabled}
          id="state"
          name="state"
          onChange={onChange}
          value={value}
        >
          <FormattedMessage id="pleaseSelect">
            {translatedMessage => (
              <option value="">{translatedMessage}</option>
            )}
          </FormattedMessage>

          {this.state.states.map((state) => (
            <option key={state.id} value={state[responseFieldName]}>{state.name}</option>
          ))}
        </Select>

        {errorTranslationId &&
          <ErrorMessage error={errorTranslationId} />
        }
      </Form.Group>
    )
  }
}

States.propTypes = {
  disabled: PropTypes.bool,
  errorTranslationId: PropTypes.string,
  onChange: PropTypes.func,
  responseFieldName: PropTypes.string,
  value: PropTypes.string,
}

export default States
