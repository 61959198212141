import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ResizeConsumer } from '../../modules'

export class Hide extends PureComponent {
  shouldRender = (innerWidth) => {
    const { above, below }= this.props

    return (
      innerWidth >= below || innerWidth < above
    )
  }

  render() {
    const { children } = this.props

    return (
      <ResizeConsumer>
        {({ innerWidth }) => this.shouldRender(innerWidth) ? children : null}
      </ResizeConsumer>
    )
  }
}

Hide.propTypes = {
  above: PropTypes.number,
  below: PropTypes.number,
  children: PropTypes.node,
}

export default Hide
