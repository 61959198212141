import { isEmail } from 'validator'
import { isEmpty } from '../lodash'

export const isValidEmail = (value) => !isEmpty(value) && isEmail(value)

export const isValidZipCode = (value) => {
  const zipCodeRegexp = new RegExp(/(^[0-9]{5}$)|(^[0-9]{5}-[0-9]{4}$)/)

  return zipCodeRegexp.test(value)
}

export const passwordValidator = (value) => {
  const validation = {
    isLength: value.length >= 8,
    isLower: /[a-z]/.test(value),
    isNumber: /[0-9]/.test(value),
    isUpper: /[A-Z]/.test(value),
  }

  const isValidPassword = validation.isLower &&
    validation.isUpper &&
    validation.isNumber &&
    validation.isLength &&
    !isEmpty(value)

  return {
    isValidPassword,
    ...validation,
  }
}
