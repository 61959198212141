import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider } from 'react-intl'
import translations from './translations'
import strapi from '../../api/strapi'

const LocaleSwitchProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [locale] = useState('en-US')
  const [localizedMessages, setLocalizedMessages] = useState(translations[locale])

  useEffect(() => {
    const fetchTranslations = async () => {
      const { data } = await strapi.get(`/translation?locale=${locale}`)

      if (data) {
        setLocalizedMessages(oldState => Object.assign(oldState, data.data.attributes.translation))
      }

      setIsLoading(false)
    }

    fetchTranslations()
  }, [])

  if (isLoading) return false

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={localizedMessages}
    >
      {children}
    </IntlProvider>
  )
}

LocaleSwitchProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocaleSwitchProvider
