import styled from 'styled-components'
import { border, width } from 'styled-system'

const Separator = styled.hr`
  ${border}
  ${width}

  margin-left: 0;
  border-color: ${props => props.borderColor || 'primary.main'};
`

Separator.displayName = 'Separator'

export default Separator
