import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, Text } from '../../elements'
import { TranslatedMetaLoader, TranslatedTitleLoader } from '../../modules'
import images from '../../../assets/images/newcb'

export const ProductsPage = () => (
  <>
    <Container className="mt-3 mb-5">

      <TranslatedTitleLoader titleId="pageTitleProducts" />

      <TranslatedMetaLoader
        metaArray={[
          { content: 'metaTextProducts', name: 'description' },
          { content: 'metaTextProducts', property: 'og:description' },
          { content: 'pageTitleProducts', property: 'og:title' },
        ]}
      />

      <Row className="products-page">
        <Col md="12">
          <Text as="h2" mt={4} textAlign="center">
            <FormattedMessage id="thankYouFans" />
          </Text>

          <Box lineHeight="18px" my={['20px', '40px']} px={[0, 3]} textAlign="center">
            <FormattedMessage id="thankYouFansText" />
          </Box>
          <Box mt={4} textAlign="center">
            <Text fontSize={2} fontWeight="bold">
              <FormattedMessage id="endLoopDiaperText1" />
            </Text>
            <Text fontSize={2} fontWeight="bold">
              <FormattedMessage id="endLoopDiaperText2" />
            </Text>
          </Box>
          <ExtraBox>
            <Text fontSize={2} fontWeight="bold" textAlign="center">
              <FormattedMessage id="thankYou" />
            </Text>
          </ExtraBox>
        </Col>
      </Row>

      <Row className="justify-content-md-center">

      </Row>
    </Container>
  </>
)

const ExtraBox = styled(Box)`
  background: url(${images.bannerExtraTextBg});
  background-size: contain;  
  background-repeat: no-repeat;
  background-position: center center;
  padding: 40px;
  `

export default ProductsPage
