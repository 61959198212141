import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Loading } from '../../../components'
import { Box, Button, Heading } from '../../../elements'
import { TranslatedTitleLoader } from '../../../modules'
import { trackEvent } from '../../../utils'
import strapi from '../../../api/strapi'

export const SchedulePickup = () => {
  const [isSchedulePickUpLoading, setSchedulePickUpLoading] = useState(true)
  const [strapiSchedulePickUpPage, setSchedulePickUpPage] = useState(null)

  useEffect(() => {
    const fetchPickUpPage = async () => {
      const { data } = await strapi.get('/schedule-pick-up')

      if (data) {
        setSchedulePickUpPage(data.data.attributes)
      }

      setSchedulePickUpLoading(false)
    }

    fetchPickUpPage()
  }, [])

  if (isSchedulePickUpLoading)
    return (<Loading />)

  return (
    <Row id="user-menu-pickup-schedule">
      <TranslatedTitleLoader titleId="pageTitleUserScheduleAPickup" />

      <Col>
        <Heading className="schdule-pick-up-page-title" fontSize="2.3rem" mb={3} textAlign="left">
          <FormattedMessage id="schedulePickup" />
        </Heading>

        <Box border="1px solid #F3ECDE" mb={4} px={4} py={3}>
          <BlocksRenderer content={strapiSchedulePickUpPage?.content} />
        </Box>

        {strapiSchedulePickUpPage?.showPickUpButton && (
          <form action="https://row.ups.com/GuestAccess/Guest/Access" method="POST" target="_blank">
            <input name="Company" type="hidden" value="VEVSUkFDWUNMRQ==" />

            <Button
              onClick={() => {trackEvent('User', 'Clicked to the pick up button', 'User Page - Schedule a pickup')}}
              type="submit"
            >
              <FormattedMessage id="clickHereToScheduleYourPickUp" />
            </Button>
          </form>
        )}
      </Col>
    </Row>
  )
}

const TransparentButton = styled.button`
  background: transparent;
  border: none;
  padding-left: 0;
  color: ${props => props.theme.colors.primary.main};
`

TransparentButton.displayName = 'TransparentButton'

export default SchedulePickup
