import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { Col, Container, Row } from 'react-bootstrap'
import { Loading } from '../../components'
import { Arrow, Box, ContentBox, Heading, HeadingInfoBox, HeadingWithBg, Hide } from '../../elements'
import images from '../../../assets/images/newcb'
import strapi from '../../api/strapi'

const HowItWorks = () => {
  const [isOurMissionLoading, setOurMissionLoading] = useState(true)
  const [strapiOurMissionPage, setStrapiOurMissionPage] = useState(null)

  useEffect(() => {
    const fetchOurMissionPage = async () => {
      const { data } = await strapi.get('/our-mission?populate[ourMissionList][populate]=*&populate[ourMissionList][image][populate]=*&populate[content][image][populate]=*&populate[content][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiOurMissionPage(data.data.attributes)
      }

      setOurMissionLoading(false)
    }

    fetchOurMissionPage()
  }, [])

  if (isOurMissionLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiOurMissionPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiOurMissionPage.MetaData?.metaText , name: 'description' },
            { content: strapiOurMissionPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiOurMissionPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiOurMissionPage.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container">
        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBgV4"
          mb={[70, 50]}
          ml={[0, 200]}
          mt={[20, 50]}
          p="40px"
          position="relative"
          textAlign="center"
        >
          <FormattedMessage id="ourMission" />

          <Hide below={767}>
            <CustomBox color="gray.dark" width={400}>
              <HeadingInfoBox fontSize={1} ml={20} px={70}>
                {strapiOurMissionPage?.subTitle}
              </HeadingInfoBox>
            </CustomBox>
          </Hide>
        </HeadingWithBg>

        <Hide above={767}>
          <CustomMobileBox color="gray.dark" mt={-70} mx="auto">
            <HeadingInfoBox fontSize={2} px={80}>
              {strapiOurMissionPage?.subTitle}
            </HeadingInfoBox>
          </CustomMobileBox>
        </Hide>

        <ModifiedImageBox mt={[0, -40, -40, -40, -90]}>
          <ContentBox content={strapiOurMissionPage?.content} isLoading={isOurMissionLoading} type="imageAndText" />
        </ModifiedImageBox>

        <Row>
          <Col md={12}>
            <Heading as="h4" color="black" textAlign="center">
              <FormattedMessage id="heresHowWeMakeItEasy" />
              <Arrow mt={30} mx="auto" />
            </Heading>
          </Col>

          <Col md={12}>
            <Box className="line2">
              <ContentBox content={strapiOurMissionPage?.ourMissionList} custom={{ boxBg:'white' }} isLoading={isOurMissionLoading} type="shadowCard" />
            </Box>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )
}

const CustomMobileBox = styled(Box)`
  width: 400px;

  @media (max-width: 400px) {
    width: 100%;

    background-size: auto;
    background-repeat: round;
    background-position: 0 0;

    div {
      padding: 40px !important;
    }
  }
`

const ModifiedImageBox = styled(Box)`
  img {
    padding: 80px;
    margin-top: -120px;

    border-radius: 130px;
  }

  .text {
    margin-top: 100px !important; 
  }

  @media (max-width: 1200px) {
    .text {
      margin-top: 70px;  
    }

    img {
      margin-top: -270px;
    }

  @media (max-width: 767px) {
    .text {
      margin-top: 0;  
    }

    img {
      margin-top: 0;
      padding: 10px;
    }
  }
`

const CustomBox = styled(Box)`
  position: absolute;

  right: 80px;
  top: 50px;

  background-image: url(${props => images.curvedArrow});
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 20%;

  @media (max-width: 924px) {
   top: 60px;
  }

  @media (max-width: 1200px) {
    right: 0;
  }

`

export default HowItWorks
