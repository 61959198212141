import React from 'react'
import PropTypes from 'prop-types'
import Recaptcha from 'react-google-recaptcha'
import { isEmpty } from '../../lodash'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'
import { Box } from '../../elements'
import { withStoreConfig } from '../../modules'

export const GoogleCaptcha = ({ error, setCaptchaInstance, onChange, store }) => (
  store.isLoading
    ? null
    : (
      <Box id="recaptchaResponse" my={3}>
        <Recaptcha
          id="g-recaptcha"
          onChange={onChange}
          ref={e => setCaptchaInstance(e)}
          sitekey={store.config.captcha_site_key}
        />

        {!isEmpty(error) &&
          <Form.Control.Feedback type="invalid">
            <FormattedMessage id={error} />
          </Form.Control.Feedback>
        }
      </Box>
    )
)

GoogleCaptcha.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  setCaptchaInstance: PropTypes.func.isRequired,
  store: PropTypes.object,
}

export default withStoreConfig(GoogleCaptcha)
