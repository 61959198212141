import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from '../'
import { FormattedMessage } from 'react-intl'

import './checkbox.scss'

export const Checkbox = ({
  checked,
  disabled = false,
  errorTranslationId,
  isRequired,
  labelTextId = '',
  labelTextIdValue = {},
  name,
  onChange = () => {},
}) => (
  <FormattedMessage id={labelTextId} values={labelTextIdValue}>
    {translatedMessage => (
      <label className="checkbox">{translatedMessage}{isRequired ? '*' : ''}
        <input
          checked={checked}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onChange}
          type="checkbox"
        />
        <span className="checkmark"></span>

        {errorTranslationId &&
          <ErrorMessage error={errorTranslationId} />
        }
      </label>
    )}
  </FormattedMessage>
)

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  errorTranslationId: PropTypes.string,
  isRequired: PropTypes.bool,
  labelTextId: PropTypes.string,
  labelTextIdValue: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
}

export default Checkbox
