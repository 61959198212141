import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import ShadowedCardLayout from './shadowed-card-layout/ShadowedCardLayout'
import EqualLayout from './equal-layout/EqualLayout'
import { PopUpModal } from '../'

const ContentBox = ({ content = [], custom, isLoading, type }) => {
  if (isLoading)
    return <Skeleton count={10} />

  return (
    React.Children.toArray(content.map((nextElement, index) => {
      const isImgRight = nextElement.imagePosition === 'right'
      const flexDirection = isImgRight
        ? ['column-reverse', 'row']
        : ['column-reverse', 'row-reverse']

      switch (`${type}.${nextElement.__component}`) {
      case 'shadowCard.global.content-box':
        return (
          <ShadowedCardLayout
            content={nextElement.content}
            custom={custom}
            image={nextElement.image}
            isImgRight={isImgRight}
            title={nextElement.title}
            wrapperFlexProps={{ flexDirection }}
          />
        )
      case 'imageAndText.global.content-box':
        return (
          <EqualLayout
            content={nextElement.content}
            custom={custom}
            id={nextElement.id}
            image={nextElement.image}
            index={index + 1}
            isImgRight={isImgRight}
            title={nextElement.title}
            wrapperFlexProps={{ flexDirection }}
          />
        )
      case 'modal.global.content-box':
        return <PopUpModal content={nextElement} custom={custom} flexDirection={flexDirection} index={index + 1} isImgRight={isImgRight} />
      default:
        return null
      }
    }))
  )
}

ContentBox.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array,
  custom: PropTypes.object,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
}

export default ContentBox
