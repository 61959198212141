import React, { useState } from 'react'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Navigate, useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Container, Form, Row } from 'react-bootstrap'
import { isEmpty, isEqual } from '../../lodash'
import { GoogleCaptcha, PasswordInput, States, TextField } from '../../components'
import { Col, Button, Checkbox, Heading, Text } from '../../elements'
import { Auth, TranslatedMetaLoader, TranslatedTitleLoader, withProfile } from '../../modules'
import { addNotification, isValidEmail, passwordValidator, getBackendErrors, scrollToElement, trackEvent } from '../../utils'
import { userPropTypes } from '../../types'
import './signup.scss'
import client from '../../api/api'

export let recaptchaInstance

const TermsAndConditionsLink = () =>
  <a className="link-underline" href="/terms-and-conditions" rel="noreferrer noopener" target="_blank"><FormattedMessage id="termsAndConditions" /></a>

const PrivacyPolicyLink = () =>
  <a className="link-underline" href="/privacy-policy" rel="noreferrer noopener" target="_blank"><FormattedMessage id="privacyPolicy" /></a>

export const SignUpPage = ({ user }) => {
  let navigate = useNavigate()

  const [ isLoading, setIsLoading ] = useState(false)
  const [ formErrors, setFormErrors ] = useState(null)
  const [ formState, setFormState ] = useState({
    email: '',
    firstName: '',
    isTosAccepted: false,
    lastName: '',
    marketingResearch: false,
    newsletter: false,
    password: '',
    passwordConfirmation: '',
    recaptchaResponse: null,
    state: '',
  })

  if(user.me)
    return <Navigate replace to="/" />

  const setErrors = (errors) => {
    setFormErrors(errors)

    if (!isEmpty(errors))
      scrollToElement(Object.keys(errors)[0], 'aboveElement')
  }

  const isValidForm = () => {
    const {
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      recaptchaResponse,
      state,
      isTosAccepted,
    } = formState
    const errors = {}

    if (isEmpty(firstName)) {
      errors.firstName = 'blank'
    }

    if (isEmpty(lastName)) {
      errors.lastName = 'blank'
    }

    if (!isValidEmail(email)) {
      errors.email = 'emailIsInvalid'
    }

    if (isEmpty(password)) {
      errors.password = 'blank'
    }

    if (!isEmpty(password) && !passwordValidator(password).isValidPassword) {
      errors.password = 'passwordIsInvalid'
    }

    if (isEmpty(passwordConfirmation)) {
      errors.passwordConfirmation = 'blank'
    }

    if (!isEmpty(password) && !isEqual(password, passwordConfirmation)) {
      errors.passwordConfirmation = 'passwordDoesntMatch'
    }

    if (isEmpty(state)) {
      errors.state = 'blank'
    }

    if (!isTosAccepted) {
      errors.isTosAccepted = 'blank'
    }

    if (!recaptchaResponse) {
      errors.recaptchaResponse = 'blank'
    }

    setErrors(errors)

    return isEmpty(errors)
  }

  const handleInputChange = (event) => {
    const { checked, maxLength, name, type, value } = event.target

    const newValue = (type ==='text' && maxLength)
      ? value.slice(0, maxLength)
      : value

    setFormState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : newValue,
    }))
  }

  const setCaptchaToken = (token) => {
    setFormState(prevState => ({
      ...prevState,
      recaptchaResponse: token,
    }))
  }

  const handleClick = (event) => {
    event.preventDefault()
    setIsLoading(true)
    setErrors(null)

    const {
      email,
      firstName,
      lastName,
      marketingResearch,
      newsletter,
      password,
      passwordConfirmation,
      recaptchaResponse,
      state,
      isTosAccepted,
    } = formState

    if (!isValidForm()) {
      setIsLoading(false)

      return false
    }

    client.account.create({
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        marketing_research: marketingResearch,
        newsletter,
        password,
        password_confirmation: passwordConfirmation,
        recaptcha_response: recaptchaResponse,
        state_id: state,
        tos: isTosAccepted,
      },
    })
      .then((response) => {
        if (response.isSuccess()) {
          addNotification({
            messageId: 'notificationResgistrationMsg',
            titleId: 'notificationSuccessTitle',
            type: 'success',
          })

          trackEvent('User', 'Registered a new account', 'Signup Page')

          Auth.signIn({ email, password })
            .then((response) => {
              if (response.isSuccess()) {
                const hasRedirectParam = !!~window.location.search.indexOf('checkout')
                const tokens = response.success()

                Auth.saveToken(tokens)

                user.associateGuestCart()

                user.refetch()

                navigate(hasRedirectParam ? '/checkout' : '/', { replace: true })
              }
            })
        } else {
          const backendErrors = getBackendErrors(response.fail().serverResponse.data.errors)

          resetCaptchaToken()
          setErrors(backendErrors)
          setIsLoading(false)
        }
      })
      .catch((response) => {
        addNotification({
          backendError: response,
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })

        resetCaptchaToken()
        setIsLoading(false)
      })
  }

  const setCaptchaInstance = (captchaInstance) => {
    recaptchaInstance = captchaInstance
  }

  const resetCaptchaToken = () => {
    recaptchaInstance.props.grecaptcha.reset()

    setCaptchaToken(null)
  }

  return (
    <Container>
      <TranslatedTitleLoader titleId="pageTitleSignup" />

      <TranslatedMetaLoader
        metaArray={[
          { content: 'metaTextSignUp', name: 'description' },
          { content: 'metaTextSignUp', property: 'og:description' },
          { content: 'pageTitleSignup', property: 'og:title' },
        ]}
      />

      <Row className="justify-content-md-center mt-3">
        <Col className="mb-2 px-0" xs="12">
          <Heading as="h1" textAlign="center">
            <FormattedMessage id="readyToStart" />
          </Heading>
        </Col>

        <Col className="px-0" md="8">
          <Text fontSize={['1.55rem', '1.1rem']} fontWeight="bold" textAlign="center">
            <FormattedMessage id="signUpForAFreeAccount" />
          </Text>
        </Col>
      </Row>

      <Form className="mb-5">
        <Row className="justify-content-md-center mt-3">
          <Col className="px-0" md="4">
            <FormattedMessage id="firstName">
              {translatedMessage => (
                <StyledFormGroup controlId="firstName" mr={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    errorTranslationId={formErrors?.firstName}
                    maxLength="30"
                    name="firstName"
                    onChange={handleInputChange}
                    type="text"
                  />
                </StyledFormGroup>
              )}
            </FormattedMessage>
          </Col>

          <Col className="px-0" md="4">
            <FormattedMessage id="lastName">
              {translatedMessage => (
                <StyledFormGroup controlId="lastName" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    errorTranslationId={formErrors?.lastName}
                    maxLength="30"
                    name="lastName"
                    onChange={handleInputChange}
                    type="text"
                  />
                </StyledFormGroup>
              )}
            </FormattedMessage>
          </Col>

          <Col className="px-0" md="8">
            <FormattedMessage id="email">
              {translatedMessage => (
                <Form.Group controlId="email">
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    errorTranslationId={formErrors?.email}
                    maxLength="100"
                    name="email"
                    onChange={handleInputChange}
                    type="text"
                  />
                </Form.Group>
              )}
            </FormattedMessage>

            <Form.Group controlId="password">
              <Form.Label md="4">
                <FormattedMessage id="createPassword" />*
              </Form.Label>

              <PasswordInput
                errorTranslationId={formErrors?.password}
                onChange={handleInputChange}
                value={formState.password}
              />
            </Form.Group>

            <Form.Group controlId="passwordConfirmation">
              <Form.Label md="4">
                <FormattedMessage id="confirmPassword" />*
              </Form.Label>

              <PasswordInput
                errorTranslationId={formErrors?.passwordConfirmation}
                name="passwordConfirmation"
                onChange={handleInputChange}
                showHint={false}
                value={formState.passwordConfirmation}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col className="px-0" md="4">
            <States
              errorTranslationId={formErrors?.state}
              onChange={handleInputChange}
              responseFieldName="id"
              value={formState.state}
            />
          </Col>

          <Col md="4" />
        </Row>

        <Row className="justify-content-md-center mt-3">
          <Col className="px-0" md="8">
            <Checkbox
              checked={formState.newsletter}
              labelTextId="signUpToNewsletter"
              name="newsletter"
              onChange={handleInputChange}
            />
          </Col>

          <Col className="px-0" md="8">
            <Checkbox
              checked={formState.marketingResearch}
              labelTextId="optIntoCiResearch"
              name="marketingResearch"
              onChange={handleInputChange}
            />
          </Col>

          <Col className="px-0" md="8">
            <Checkbox
              checked={formState.isTosAccepted}
              errorTranslationId={formErrors?.isTosAccepted}
              isRequired
              labelTextId="aggreTermsAndConditions"
              labelTextIdValue={{ privacyPolicy: <PrivacyPolicyLink />, termsAndConditions: <TermsAndConditionsLink /> }}
              name="isTosAccepted"
              onChange={handleInputChange}
            />
          </Col>

          <Col className="px-0" md="8">
            <GoogleCaptcha
              error={formErrors?.recaptchaResponse}
              onChange={setCaptchaToken}
              setCaptchaInstance={setCaptchaInstance}
            />
          </Col>
        </Row>

        <Row className="justify-content-md-center mt-3">
          <Col className="px-0" md="8">
            <Button
              block
              className="primary-button"
              disabled={isLoading}
              id="submitButton"
              mx="auto"
              onClick={handleClick}
              px="60px"
              size="lg"
              type="submit"
              variant="primary"
              width={1}
            >
              <FormattedMessage id="register" />
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

const StyledFormGroup = styled(Form.Group)`
  ${space}
`

SignUpPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(SignUpPage)
