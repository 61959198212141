const getBackendErrors = (errors) => {
  let errorsWithFields = {}

  if (errors)
    Object.keys(errors).forEach((key) => errorsWithFields[key] = errors[key][0])

  return errorsWithFields
}

export default getBackendErrors
