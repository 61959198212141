import { fill, last } from '../../../lodash'

export const generateMask = (gaps, lengths) => {
  if (!gaps && !lengths) {
    return '9999 9999 9999 9999'
  }

  const maxLength = last(lengths)
  const allDigits = fill(Array(maxLength), '9')

  return gaps.sort(sortReverse).reduce((prev, curr) => {
    prev.splice(curr, 0, ' ')

    return prev
  }, allDigits).join('')
}

export const sortReverse = (a, b) => a > b ? -1 : 1
