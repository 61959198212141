import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Col } from 'react-bootstrap'
import { Box } from '../../elements'
import { Loading } from '../../components'
import strapi from '../../api/strapi'
// import { ContentfulContent } from '../../components'

const Custom = ({ id }) => {
  const [isPageLoading, setPageLoading] = useState(true)
  const [strapiPage, setStrapiPage] = useState(null)

  useEffect(() => {
    const fetchPage = async () => {
      const { data } = await strapi.get('/consumer-privacy')

      if (data) {
        setStrapiPage(data.data.attributes)
      }

      setPageLoading(false)
    }

    fetchPage()
  }, [])

  if (isPageLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <Col className="consumer-privacy" md={{ span: 10 }}>
      <Col className="text-wrapper">
        <h1>{strapiPage?.title}</h1>
      </Col>
      <Col className="text-wrapper">
        <BlocksRenderer content={strapiPage?.content} />
      </Col>
    </Col>
  )}

Custom.propTypes = {
  id: PropTypes.string.isRequired,
}

export default Custom
