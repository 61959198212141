import React from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Hide } from '../../elements'
import { Loading } from '../../components'
import { withProfile } from '../../modules'
import { userPropTypes } from '../../types'
import {
  AccountSettings,
  ManageDeposit,
  Menu,
  OrderHistory,
  SchedulePickup,
  WhatsInMyLoop,
} from './'
import './user.scss'

export const UserPage = ({ user }) => {
  const params = useParams()

  if (user.isLoading)
    return <Loading />

  if (!params.userMenu)
    <Navigate to="/user/settings" />

  return (
    <Row className="justify-content-md-center" id="user">
      <Hide below={992}>
        <Col className="user-menu-column" md={3}>
          <Menu activeMenu={params.userMenu} />
        </Col>
      </Hide>

      <Col className="mb-5 mt-5 user-content-column">
        <Routes>
          <Route element={<SchedulePickup />} path="/schedule-pickup" />
          <Route element={<OrderHistory />} path="/order-history" />
          <Route element={<AccountSettings />} path="/settings" />
          <Route element={<ManageDeposit />} path="/manage-deposits" />
          <Route element={<WhatsInMyLoop />} path="/whats-in-my-loop" />
        </Routes>
      </Col>
    </Row>
  )
}

UserPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(UserPage)
