import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Box, Heading, Image, Text } from '../../elements'
import { TranslatedTitleLoader } from '../../modules'
import { scrollToElement } from '../../utils'
import images from '../../../assets/images'

const Error404Page = () => (
  <React.Fragment>
    <TranslatedTitleLoader titleId="pageTitle404" />

    <div className="my-5 text-center" id="404page">
      <Box mx={5}>
        <Image alt="404" src={images.icon404} width="100%" />
      </Box>

      <Heading mt={40} textAlign="center">
        <FormattedMessage id="error404Title" />
      </Heading>

      <Text textAlig="center">
        <FormattedMessage id="error404Description" />
      </Text>

      <Box mx={5}>
        <Link className="link" id="backToHomePageLink" onClick={scrollToElement} to="/">
          <Button className="full-width-button mb-5" mt={2}>
            <FormattedMessage id="backToHomePage" />
          </Button>
        </Link>
      </Box>
    </div>
  </React.Fragment>
)

export default Error404Page
