import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Container, Row } from 'react-bootstrap'
import { Loading } from '../../components'
import { Box, Col, HeadingWithBg } from '../../elements'
import strapi from '../../api/strapi'

const ContactUs = () => {
  const [isContactUsLoading, setContactUsLoading] = useState(true)
  const [strapiContactUs, setStrapiContactUs] = useState(null)

  useEffect(() => {
    const fetchContactUs = async () => {
      const { data } = await strapi.get('/contact-us?&populate[MetaData][populate]=*')

      if (data) {
        setStrapiContactUs(data.data.attributes)
      }

      setContactUsLoading(false)
    }

    fetchContactUs()
  }, [])

  if (isContactUsLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiContactUs?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiContactUs.MetaData?.metaText , name: 'description' },
            { content: strapiContactUs.MetaData?.metaText , property: 'og:description' },
            { content: strapiContactUs.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiContactUs.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container">
        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBgV4"
          my={30}
          p={35}
          position="relative"
          textAlign="center"
        >
          <FormattedMessage id="contactUs" />
        </HeadingWithBg>

        <Row>
          <Col display="flex" md={{ offset: 3, span: 6 }}>
            <ShadowBox bg="white" mt={4} p={3}>
              <BlocksRenderer content={strapiContactUs?.content} />
            </ShadowBox>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )}

const ShadowBox = styled(Box)`
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  img {
    display: inline;
    max-width: 20px;
    height: auto;

    margin-right: 8px;
  }
`

export default ContactUs
