import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from '../../elements'

const NoImage = () => (
  <Text fontSize="1em" textAlign="center">
    <FormattedMessage id="noImageAvailable" />
  </Text>
)

export default NoImage
