import React from 'react'
import getDisplayName from '../../../hoc-utils/getDisplayName'
import { UserContext } from '../../'

export const withProfile = (Component) => {
  const WithUser = (props) => (
    <UserContext.Consumer>
      {data => <Component {...props} user={data} />}
    </UserContext.Consumer>
  )

  WithUser.displayName = `withUser(${getDisplayName(Component)})`

  return WithUser
}
