import React, { useEffect, useState } from 'react'
import { Box, Text } from '../../elements'

import strapi from '../../api/strapi'

const Banner = () => {
  const [bannerText, setBannerText] = useState({ visible: false })

  useEffect(() => {
    const fetchBannerText = async () => {
      const { data } = await strapi.get('/home-page-banner')

      if (data) {
        setBannerText(data.data.attributes)
      }
    }

    fetchBannerText()
  }, [])

  if (!bannerText.visible)
    return null

  return (
    <Box bg="primary.dark" className="home-banner" px={2} width="100%">
      <Text
        className="animate__animated animate__slideInDown"
        color="gray.light"
        fontSize={['12px', '16px']}
        fontWeight="bold"
        lineHeight={['28px', '32px']}
        mb={0}
        textAlign="center"
      >
        {bannerText.text}
      </Text>
    </Box>
  )
}

export default Banner
