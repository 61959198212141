import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { GetStarted, Loading } from '../../components'
import {
  Arrow,
  Box,
  Col,
  Heading,
  HeadingWithBg,
  HeadingInfoBox,
  Image,
  Row,
  Text,
} from '../../elements'
import images from '../../../assets/images/newcb'
import strapi from '../../api/strapi'

const CostCalculator = () => {
  const [isHowItWorksPageLoading, setCostCalculatorPageLoading] = useState(true)
  const [strapiCostCalculatorPage, setStrapiCostCalculatorPage] = useState(null)

  useEffect(() => {
    const fetchCostCalculatorPage = async () => {
      const { data } = await strapi.get('/cost-comparison?populate[landfillPart][populate]=*&populate[comparisonPart][populate]=*&populate[mainImage][populate]=*&populate[diaperCleaningImages][populate]=*&populate[imageWithTwoText][populate]=*&populate[button][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiCostCalculatorPage(data.data.attributes)
      }

      setCostCalculatorPageLoading(false)
    }

    fetchCostCalculatorPage()
  }, [])

  if (isHowItWorksPageLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiCostCalculatorPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiCostCalculatorPage.MetaData?.metaText , name: 'description' },
            { content: strapiCostCalculatorPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiCostCalculatorPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiCostCalculatorPage.MetaData?.pageTitle}
        />
      }

      <Container className="mt-4 mb-5 content-container">
        <Row>
          <Col md={5}>
            <HeadingWithBg
              as="h4"
              color="white"
              imageName="headerBgV7"
              mb={-40}
              mt={[0, 30]}
              p={20}
              pl={10}
              position="relative"
              pt={70}
              textAlign="center"
            >
              {strapiCostCalculatorPage?.title}
            </HeadingWithBg>

            <Box p={25}>
              <ImageWithRadius src={strapiCostCalculatorPage?.mainImage?.data?.attributes?.url} width="100%" />
            </Box>
          </Col>

          <Col md={6}>
            <Box position="relative">
              <CustomHeadingInfoBox ml="auto" mr={['auto', 60]} px={60} width={400}>
                <CustomArrowBox className="arrow" />
                <p className="mt-2">
                  {strapiCostCalculatorPage?.firstSubtitle}
                </p>
              </CustomHeadingInfoBox>

              <CustomHeadingInfoBox color="white" dark ml="auto" mr={['auto', 30]} mt={-30} px={60} width={400}>
                <p className="mt-2">
                  {strapiCostCalculatorPage?.secondSubtitle}
                </p>
              </CustomHeadingInfoBox>
            </Box>

            <Heading as="h4" color="black" mt={60} textAlign="center">
              {strapiCostCalculatorPage?.arrowTitle}
              <Arrow mt={30} mx="auto" />
            </Heading>
          </Col>

          <Col className="markdownParagraph" md={{ offset: 2, span: 10 }} mt={[40, 40, 80]}>
            <ShadowBox bg="white" maxWidth={['100%', '100%', '50%']} px="10px" py="2px">
              <p>
                {strapiCostCalculatorPage?.imageWithTwoText?.firstSubText}
              </p>
            </ShadowBox>

            <MobileBox display="flex" flex="1 1 auto" maxWidth={['100%', '100%', '80%']} ml={[0, 0, '2%']} mt={[10, 10, -20]}>
              <Box alignSelf="center" className="static-image" flex="1 1 200px">
                <Image mr={20} mt={33} src={strapiCostCalculatorPage?.imageWithTwoText?.image?.data?.attributes?.url} width={150} />
              </Box>

              <ShadowBox bg="white" px="10px" py="2px">
                <p>
                  {strapiCostCalculatorPage?.imageWithTwoText?.secondSubText}
                </p>
              </ShadowBox>
            </MobileBox>
          </Col>
        </Row>

        <Row mt={[10, 100]} position="relative" px={[30, 0]}>
          <StyledBox mx="auto" position={['static', 'absolute']} right={-66} top={-190} width={300}>
            <Image src={strapiCostCalculatorPage?.diaperCleaningImages?.imageWithText?.data?.attributes?.url} width="100%" />
          </StyledBox>

          <Col lg={{ offset: 2, span: 3 }} md={4}>
            <Image src={strapiCostCalculatorPage?.diaperCleaningImages?.firstCleaningImage?.data?.attributes?.url} width="100%" />
          </Col>

          <Col lg={3} md={4} mt={[30, 0]}>
            <Image src={strapiCostCalculatorPage?.diaperCleaningImages?.secondCleaningImage?.data?.attributes?.url} width="100%" />
          </Col>

          <Col lg={3} md={4} mt={[30, 0]}>
            <Image src={strapiCostCalculatorPage?.diaperCleaningImages?.thirdCleaningImage?.data?.attributes?.url} width="100%" />
          </Col>

          <Col md={{ offset: 2, span: 10 }}>
            <Text mt={20} textAlign={['center', 'right']}>
              <FormattedMessage id="averageCost" />
            </Text>
          </Col>
        </Row>
      </Container>

      <ContainerWithBg fluid>
        <BottomContainerWithBg fluid>
          <RowWithBg>
            <CustomCol className="landfillWrapper" md={{ offset: 2 , span: 8 }}>
              <h1>{strapiCostCalculatorPage?.landfillPart.title}</h1>
              <p className="subtitle">{strapiCostCalculatorPage?.landfillPart.subTitle}</p>
              <Image src={strapiCostCalculatorPage?.landfillPart.image?.data?.attributes?.url} />
            </CustomCol>
          </RowWithBg>
        </BottomContainerWithBg>
      </ContainerWithBg>

      <Container className="content-container">
        <Row>
          <Col
            mb={4}
            md={{ offset: 2, span: 8 }}
            mt={3}
          >
            <ShadowBox>
              <Row
                height="auto"
                pl="auto"
                pr="auto"
              >
                <CompareBoxSide bg="#D9D9D9" data={strapiCostCalculatorPage} side="left" xs={6}>
                  <CustomHeading
                    as="h4"
                    color="black"
                    pl={['10%', '10%', '10%']}
                    pr={['10%', '10%', '10%']}
                    pt={40}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.titleA}
                  </CustomHeading>

                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '10%', '10%', '19%']}
                    pr={['10%', '32%', '32%', '40%']}
                    pt={['120%', 68]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textA1}
                  </Text>
                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '12%', '12%', '12%']}
                    pr={['10%', '50%', '50%', '50%']}
                    pt={[16, 89]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textA2}
                  </Text>
                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '24%', '24%', '24%']}
                    pr={['10%', '28%', '28%', '28%']}
                    pt={[16, 68]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textA3}
                  </Text>
                </CompareBoxSide>

                <CompareBoxSide bg="primary.main" data={strapiCostCalculatorPage} side="right" xs={6}>
                  <Heading
                    as="h4"
                    color="primary.dark"
                    fontSize={30}
                    pt={40}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.titleB}
                  </Heading>

                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '36%', '36%', '36%']}
                    pr={['10%', '8%', '8%', '8%']}
                    pt={['120%', 42]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textB1}
                  </Text>
                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '42%', '42%']}
                    pr={['10%', '4%', '4%']}
                    pt={[16, 77]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textB2}
                  </Text>
                  <Text
                    fontSize={[3, 1, 1]}
                    pl={['10%', '20%', '20%']}
                    pr={['10%', '30%', '30%']}
                    pt={[16, 80]}
                    textAlign="center"
                  >
                    {strapiCostCalculatorPage?.comparisonPart?.textB3}
                  </Text>
                </CompareBoxSide>
              </Row>
            </ShadowBox>
          </Col>

          <Col mb={60} md={{ offset: 2, span: 8 }} mt={30}>
            <ShadowBox bg="white" display={['block', 'flex']} justifyContent="space-evenly" p={3} textAlign="center">
              <Text mb={[3, 0]} style={{ alignSelf: 'center' }}>
                <FormattedMessage id="readyToStartEasy" />
              </Text>

              <GetStarted link={strapiCostCalculatorPage?.button?.link} text={strapiCostCalculatorPage?.button?.text} />
            </ShadowBox>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
const CompareBoxSide = styled(Col)`
  background-image: url(${props => props.side === 'left' ? props.data.comparisonPart?.backgroundA?.data?.attributes?.url : props.data.comparisonPart?.backgroundB?.data?.attributes?.url});
  background-repeat: no-repeat;
  background-position: ${props => props.side === 'right' ? 'left 60%' : 'right 60%'};
  background-size: 40%;

  border-top-right-radius: ${props => props.side === 'left' ? 0 : 20}px;
  border-bottom-right-radius: ${props => props.side === 'left' ? 0 : 20}px;

  border-top-left-radius: ${props => props.side === 'right' ? 0 : 20}px;
  border-bottom-left-radius: ${props => props.side === 'right' ? 0 : 20}px;

  padding: 0;

  @media (max-width: 767px) {
    background-position: ${props => props.side === 'right' ? 'left 30%' : 'right 30%'};
  }
`

const CustomHeading = styled(Heading)`
  font-size: 26px;
  font-family: 'Nunito', sans-serif;
`

const MobileBox = styled(Box)`
  @media (max-width: 400px) {
    display: block;

    .static-image {
      text-align: center;
    }
  }
`

const StyledBox = styled(Box)`
  @media (max-width: 1065px) {
    position: absolute;
    width: 250px;

    right: -15px;
    top: -160px;
  }

  @media (max-width: 767px) {
    position: static;
  }
`

const CustomHeadingInfoBox = styled(HeadingInfoBox)`
  @media (max-width: 400px) {
    width: 100%;

    padding: 40px 20px;

    background-size: cover;
    background-repeat: round;
    background-position: 0 0;
  }
`

const CustomCol = styled(Col)`
  h1 {
    color: #fff !important;

    font-size: 30px;
  }

  p {
    span {
      color: #fff !important; 
      font-size: 22px;
      line-height: 22px;
    }
  }

  text-align: center;

  img {
    margin-top: 30px;
    width: 100%;
  }
`

const RowWithBg = styled(Row)`
  background-color: #192657;
  color: #fff;
`

const ContainerWithBg = styled(Container)`
  padding: 0;
  padding-top: 5%;


  background-image: url("data:image/svg+xml,%3Csvg id='wave' style='transform:rotate(0deg); transition: 0.3s' viewBox='0 0 1440 110' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='sw-gradient-0' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop stop-color='rgba(25, 38, 87, 1)' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='rgba(25, 38, 87, 1)' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath style='transform:translate(0, 0px); opacity:1' fill='url(%23sw-gradient-0)' d='M0,11L80,16.5C160,22,320,33,480,42.2C640,51,800,59,960,51.3C1120,44,1280,22,1440,22C1600,22,1760,44,1920,55C2080,66,2240,66,2400,58.7C2560,51,2720,37,2880,27.5C3040,18,3200,15,3360,22C3520,29,3680,48,3840,51.3C4000,55,4160,44,4320,38.5C4480,33,4640,33,4800,44C4960,55,5120,77,5280,88C5440,99,5600,99,5760,93.5C5920,88,6080,77,6240,64.2C6400,51,6560,37,6720,27.5C6880,18,7040,15,7200,11C7360,7,7520,4,7680,1.8C7840,0,8000,0,8160,16.5C8320,33,8480,66,8640,69.7C8800,73,8960,48,9120,36.7C9280,26,9440,29,9600,34.8C9760,40,9920,48,10080,56.8C10240,66,10400,77,10560,67.8C10720,59,10880,29,11040,23.8C11200,18,11360,37,11440,45.8L11520,55L11520,110L11440,110C11360,110,11200,110,11040,110C10880,110,10720,110,10560,110C10400,110,10240,110,10080,110C9920,110,9760,110,9600,110C9440,110,9280,110,9120,110C8960,110,8800,110,8640,110C8480,110,8320,110,8160,110C8000,110,7840,110,7680,110C7520,110,7360,110,7200,110C7040,110,6880,110,6720,110C6560,110,6400,110,6240,110C6080,110,5920,110,5760,110C5600,110,5440,110,5280,110C5120,110,4960,110,4800,110C4640,110,4480,110,4320,110C4160,110,4000,110,3840,110C3680,110,3520,110,3360,110C3200,110,3040,110,2880,110C2720,110,2560,110,2400,110C2240,110,2080,110,1920,110C1760,110,1600,110,1440,110C1280,110,1120,110,960,110C800,110,640,110,480,110C320,110,160,110,80,110L0,110Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: top;
`

const BottomContainerWithBg = styled(Container)`
  padding: 0;
  padding-bottom: 5%;


  background-image: url("data:image/svg+xml,%3Csvg id='wave' style='transform:rotate(180deg); transition: 0.3s' viewBox='0 0 1440 110' version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cdefs%3E%3ClinearGradient id='sw-gradient-0' x1='0' x2='0' y1='1' y2='0'%3E%3Cstop stop-color='rgba(25, 38, 87, 1)' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='rgba(25, 38, 87, 1)' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath style='transform:translate(0, 0px); opacity:1' fill='url(%23sw-gradient-0)' d='M0,11L80,16.5C160,22,320,33,480,42.2C640,51,800,59,960,51.3C1120,44,1280,22,1440,22C1600,22,1760,44,1920,55C2080,66,2240,66,2400,58.7C2560,51,2720,37,2880,27.5C3040,18,3200,15,3360,22C3520,29,3680,48,3840,51.3C4000,55,4160,44,4320,38.5C4480,33,4640,33,4800,44C4960,55,5120,77,5280,88C5440,99,5600,99,5760,93.5C5920,88,6080,77,6240,64.2C6400,51,6560,37,6720,27.5C6880,18,7040,15,7200,11C7360,7,7520,4,7680,1.8C7840,0,8000,0,8160,16.5C8320,33,8480,66,8640,69.7C8800,73,8960,48,9120,36.7C9280,26,9440,29,9600,34.8C9760,40,9920,48,10080,56.8C10240,66,10400,77,10560,67.8C10720,59,10880,29,11040,23.8C11200,18,11360,37,11440,45.8L11520,55L11520,110L11440,110C11360,110,11200,110,11040,110C10880,110,10720,110,10560,110C10400,110,10240,110,10080,110C9920,110,9760,110,9600,110C9440,110,9280,110,9120,110C8960,110,8800,110,8640,110C8480,110,8320,110,8160,110C8000,110,7840,110,7680,110C7520,110,7360,110,7200,110C7040,110,6880,110,6720,110C6560,110,6400,110,6240,110C6080,110,5920,110,5760,110C5600,110,5440,110,5280,110C5120,110,4960,110,4800,110C4640,110,4480,110,4320,110C4160,110,4000,110,3840,110C3680,110,3520,110,3360,110C3200,110,3040,110,2880,110C2720,110,2560,110,2400,110C2240,110,2080,110,1920,110C1760,110,1600,110,1440,110C1280,110,1120,110,960,110C800,110,640,110,480,110C320,110,160,110,80,110L0,110Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom;
`

const ImageWithRadius = styled(Image)`
  border-radius: 20px;
`

const ShadowBox = styled(Box)`
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
`

const CustomArrowBox = styled(Box)`
  position: absolute;

  bottom: -40px;
  left: -60px;

  background-image: url(${props => images.curvedArrow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;

  width: 120px ;
  height: 120px;
  
  transform: rotate(52deg);
`

export default CostCalculator
