import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Nav } from 'react-bootstrap'
import { scrollToElement } from '../../../utils'
import { userMenu } from '../../../constants'

const Menu = ({ activeMenu }) => {
  let navigate = useNavigate()

  return (
    <Nav
      activeKey={activeMenu}
      className="flex-column mt-5"
      id="user-menu"
      onClick={scrollToElement}
      onSelect={(selectedKey) => navigate(selectedKey)}
    >
      {userMenu.map(({ linkTo, name, translationId }) => (
        <Nav.Link className={name === activeMenu && 'active-user-menu'} eventKey={linkTo} key={translationId}>
          <FormattedMessage id={translationId} />
        </Nav.Link>
      ))}
    </Nav>
  )
}

Menu.propTypes = {
  activeMenu: PropTypes.string,
}

export default Menu
