import arrowRight from './arrow_box_right.svg'
import arrowDown from './icons/arrow-down.svg'
import browserIconChrome from './browsers/chrome.png'
import browserIconFirefox from './browsers/firefox.png'
import browserIconSafari from './browsers/safari.png'
import checkIcon from './icons/checked.svg'
import headerLogo from './header_logo.png'
import icon404 from './404.webp'
import userIcon from './user_icon.png'

const images = {
  arrowDown,
  arrowRight,
  browserIconChrome,
  browserIconFirefox,
  browserIconSafari,
  checkIcon,
  headerLogo,
  icon404,
  userIcon,
}

export default images
