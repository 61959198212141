import styled from 'styled-components'
import Box from '../box/Box'

const Arrow = styled(Box)`
  background: #000;
  height: 3px;
  width: 30px;
  position: relative;
  transform: rotate(90deg);

  &:before,
  &:after {
    content: "";
    background: #000;
    position: absolute;
    height: 3px;
    width: 15px;
  }

  &:before {
    right: -3px;
    bottom: -4px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -3px;
    top: -4px;
    transform: rotate(45deg);
  }
`

export default Arrow
