import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import theme from '../../constants/theme'

const Provider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

Provider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Provider
