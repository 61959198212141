import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { find, isEmpty } from '../../lodash'
import { Image } from 'react-bootstrap'
import { Box, Currency, Link, Heading, Text, NoImage } from '../../elements'
import { QuantityRowItem } from '../../components'

const CartItem = ({ item, readOnly=false }) => (
  <Box display="flex" flexWrap={['wrap', 'nowrap']} mb={7}>
    <Box alignSelf="flex-start" my={[5, 0]} textAlign="center" width={[1, 156]}>
      <Link to={`/product/${item.slug}`}>
        {isEmpty(item.images.data)
          ? <NoImage />
          : (
            <Image
              fluid
              src={find(item.images.data[0].attributes.styles, { width: '600' }).url}
              style={{ borderRadius: '10px', height: 'auto' }}
            />
          )
        }
      </Link>
    </Box>

    <Box ml={[0, 3]} width={1}>
      <Box display="flex" flexDirection={['column', 'row']} height="100%" justifyContent="space-between">
        <Box display="flex" flex="1 1 auto" flexDirection="column" justifyContent="space-between">
          <Box>
            <Heading as="h2" fontSize={[4, 3]} fontWeight="bold" my={1}>
              <Link color="dark" to={`/product/${item.slug}`}>
                {readOnly && <Text as="span" color="primary.dark">{item.quantity} x </Text>}{item.name}
              </Link>
            </Heading>

            <Text color="gray.main">
              {item.subtitle}
            </Text>
          </Box>

          <Box>
            <Heading as="h3" color="black" fontSize={[4, 3]} fontWeight="bold" my={1}>
              <FormattedMessage id="includes" />
            </Heading>

            <Box id="plus-list">
              <ul>
                {item.items.filter(item => item.quantity > 0).map(item => (
                  <li key={item.name}>
                    {item.quantity < 2
                      ? item.name
                      : `${item.quantity} ${item.name}`
                    }
                  </li>
                ))}
                <li>
                  <FormattedMessage id="freePickUp" />
                </li>
              </ul>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flex="1 0 auto" flexDirection="column" justifyContent="space-between">
          <Box>
            <Text color="primary.dark" fontSize={[2, 3]} fontWeight="bold" my={1} textAlign="right">
              <Currency value={(item.price - item.deposit_price)} />
            </Text>

            <Text color="gray.main" fontSize={1} my={0} textAlign="right">
              + <Currency data-test="deposit-price" value={item.deposit_price} /> <FormattedMessage id="refundableDeposit" />
            </Text>
          </Box>

          {!readOnly &&
            <QuantityRowItem lineItemId={item.id} quantity={item.quantity} />
          }
        </Box>
      </Box>
    </Box>
  </Box>
)

CartItem.propTypes = {
  item: PropTypes.object,
  readOnly: PropTypes.bool,
}

export default CartItem
