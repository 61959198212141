import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Box, Heading } from '../'

const Panel = ({ children, title }) => (
  <BorderedBox
    mx={0}
    my=".75em"
    pb={4}
    pt={3}
    px={[4, 5]}
  >
    {title &&
      <HeadingWithSeparator fontSize={3} pb={3} pt={2}>
        <FormattedMessage id={title} />
      </HeadingWithSeparator>
    }

    {children}
  </BorderedBox>
)

Panel.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export const BorderedBox = styled(Box)`
  border: 1px solid ${props => props.theme.colors.primary.main};
  border-radius: 4px;
`

export const HeadingWithSeparator = styled(Heading)`
  border-bottom: 1px solid ${props => props.theme.colors.primary.main};
`

export default Panel
