import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Box, Currency } from '../'

const SummaryElement = ({ contentTranslationId, currencyValue, isBold=false, isFree=false, isHighlighted=false }) => (
  <Box
    alignItems={['end', 'center']}
    color="dark"
    display="flex"
    flexWrap="wrap"
    fontWeight="bold"
    my={2}
  >
    <Box width={1/2}>
      <FormattedMessage id={contentTranslationId} />
    </Box>

    <Box
      color={isHighlighted ? 'primary.dark' : 'dark'}
      display="flex"
      fontSize={isHighlighted ? '1.5rem' : '1rem'}
      fontWeight={isBold ? 'bold' : 'normal'}
      justifyContent="flex-end"
      width={1/2}
    >
      {isFree
        ? <FormattedMessage id="free" />
        : <Currency value={currencyValue} />
      }
    </Box>
  </Box>
)

SummaryElement.propTypes = {
  contentTranslationId: PropTypes.string.isRequired,
  currencyValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  isBold: PropTypes.bool,
  isFree: PropTypes.bool,
  isHighlighted: PropTypes.bool,
}

export default SummaryElement
