import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { color, layout, typography, space } from 'styled-system'
import Box from '../box/Box'
import images from '../../../assets/images/newcb'

const Heading = styled.h1`
  ${color}
  ${layout}
  ${typography}
  ${space}

  color: ${props => props.color || 'primary.dark'};
`
export const HeadingWithBg = ({ children, imageName, ...props }) => (
  <StyledHeading bg={imageName} {...props}>
    {children}
  </StyledHeading>
)

const StyledHeading = styled(Heading)`
  background-image: url(${props => images[props.bg]});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
`

HeadingWithBg.propTypes = {
  children: PropTypes.node,
  imageName: PropTypes.oneOf([
    'headerBg',
    'headerBgV2',
    'headerBgV3',
    'headerBgV4',
    'headerBgV5',
    'headerBgV6',
    'headerBgV7',
  ]).isRequired,
}

export const HeadingInfoBox = styled(Box)`
  background-image: url(${props => images[props.dark ? 'headerInfoBoxDarkBg' : 'headerInfoBoxBg']});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  position: relative;

  span, p {
    color: ${props => props.color};
  }

  font-weight: ${props => props.fontWeight || 'bold'};
  padding-left: ${props => props.px || 50}px;
  padding-right: ${props => props.px || 50}px;
  padding-top: ${props => props.py || 40}px;
  padding-bottom: ${props => props.py || 40}px;
`

export default Heading
