import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from '../'

const FormattedAddress = ({ address }) => (
  <Box>
    <Text fontSize={1} mb={0}>{address.address1} {address.address2}</Text>

    <Text fontSize={1} mb={0}>{address.country_name} </Text>

    <Text fontSize={1} mb={0}>
      {address.city} {address.state_name} {address.zipcode}
    </Text>
  </Box>
)

FormattedAddress.propTypes = {
  address: PropTypes.shape({
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    country_name: PropTypes.string,
    state_name: PropTypes.string,
    zipcode: PropTypes.string,
  }),
}

export default FormattedAddress

