import ReactGA from 'react-ga4'

/**
 * Track any event using Google Analytics
 * @param {String} category - Typically the object that was interacted with
 * @param {String} action - The type of interaction
 * @param {String} label - Useful for categorizing events
 **/
const trackEvent = (category, action, label) => {
  if (window.ga) {
    ReactGA.event({
      action,
      category,
      label,
    })
  }
}

export default trackEvent
