import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Col, Row, Table } from 'react-bootstrap'
import { Loading } from '../../../components'
import { Box, Heading, Link, Text } from '../../../elements'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export const WhatsInMyLoop = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [myLoop, setMyLoop] = useState([])

  useEffect(() => {
    getMyLoopList()
  }, [])

  const getMyLoopList = async () => {
    const bearerToken = await user.getTokenForRequest()

    const { data } = await client.fetcher.fetch({
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      method: 'GET',
      url: 'api/v2/storefront/my_loop',
    })

    setMyLoop(data)
    setIsLoading(false)
  }

  const isLoopEmpty = (myLoop.deposit_in_use === 0 && myLoop.unused_deposit === 0)

  if (isLoading)
    return <Loading />

  return (
    <Row id="user-menu-delivery-history">
      <TranslatedTitleLoader titleId="pageTitleUserDelivery" />

      <Col>
        <Heading as="h3" mb={4}>
          <FormattedMessage id="whatsInMyLoop" />
        </Heading>

        {isLoopEmpty &&
          <FormattedMessage
            id="yourLoopIsEmpty"
            values={{
              link: <Link to="/products"> <FormattedMessage id="chooseBoundle" /></Link>,
            }}
          />
        }

        {!isLoopEmpty &&
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="products" />
                </th>
              </tr>
            </thead>

            <tbody>
              {myLoop.items.map(item => (
                <tr key={item.id}>
                  <td>
                    <Box flex="1 1 auto" my={[4, 0]}>
                      <Box width={1}>
                        <Box display="flex" height="100%" justifyContent="space-between">
                          <Box display="flex" flex="1 1 auto" flexDirection="column" justifyContent="space-between">
                            <Box>
                              <Heading as="h2" fontSize={[4, 3]} fontWeight="bold" my={1}>
                                {item.name}
                                <Text color="gray.main" fontSize="12px" fontWeight="normal">{item.sku}</Text>
                              </Heading>
                            </Box>
                          </Box>

                          <Box>
                            {item.outstanding_quantity > 0 &&
                                <Text color="gray.main" my={0} textAlign="right">
                                  <FormattedMessage
                                    id="atHomeCount"
                                    values={{
                                      quantity: item.outstanding_quantity,
                                    }}
                                  />
                                </Text>
                            }

                            {item.returned_quantity > 0 &&
                                <Text color="gray.main" my={0} textAlign="right">
                                  <FormattedMessage
                                    id="returnedItemCount"
                                    values={{
                                      quantity: item.returned_quantity,
                                    }}
                                  />
                                </Text>
                            }
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </Col>
    </Row>
  )
}

WhatsInMyLoop.propTypes = {
  user: userPropTypes,
}

export default withProfile(WhatsInMyLoop)
