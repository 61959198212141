import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box } from '../../elements'
import { Carousel as OriginalCarousel } from 'react-responsive-carousel'

const carouselSettings = {
  emulateTouch: true,
  showArrows: false,
  showIndicators: false,
  showStatus: false,
  useKeyboardArrows: true,
}

const Carousel = ({ images, onClickThumb, selectedItem }) => (
  <CustomCarousel
    {...carouselSettings}
    onClickThumb={onClickThumb}
    selectedItem={selectedItem}
    showThumbs={images.length > 1}
  >
    {images.map(image => (
      <Box key={image.id}>

        <img
          alt={image.alternativeText}
          src={image.url}
        />
      </Box>
    ))}
  </CustomCarousel>
)

const CustomCarousel = styled(OriginalCarousel)`
.carousel {
  .slide {
    background: transparent;

    img {
      border-radius: 20px;
    }
  }

  .thumbs-wrapper {
    .control-next.control-arrow:before {
      border-left: 8px solid ${props => props.theme.colors.secondary.light};
    }

    .control-prev.control-arrow:before {
      border-right: 8px solid ${props => props.theme.colors.secondary.light};
    }
  }

  & .thumbs {
    padding: 0;
    .thumb {
      border-radius: 20px;

      &:hover,
      &.selected {
        border-color: ${props => props.theme.colors.primary.main};
      }

      img {
        border-radius: 20px;
      }
    }
  }
}
`

Carousel.propTypes = {
  images: PropTypes.array.isRequired,
  onClickThumb: PropTypes.func.isRequired,
  selectedItem: PropTypes.number,
}

export default Carousel
