import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { Accordion, Card, Col, Row } from 'react-bootstrap'
import { isEmpty } from '../../../lodash'
import { Loading, ReviewOrderItem } from '../../../components'
import { Box, Currency, Divider, FormattedAddress, Heading, Hide, OrderElement, Text } from '../../../elements'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { addNotification } from '../../../utils'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export const OrderHistory = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [activeOrderId, setActiveOrder] = useState(null)

  useEffect(() => {
    getOrderList()
  }, [])

  const getOrderList = async () => {
    const bearerToken = await user.getTokenForRequest()

    const response = await client.account.completedOrdersList(
      { bearerToken },
      {
        sort: '-completed_at',
      },
    )

    if (response.isSuccess()) {
      const { data } = response.success()

      setOrders(data)
    } else {
      addNotification({
        messageId: 'notificationSomethingWentWrong',
        titleId: 'notificationErrorTitle',
        type: 'danger',
      })
    }

    setIsLoading(false)
  }

  const handleSelectedOrder = (id) => {
    setActiveOrder(activeOrderId === id ? null : id)
  }

  const hasData = !isEmpty(orders)

  return (
    <Row id="user-menu-delivery-history">
      <TranslatedTitleLoader titleId="pageTitleUserDelivery" />

      <Col>
        <Heading as="h3" mb={4}>
          <FormattedMessage id="orderHistory" />
        </Heading>
        {isLoading && <Loading />}

        {!isLoading && !hasData &&
          <FormattedMessage id="thereIsNoDataToShow" />
        }

        {hasData &&
          <>
            <StyledCard>
              <Card.Header>
                <Box alignItems="center" display="flex" justifyContent="space-between">
                  <Text fontSize={[1, 3]} fontWeight="bold" mb={0} width={['33%', '25%']}>
                    <FormattedMessage id="orderDate" />
                  </Text>
                  <Text fontSize={[1, 3]} fontWeight="bold" mb={0} textAlign={['center', 'left']} width={['33%', '25%']}>
                    <FormattedMessage id="orderNumber" />
                  </Text>
                  <Hide below={540}>
                    <Text fontSize={3} fontWeight="bold" mb={0} textAlign={['center', 'left']} width="25%">
                      <FormattedMessage id="products" />
                    </Text>
                  </Hide>
                  <Text fontSize={[1, 3]} fontWeight="bold" mb={0} textAlign="right" width={['33%', '25%']}>
                    <FormattedMessage id="totalPrice" />
                  </Text>
                </Box>
              </Card.Header>
            </StyledCard>

            {orders.map(order => (
              <Accordion activeKey={activeOrderId} key={order.id}>
                <StyledCard>
                  <StyledHeader onClick={() => handleSelectedOrder(order.id)}>
                    <Box alignItems="center" display="flex" justifyContent="space-between">
                      <Text fontSize={[1, 3]} fontWeight="bold" mb={0} width={['30%', '25%']}>
                        <FormattedDate
                          data-test="order-date"
                          day="numeric"
                          month="long"
                          value={order.attributes.completed_at}
                          year="numeric"
                        />
                      </Text>
                      <Text fontSize={[1, 3]} fontWeight="bold" mb={0} textAlign={['center', 'left']} width={['33%', '25%']}>
                        {order.attributes.number}
                      </Text>
                      <Hide below={540}>
                        <Text fontSize={3} fontWeight="bold" mb={0} textAlign={['center', 'left']} width="25%">
                          {order.attributes.item_count}
                        </Text>
                      </Hide>
                      <Text fontSize={[1, 3]} fontWeight="bold" mb={0} textAlign="right" width={['33%', '25%']}>
                        <Currency value={(order.attributes.total)} />
                      </Text>
                    </Box>
                  </StyledHeader>

                  <Accordion.Collapse eventKey={order.id}>
                    <Card.Body>
                      <Row>
                        <Col className="mt-3" md={12}>
                          <Row>
                            <Col className="mt-3" md={5}>
                              <Text fontSize={3} fontWeight="bold" mb={0}>
                                <FormattedMessage id="shippingAddress" />
                              </Text>

                              <FormattedAddress address={order.attributes.shipping_address.data.attributes} />
                            </Col>

                            <Col className="mt-3" md={{ offset:2, span:5 }}>
                              <Text fontSize={3} fontWeight="bold" mb={0}>
                                <FormattedMessage id="billingAddress" />
                              </Text>

                              <FormattedAddress address={order.attributes.billing_address.data.attributes} />
                            </Col>
                          </Row>
                        </Col>

                        <Col>
                          <Divider />
                        </Col>

                        <Col className="mt-0" md={12}>
                          {order.attributes.line_items.map(item =>
                            <ReviewOrderItem item={item} key={item.id} readOnly />,
                          )}
                        </Col>

                        <Col>
                          <Divider />
                        </Col>

                        <Col className="mt-3" md={{ offset:6, span:6 }}>
                          <OrderElement translationId="orderElementBundleServiceFee" value={order.attributes.sum_fee} />
                          {order.attributes.adjustment_total !== '0.0' && <OrderElement isBold translationId="promotion" value={order.attributes.adjustment_total} />}
                          <OrderElement translationId="orderElementRefundable" value={order.attributes.sum_deposit} />
                          <OrderElement translationId="orderElementShipping" value={order.attributes.ship_total} />
                          <OrderElement translationId="orderElementTotal" value={order.attributes.total} />
                          <OrderElement translationId="orderElementDeducted" value={order.attributes.total_applied_store_credit} />
                          <OrderElement isHighlighted translationId="orderElementCharged" value={order.attributes.total - order.attributes.total_applied_store_credit} />
                        </Col>

                      </Row>
                    </Card.Body>
                  </Accordion.Collapse>
                </StyledCard>
              </Accordion>
            ))}
          </>
        }
      </Col>
    </Row>
  )
}

const StyledHeader = styled(Card.Header)`
  cursor: pointer;
`

const StyledCard = styled(Card)`
  margin-bottom: 24px;
  border: 1px solid ${props => props.theme.colors.gray.light} !important;
`

OrderHistory.propTypes = {
  user: userPropTypes,
}

export default withProfile(OrderHistory)
