import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import EqualLayout from '../content-box//equal-layout/EqualLayout'

const PopUpModal = ({ content, custom, flexDirection, index, isImgRight }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const hideModal = () => {
    localStorage.setItem(content.cacheTitle, true)

    setIsModalVisible(false)
  }

  useEffect(() => {
    const alreadySaw = localStorage.getItem(content.cacheTitle)

    if (!alreadySaw)
      setIsModalVisible(true)
  }, [])

  if (!isModalVisible)
    return null

  return (
    <Modal
      className="customModal"
      onHide={hideModal}
      show={isModalVisible}
      size="md"
    >
      {content.title && (
        <Modal.Header>
          <Modal.Title
            as="h3"
            color="primary.dark"
            fontSize={3}
          >{content.title}
          </Modal.Title>
        </Modal.Header>
      )}
      <EqualLayout
        content={content.content}
        custom={custom}
        hideModal={hideModal}
        hideTitle={true}
        highlightedLinkText={content.highlightedLinkText}
        highlightedLinkTo={content.highlightedLinkTo}
        id={content.id}
        image={content.image}
        index={index + 1}
        isImgRight={isImgRight}
        wrapperFlexProps={{ flexDirection }}
      />
    </Modal>
  )
}

PopUpModal.propTypes = {
  content: PropTypes.object,
  custom: PropTypes.object,
  flexDirection: PropTypes.array || PropTypes.string,
  index: PropTypes.number,
  isImgRight: PropTypes.bool,
}

export default PopUpModal
