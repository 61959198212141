import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from '../../lodash'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Alert, Container, Form, InputGroup } from 'react-bootstrap'
import { Box, Button, OrderElement, Text } from '../../elements'
import { CartItem, TextField } from '../../components'
import { TranslatedTitleLoader, withProfile } from '../../modules'
import { addNotification, trackEvent } from '../../utils'
import { userPropTypes } from '../../types'
import client from '../../api/api'

export const ReviewOrder = ({ onChange, user }) => {
  const formRef = useRef(null)
  const [couponCode, setCouponCode] = useState('')

  const handleAddCouponCode = async () => {
    const bearerToken = await user.getTokenForRequest()
    const couponResponse = await client.cart.applyCouponCode(
      { bearerToken },
      {
        coupon_code: couponCode,
      },
    )

    if (couponResponse.isFail()) {
      addNotification({
        backendError: couponResponse.fail().serverResponse.data.error,
        titleId: 'notificationErrorTitle',
        type: 'danger',
      })

      return false
    }

    addNotification({
      messageId: 'promotionAdded',
      titleId: 'notificationSuccessTitle',
      type: 'success',
    })

    setCouponCode('')
    formRef.current.reset()

    user.refetchCart()

    trackEvent('User', 'Added coupon code', 'Checkout Page - Review and authorize order')
  }

  const {
    cart: {
      adjustment_total,
      line_items,
      promotions,
      ship_total,
      sum_deposit,
      sum_fee,
      total,
      total_applicable_store_credit_for_deposit,
    },
  } = user

  return (
    <Container>
      <TranslatedTitleLoader titleId="pageTitleCart" />

      <Box alignItems="flex-start" display="flex" flexWrap={['wrap', 'nowrap']}>
        <Box flex="1 1 auto" mr={[0, 5]} my={[4, 0]}>
          {line_items.map(item =>
            <CartItem item={item} key={item.id} />,
          )}

          <Alert variant="info">
            <Text mb={0} p={3}>
              <FormattedMessage id="freePickUpDescription" />
            </Text>
          </Alert>
        </Box>

        <Box borderRadius={2} flex="1 1 400px" p={3} style={{ boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.15)' }}>
          <Box borderBottom="1px solid" mb={2}>
            <FormattedMessage id="enterCoupon">
              {translatedMessage => (
                <Form className="mb-2" ref={formRef}>
                  <StyledInputGroup className="mb-3">
                    <TextField
                      defaultValue={''}
                      maxLength="30"
                      mb={0}
                      name="couponCode"
                      onChange={({ target }) => setCouponCode(target.value)}
                      placeholder={translatedMessage}
                      type="text"
                    />
                    <InputGroup.Append>
                      <Button
                        disabled={isEmpty(couponCode)}
                        onClick={handleAddCouponCode}
                        px={3}
                        type="button"
                      >
                        <FormattedMessage id="redeem" />
                      </Button>
                    </InputGroup.Append>
                  </StyledInputGroup>
                </Form>
              )}
            </FormattedMessage>

            {promotions &&
              <Text color="primary.dark" mb={4}>
                <FormattedMessage id="applied" />: {promotions.attributes.name}
              </Text>
            }
          </Box>

          <OrderElement translationId="orderElementBundleServiceFee" value={sum_fee} />
          {promotions && <OrderElement isBold translationId="promotion" value={adjustment_total} />}
          <OrderElement translationId="orderElementRefundable" value={sum_deposit} />
          <OrderElement isFree translationId="orderElementShipping" value={ship_total} />
          <OrderElement translationId="orderElementTotal" value={total} />
          <OrderElement translationId="orderElementDeducted" value={total_applicable_store_credit_for_deposit} />
          <OrderElement isHighlighted translationId="orderElementCharged" value={total - total_applicable_store_credit_for_deposit} />
        </Box>
      </Box>

      <Box mt={40}>
        <Text>
          <FormattedMessage id="byClicking" />
        </Text>
      </Box>
    </Container>
  )
}

const StyledInputGroup = styled(InputGroup)`
  flex-wrap: nowrap;

  .form-group {
    width: 100%;

    margin-bottom: 0;
  }

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  button {
    width: auto;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`

ReviewOrder.propTypes = {
  onChange: PropTypes.func,
  user: userPropTypes,
}

export default withProfile(ReviewOrder)
