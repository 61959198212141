import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Box, BannerCoverImage, Hide, Text } from '../../elements'
import { GetStarted } from '../../components'
import { Loading } from '../'
import { hasProtocol, trackEvent } from '../../utils'
import images from '../../../assets/images/newcb'

const BannerImage = ({ content: { button, image, text }, isLoading }) => {
  const isItExternal = hasProtocol(button?.link)

  if (isLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <Box id="home-carousel" mb={4}>
      {image &&
        <Hide below={400}>
          <BannerCoverImage
            alt={image?.title}
            className="animate__animated animate__slideInRight"
            height="100%"
            image={image.url}
            isFit={true}
            isLoaded={true}
          />
        </Hide>
      }

      {text && (
        <ExtraTextBox className="home-banner">
          <Box px={['11%', '14%', '14%']} py={['17%']}>
            <PimpedText
              className="home-banner-text animate__animated animate__fadeInDown"
              fontSize={[14, 20, 22, 26]}
              fontWeight="bold"
              lineHeight={['26px']}
            >
              <Text>
                {text}
              </Text>
            </PimpedText>

            <Text className="home-banner-link" m={0} textAlign="center">
              {button &&
                <GetStarted
                  external={isItExternal}
                  link={button?.link}
                  onClick={() => {trackEvent('User', 'Clicked carousel button')}}
                  text={button?.text}
                />
              }
            </Text>
          </Box>
        </ExtraTextBox>
      )}
    </Box>
  )
}

const PimpedText = styled(Box)`
  font-family: 'SouvenirStd', sans-serif !important;

  background: url(${images.bannerTextPimp});
  background-repeat: no-repeat;
  background-size: 16%;
  background-position: 39px bottom;

  padding-bottom: 20px;
  margin-bottom: 0;

  @media (max-width: 756px) {
    background: none;

    padding-bottom: 0;

    line-height: 14px;
  }

  @media (max-width: 870px) {
    background: none;

    padding-bottom: 0;

    line-height: 20px;
  }


`

const ExtraTextBox = styled(Box)`
  position: absolute;
  top: 20%;
  left: 20%;

  width: 60%;
  min-height: 54%;

  background: url(${images.bannerExtraTextBg});
  background-size: contain;  
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 756px) {
    top: 10%;
    left: 0;
  }

  @media (max-width: 400px) {
    position: static;

    width: 100%;
  }
`

BannerImage.propTypes = {
  content: PropTypes.shape({
    button: PropTypes.object,
    image: PropTypes.object,
    text: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
}

export default BannerImage
