import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../../lodash'
import { FormattedMessage } from 'react-intl'
import { Navigate, useNavigate } from 'react-router-dom'
import { Button, Heading } from '../../../elements'
import { GoogleCaptcha, TextField } from '../../../components'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { addNotification, isValidEmail, scrollToElement, trackEvent } from '../../../utils'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export let recaptchaInstance

export const RecoveryPage = ({ user }) => {
  let navigate = useNavigate()

  useEffect(() => {
    scrollToElement()
  }, [])

  const [ isLoading, setIsLoading ] = useState(false)
  const [ formError, setFormError ] = useState({})
  const [ formState, setFormState ] = useState({
    email: '',
    recaptchaResponse: '',
  })

  if (user.me)
    return <Navigate to="/" />

  const handleInputChange = (event) => {
    const { name , value } = event.target

    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const checkFormValidation = () => {
    const errors = {}

    if (!isValidEmail(formState.email))
      errors.email = 'emailIsInvalid'

    if (isEmpty(formState.recaptchaResponse))
      errors.recaptchaResponse = 'blank'

    setFormError(errors)

    return isEmpty(errors)
  }

  const handleClick = (event) => {
    event.preventDefault()

    setFormError({})
    const isValidForm = checkFormValidation()

    if (isValidForm) {
      setIsLoading(true)

      client.account.forgotPassword({
        user: {
          email: formState.email,
          recaptcha_response: formState.recaptchaResponse,
        },
      })
        .then((response) => {

          if (response.isSuccess()) {
            addNotification({
              messageId: 'notificationRecoveryMsg',
              titleId: 'notificationSuccessTitle',
              type: 'success',
            })

            trackEvent('User', 'Requested a new password', 'Recovery Page')

            navigate('/', { replace: true })
          } else {
            throw response.fail().serverResponse
          }
        })
        .catch((error) => {
          setIsLoading(false)

          recaptchaInstance.props?.grecaptcha?.reset()
          setCaptchaToken(null)

          addNotification({
            backendError: error.statusText,
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })
        })
    }
  }

  const setCaptchaInstance = (captchaInstance) => {
    recaptchaInstance = captchaInstance
  }

  const setCaptchaToken = (token) => {
    setFormState(prevState => ({
      ...prevState,
      recaptchaResponse: token,
    }))
  }

  return (
    <Container className="my-5">
      <TranslatedTitleLoader titleId="pageTitleRecovery" />

      <Row className="recovery-page">
        <Col lg={{ offset:4, span:4 }} md={{ offset:3, span:6 }}>
          <Heading className="recovery-page-title" fontSize="2.3rem" mb={3} textAlign="center">
            <FormattedMessage id="passwordRecovery" />
          </Heading>

          <Form id="recovery-form">
            <FormattedMessage id="enterYourEmail">
              {translatedMessage => (
                <TextField
                  errorTranslationId={formError?.email}
                  label={translatedMessage}
                  name="email"
                  onChange={handleInputChange}
                  type="email"
                />
              )}
            </FormattedMessage>

            <Col className="text-center">
              <GoogleCaptcha
                error={formError?.recaptchaResponse}
                onChange={setCaptchaToken}
                setCaptchaInstance={setCaptchaInstance}
              />
            </Col>

            <Button
              className="primary-button"
              disabled={isLoading}
              id="recovery-button"
              onClick={handleClick}
              type="submit"
              variant="primary"
              width="100%"
            >
              <FormattedMessage id="sendMeRecoveryLink" />
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

RecoveryPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(RecoveryPage)
