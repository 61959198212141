import 'react-app-polyfill/ie9'
import React from 'react'
import { createRoot } from 'react-dom/client'
import Root from '../Root'

document.addEventListener('DOMContentLoaded', () => {
  const container = document.body.appendChild(document.createElement('div'))
  const root = createRoot(container)
  root.render(<Root />)
})
