import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, space } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Dropdown, Image, Row } from 'react-bootstrap'
import images from '../../../../assets/images'
import { Cart } from '../../../components'
import { Hide } from '../../../elements'
import { withProfile } from '../../../modules'
import { userPropTypes } from '../../../types'
import { userMenu } from '../../../constants'
import { handleClickOutside } from '../header-utils'

import './user-menu.scss'

export const UserMenuIcon = ({ expanded, handleMenuClick }) => {
  let domNode = handleClickOutside(() => {
    handleMenuClick(false)
  })

  return (
    <Row>
      <div
        className="logged-user"
        id="dropdown-basic"
        onClick={() => handleMenuClick(!expanded)}
        variant="info"
      >
        <Image alt="User" className="user-icon" fluid ref={domNode} src={images.userIcon} />
      </div>

      <Cart />
    </Row>
  )
}

UserMenuIcon.propTypes = {
  expanded: PropTypes.bool,
  handleMenuClick: PropTypes.func,
}

export const UserMenuDropdown = ({ expanded, handleMenuClick }) => {
  return (
    <div className={`dropdown-menu ${expanded ? 'opened' : 'closed'} logged-user-menu`}>
      {userMenu.map(({ linkTo, translationId }) => (
        <div key={translationId}>
          <Dropdown.ItemText onClick={() => handleMenuClick(!expanded)}>
            <Link className="user-menu-link" to={linkTo}>
              <FormattedMessage id={translationId} />
            </Link>
          </Dropdown.ItemText>

          <Dropdown.Divider />
        </div>
      ))}
    </div>
  )
}

UserMenuDropdown.propTypes = {
  expanded: PropTypes.bool,
  handleMenuClick: PropTypes.func,
}

export const LoginAndSignupButton = () => (
  <Row>
    <StyledLink
      bg="primary.dark"
      className="nav-link header-login-link"
      color="gray.light"
      id="loginLink"
      mr={[0, 2]}
      to="/login"
    >
      <FormattedMessage id="signInOrRegister" />
    </StyledLink>

    <Cart />
  </Row>
)

export const UserMenu = ({ expanded, handleMenuClick, user: { me } }) => (
  <Hide below={992}>
    <div className="user-menu">
      {me ? <UserMenuIcon expanded={expanded} handleMenuClick={handleMenuClick} /> : <LoginAndSignupButton />}
    </div>
  </Hide>
)

const StyledLink = styled(Link)`
  ${color}
  ${space}
  
  border-radius: 20px;

  font-weight: 400;

  font-size: 14px;

  padding: 6px 20px;

  line-height: 24px;

  &:hover {
    ${color};
    text-decoration: underline;
  }
`

UserMenu.propTypes = {
  expanded: PropTypes.bool,
  handleMenuClick: PropTypes.func,
  user: userPropTypes,
}

UserMenuIcon.displayName = 'UserMenuIcon'

export default withProfile(UserMenu)
