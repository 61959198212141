export const isValidMonth = (month) => {
  const parsed = parseInt(month, 10)

  return parsed > 0 && parsed < 13
}

export const isExpiredMonth = (month, year) => {
  const now = new Date()
  const currentYear = parseInt(now.getFullYear().toString().substr(-2), 10)
  const parsedYear = parseInt(year, 10)

  if (parsedYear === currentYear) {
    const parsedMonth = parseInt(month, 10)

    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1

    return parsedMonth < currentMonth
  }

  return false
}

export const isValidYear = (year) => {
  const now = new Date()
  const currentYear = now.getFullYear().toString().substr(-2)
  const parsed = parseInt(year, 10)

  return parsed >= currentYear
}

export const isValidVerificationValue = (card, value) =>
  !!(
    card
    && value
    && card.code
    && card.code.size === value.toString().length
  )
