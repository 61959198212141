import client from '../../api/api'
import { getCookie, setCookie } from '../../utils'

export const saveToken = ({ access_token, created_at, expires_in, refresh_token }) => {
  setCookie('access_token', access_token, expires_in)
  setCookie('token_created_at', created_at, expires_in)

  localStorage.setItem('refresh_token', refresh_token)
}

export const removeToken = () => {
  setCookie('access_token', '', -1)
  setCookie('token_created_at', '', -1)

  localStorage.removeItem('cart_token')
  localStorage.removeItem('refresh_token')
}

export const removeCartToken = () => localStorage.removeItem('cart_token')
export const setCartToken = (cartToken) => localStorage.setItem('cart_token', cartToken)
export const getCartToken = () => localStorage.getItem('cart_token')
export const getAccessToken = () => getCookie('access_token')

export const getAndCheckAccessToken = () => (
  new Promise(resolve => {
    if (isTokenExist()) {
      resolve(getCookie('access_token'))
    } else {
      if (isTokenExpired()) {
        refreshToken()
          .then((response) => {
            if (response.isSuccess()) {
              const tokens = response.success()

              saveToken(tokens)

              resolve(tokens.access_token)
            } else {
              removeToken()

              resolve(null)
            }
          })
      } else {
        resolve(null)
      }
    }
  })
)

export const getTokenCreationTime = () => getCookie('token_created_at')

export const getRefreshToken = () => localStorage.getItem('refresh_token')

export const isTokenExist = () => !!getCookie('access_token')

export const refreshToken = (refreshToken = getRefreshToken()) => (
  client.authentication.refreshToken({
    refresh_token: refreshToken,
  })
)

export const isTokenExpired = () => (!isTokenExist() && !!getRefreshToken())

export const signIn = ({ email, password }) => (
  client.authentication.getToken({
    password,
    username: email,
  })
)
