import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Outlet, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { color } from 'styled-system'
import { Row } from 'react-bootstrap'
import { ReactNotifications } from 'react-notifications-component'
import TagManager from 'react-gtm-module'
import {
  FacebookPixel,
  Footer,
  GoogleAnalytics,
  Header,
} from 'components'
import { ContentBox } from 'elements'
import * as dotenv from 'dotenv'
import strapi from './api/strapi'
import {
  DefaultTitleAndIconLoader,
  SearchIndexingBlocker,
  TranslatedMetaLoader,
  UserProvider,
} from './modules'

import newImages from '../assets/images/newcb'

import 'app.scss'
import 'react-loading-skeleton/dist/skeleton.css'

dotenv.config()

const isProd = process.env.RAILS_ENV === 'production'
const oneTrustKey = isProd ? '9141e4d9-59f6-420a-99cb-4691adb24813' : '9141e4d9-59f6-420a-99cb-4691adb24813-test'

const tagManagerArgs = {
  dataLayerName: 'PageDataLayer',
  gtmId: 'GTM-56PF5BQ4',
}

TagManager.initialize(tagManagerArgs)

const App = () => {
  const location = useLocation()
  const [bgColor, setBgColor] = useState('#fff')
  const [isPopUpLoading, setPopUpLoading] = useState(true)
  const [strapiPopUp, setStrapiPopUp] = useState(null)
  const isTestMode = process.env.RAILS_ENV !== 'production'
  const customBgPages = {
    '/consumer-privacy': 'box.primary',
    '/contact-us': 'box.primary',
    '/cost-comparison': 'box.primary',
    '/how-it-works': 'box.primary',
    '/our-mission': 'box.primary',
    '/privacy-policy': 'box.primary',
    '/support-and-faq': 'box.primary',
    '/terms-and-conditions': 'box.primary',
  }

  useEffect(() => {
    setBgColor(customBgPages[location.pathname] || '#fff')
  }, [location.pathname])

  useEffect(() => {
    const fetchPopUp = async () => {
      const { data } = await strapi.get('/popup?&populate[popUpContent][image][populate]=*&populate[popUpContent][populate]=*&populate[highlightedLink][populate]=*')

      if (data) {
        setStrapiPopUp(data.data.attributes)
      }

      setPopUpLoading(false)
    }

    fetchPopUp()
  }, [])

  return (
    <UserProvider>
      <DefaultTitleAndIconLoader />

      <TranslatedMetaLoader
        data-test="meta-description"
        metaArray={[
          { content: 'metaText', name: 'description' },
          { content: 'metaText', property: 'og:description' },
        ]}
      />

      <Helmet>
        <meta
          content={`${window.origin}${newImages.ogImage}`}
          key="og:image"
          property="og:image"
        />

        <script
          src={`https://cdn.cookielaw.org/consent/${oneTrustKey}/OtAutoBlock.js`}
          type="text/javascript"
        >
        </script>

        <script
          src={'https://tag.rmp.rakuten.com/126309.ct.js'}
          type="text/javascript"
        >
        </script>

        <script
          charset="UTF-8"
          data-domain-script={oneTrustKey}
          onload="function OptanonWrapper() {}"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
        >
        </script>
      </Helmet>

      <SearchIndexingBlocker />
      <FacebookPixel />
      <GoogleAnalytics testMode={isTestMode} />

      <ReactNotifications data-test="notification" />

      <Header />

      <ContentBox content={strapiPopUp?.popUpContent} isLoading={isPopUpLoading} type="modal" />

      <ContainerWithBg bg={bgColor} className="main-content-with-footer">
        <Row className="app-container">
          <Outlet />
        </Row>
      </ContainerWithBg>

      <Footer />
    </UserProvider>
  )
}

const ContainerWithBg = styled.div`
  ${color}
`

export default App
