import { useEffect, useRef } from 'react'
import { isObject } from '../../lodash'

/*eslint react-hooks/rules-of-hooks: "off"*/
export const handleClickOutside = (handler) => {
  const domNode = useRef()

  useEffect(() => {
    const handleClick = (event) => {
      if (isObject(event.target.className))
        return false

      if (!domNode.current?.contains(event.target) && !event.target.className.includes('user-menu-link')) {
        handler()
      }
    }

    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })

  return domNode
}
