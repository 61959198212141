import React from 'react'
import IcoMoon from 'react-icomoon'

const iconSet = require('../../constants/icons.json')

const Icon = ({ ...props }) => (
  <IcoMoon iconSet={iconSet} {...props} />
)

export default Icon
