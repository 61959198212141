import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { BannerImage, HowItWorks } from '../../components'
import { ContentBox, HeadingWithBg } from '../../elements'
import strapi from '../../api/strapi'

const HomePage = () => {
  const [isHomePageLoading, setHomePageLoading] = useState(true)
  const [strapiHomePage, setStrapiHomePage] = useState(null)

  useEffect(() => {
    const fetchHomePage = async () => {
      const { data } = await strapi.get('/home-page?populate[HowItWorks][populate]=*&populate[HowItWorks][image][populate]=*&populate[carouselImage][populate]=*&populate[Content][image][populate]=*&populate[Content][populate]=*&populate[button][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiHomePage(data.data.attributes)
      }

      setHomePageLoading(false)
    }

    fetchHomePage()
  }, [])

  return (
    <React.Fragment>
      {strapiHomePage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiHomePage.MetaData?.metaText , name: 'description' },
            { content: strapiHomePage.MetaData?.metaText , property: 'og:description' },
            { content: strapiHomePage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiHomePage.MetaData?.pageTitle}
        />
      }

      <BannerImage
        content={{
          button: strapiHomePage?.button,
          image: strapiHomePage?.carouselImage?.data?.attributes,
          text: strapiHomePage?.CarouselText,
        }}
        isLoading={isHomePageLoading}
      />

      <Container className="mb-5 content-container">
        <HowItWorks isLoading={isHomePageLoading} steps={strapiHomePage?.HowItWorks} />

        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBg"
          my={[0, 50]}
          p="30px"
          textAlign="center"
        >
          <FormattedMessage id="whyLoopDiaperService" />
        </HeadingWithBg>

        <ContentBox content={strapiHomePage?.Content} isLoading={isHomePageLoading} type="shadowCard" />
      </Container>
    </React.Fragment>
  )
}
export default HomePage
