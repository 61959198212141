import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { height } from 'styled-system'

const StyledBannerCoverImage = styled(
  ({ isFit, isLoaded, staticContext, ...restProps }) => <img alt={restProps.alt || ''} src={restProps.image} {...restProps} />,
)`
  ${height}

  max-height: 90vh;

  position: relative;

  z-index: 5;

  --animate-duration: 2000ms;

  transition: .6s filter ease-in;

  ${props => !props.isLoaded && css`
    filter: blur(20px);
    transform: scale(1.03);
  `}

  ${props => props.isFit && css`
    width: 100%;
    object-fit: cover;
  `}
`

StyledBannerCoverImage.displayName = 'CoverImage'

const BannerCoverImage = ({
  isFit,
  isLoaded = true,
  staticContext,
  ...restProps}) => {

  return <StyledBannerCoverImage isFit={isFit} isLoaded={isLoaded} {...restProps} staticContext={staticContext} />
}

BannerCoverImage.propTypes = {
  isFit: PropTypes.bool,
  isLoaded: PropTypes.bool,
  staticContext: PropTypes.object,
}

export default BannerCoverImage
