import React, { useState, useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Button } from '../../elements'
import { addNotification, isValidEmail } from '../../utils'
import client from '../../api/api'

export const Newsletter = () => {
  const emailRef = useRef('')

  const [ isLoading, setIsLoading ] = useState(false)

  const handleClick = (event) => {
    event.preventDefault()

    const email = emailRef.current.value

    if (isValidEmail(email)) {
      setIsLoading(true)

      client.fetcher.fetch({
        method: 'POST',
        url: `api/v2/storefront/newsletter?email=${email}`,
      })
        .then(() => {
          addNotification({
            messageId: 'notificationNewsletterMsg',
            titleId: 'notificationSuccessTitle',
            type: 'success',
          })

          emailRef.current.value = ''
        })
        .catch(({ data }) => {
          addNotification({
            backendError: data.errors,
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })
        })
        .finally(() => {
          setIsLoading(false)
        })

    } else {
      setIsLoading(false)
      addNotification({
        messageId: 'emailIsInvalid',
        titleId: 'notificationErrorTitle',
        type: 'danger',
      })
    }
  }

  return (
    <Form id="newsletter-form">
      <InputGroup className="mb-3 mt-3">
        <FormattedMessage id="newsletterEmailAddress">
          {(translatedMessage) => (
            <Form.Control
              className="newsletter-input"
              name="email"
              placeholder={translatedMessage}
              ref={emailRef}
              type="email"
            />
          )}
        </FormattedMessage>
        <Button
          bg="white"
          className="newsletter-button"
          disabled={isLoading}
          onClick={handleClick}
          type="submit"
        >
          <FormattedMessage id="newsletterButton" />
        </Button>
      </InputGroup>
    </Form>
  )
}

export default Newsletter
