import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { equals } from 'validator'
import { isEmpty } from '../../../lodash'
import { FormattedMessage } from 'react-intl'
import { Accordion, Card, Container, Form, Row } from 'react-bootstrap'
import { confirm } from 'react-bootstrap-confirmation'
import { Box, Col, Button, Heading, Text } from '../../../elements'
import {
  AccountInformation,
  CreditCardPanel,
  Loading,
  PasswordInput,
} from '../../../components'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { addNotification, getBackendErrors, passwordValidator, scrollToElement, trackEvent } from '../../../utils'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export const AccountSettings = ({ user }) => {
  const [accountInformationErrors, setAccountInformationErrors] = useState(null)
  const [addressesList, setAddressesList] = useState([])
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [resetForm, setResetForm] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [expandedPanel, setExpandedPanel] = useState(null)
  const [passwordFormError, setPasswordFormError] = useState(null)
  const [passwordFormState, setPasswordFormState] = useState({
    password: '',
    password_confirmation: '',
  })
  const [accountInformation, setAccountInformation] = useState({
    address: {
      apartment:'',
      city:'',
      country:'',
      firstName:'',
      lastName:'',
      phoneNumber:'',
      state:'',
      streetAddress:'',
      zipCode:'',
    },
  })

  const passwordFormRef = useRef(null)

  useEffect(() => {
    if (!user.isLoading)
      getAddressesList()
  }, [])

  const getAddressesList = async () => {
    const bearerToken = await user.getTokenForRequest()

    const response = await client.account.addressesList({ bearerToken })

    if (response.isSuccess()) {
      const { data } = response.success()

      setAddressesList(data)
    } else {
      addNotification({
        backendError: response.fail().serverResponse?.data?.error,
        messageId: 'notificationSomethingWentWrong',
        titleId: 'notificationErrorTitle',
        type: 'danger',
      })
    }
  }

  useEffect(() => {
    setTimeout(() => scrollToElement(expandedPanel, 'aboveElement'), 400)
  }, [expandedPanel])

  if (user.isLoading)
    return <Loading />

  const handleAccountFormChange = (formData) => {
    setAccountInformation(prevState => ({
      ...prevState,
      ...formData,
    }))
  }

  const handlePasswordInputChange = (event) => {
    const { id , value } = event.target

    setPasswordFormState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const checkFormValidation = () => {
    const errors = {}
    if (!passwordValidator(passwordFormState.password).isValidPassword) {
      errors.password = 'passwordIsInvalid'
    }

    if (!equals(passwordFormState.password, passwordFormState.password_confirmation)) {
      errors.password_confirmation = 'passwordDoesntMatch'
    }

    setPasswordFormError(errors)

    return isEmpty(errors)
  }

  const handlePasswordUpdateClick = async (event) => {
    event.preventDefault()

    setPasswordFormError(null)

    const isValidForm = checkFormValidation()

    if (isValidForm) {
      const bearerToken = await user.getTokenForRequest()

      setIsFormLoading(true)

      const { password, password_confirmation } = passwordFormState

      const response = await client.account.update(
        { bearerToken },
        {
          user: {
            password,
            password_confirmation,
          },
        },
      )

      if (response.isSuccess()) {
        getAddressesList()
        addNotification({
          messageId: 'notificationUpdateUserMsg',
          titleId: 'notificationSuccessTitle',
          type: 'success',
        })

        setPasswordFormState({
          password: '',
          password_confirmation: '',
        })

        setIsFormLoading(false)

        passwordFormRef.current.reset()
        trackEvent('User', 'Updated the password', 'User Page - Account settings')
      } else {
        addNotification({
          messageId: 'notificationSomethingWentWrong',
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })

        setIsFormLoading(false)
      }
    }
  }

  const handleAddressRemove = async () => {
    const result = await confirm('Are you sure?')

    const bearerToken = await user.getTokenForRequest()

    if (result && accountInformation.address.id) {
      const response = await client.account.removeAddress({ bearerToken }, accountInformation.address.id)

      if (response.isSuccess()) {
        getAddressesList()

        addNotification({
          messageId: 'notificationUpdateUserMsg',
          titleId: 'notificationSuccessTitle',
          type: 'success',
        })

        trackEvent('User', 'Removed a saved address', 'User Page - Account settings')
      } else {
        addNotification({
          messageId: 'notificationSomethingWentWrong',
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })
      }
    }
  }

  const handleAddressChange = async () => {
    const { address, addressFormIsValid } = accountInformation
    setShowErrors(false)

    if (addressFormIsValid) {
      const bearerToken = await user.getTokenForRequest()

      const newAddress = {
        address1: address.streetAddress,
        address2: address.apartment,
        city: address.city,
        country_iso: address.country,
        firstname: address.firstName,
        lastname: address.lastName,
        phone: address.phoneNumber,
        state_name: address.state,
        zipcode: address.zipCode,
      }

      let updateOrCreateUserAddress = (address.id)
        ? await client.account.updateAddress(
          { bearerToken },
          address.id,
          { address: newAddress },
        )
        : await client.account.createAddress(
          { bearerToken },
          { address: newAddress },
        )

      if (updateOrCreateUserAddress.isSuccess()) {
        addNotification({
          messageId: 'notificationUpdateUserMsg',
          titleId: 'notificationSuccessTitle',
          type: 'success',
        })

        setAccountInformation({
          address: {
            apartment:'',
            city:'',
            country:'',
            firstName:'',
            lastName:'',
            phoneNumber:'',
            state:'',
            streetAddress:'',
            zipCode:'',
          },
        })

        setResetForm(!resetForm)
        getAddressesList()

        trackEvent('User', 'Added/modified an address', 'User Page - Account settings')
      } else {
        const backendErrors = getBackendErrors(updateOrCreateUserAddress.fail().serverResponse.data.errors)

        setAccountInformationErrors(backendErrors)

        addNotification({
          backendError: updateOrCreateUserAddress.fail().serverResponse.data.error,
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })
      }
    } else {
      setShowErrors(true)
    }
  }

  return (
    <Container className="mb-5">

      <TranslatedTitleLoader titleId="pageTitleUserAccount" />

      <Row className="checkout-page">
        <Col md="12">
          <Heading as="h2" className="checkout-page-title" my={5} textAlign="left">
            <FormattedMessage id="accountSettings" />
          </Heading>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={expandedPanel !== 'addressesList'}
                onClick={setExpandedPanel}
                titleId="addressesList"
              />
              <Accordion.Collapse eventKey="addressesList">
                <Card.Body>
                  {expandedPanel === 'addressesList' &&
                    <AccountInformation
                      addressesList={addressesList}
                      addressType="address"
                      errors={accountInformationErrors}
                      listenAddresList={true}
                      onChange={handleAccountFormChange}
                      onReset={resetForm}
                      showErrors={showErrors}
                    />
                  }

                  <Row>
                    <Col>
                      <Button
                        className="mt-2"
                        onClick={handleAddressChange}
                        type="button"
                        width="100%"
                      >
                        <FormattedMessage id={accountInformation.address.id ? 'update' : 'save'} />
                      </Button>
                    </Col>
                  </Row>

                  {accountInformation.address.id &&
                    <Row>
                      <Col>
                        <Button
                          className="mt-2"
                          color="alert"
                          onClick={handleAddressRemove}
                          type="button"
                          variant="link"
                          width="100%"
                        >
                          <FormattedMessage id="removeThisAddress" />
                        </Button>
                      </Col>
                    </Row>
                  }
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>

          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={expandedPanel !== 'changePassword'}
                onClick={setExpandedPanel}
                titleId="changePassword"
              />
              <Accordion.Collapse eventKey="changePassword">
                <Card.Body>
                  <Row className="justify-content-md-center">
                    <Col md="12">
                      <Form id="password-reset-form" ref={passwordFormRef}>
                        <Form.Group controlId="password">
                          <Form.Label md="4">
                            <FormattedMessage id="enterYourNewPassword" />*
                          </Form.Label>
                          <PasswordInput
                            onChange={handlePasswordInputChange}
                            value={passwordFormState.password}
                          />

                          {passwordFormError?.password &&
                            <Form.Control.Feedback type="invalid">
                              <FormattedMessage id={passwordFormError.password} />
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Form.Group controlId="password_confirmation">
                          <Form.Label md="4">
                            <FormattedMessage id="enterYourNewPasswordOneMoreTime" />*
                          </Form.Label>

                          <PasswordInput
                            name="password_confirmation"
                            onChange={handlePasswordInputChange}
                            showHint={false}
                            value={passwordFormState.password_confirmation}
                          />

                          {passwordFormError?.password_confirmation &&
                            <Form.Control.Feedback type="invalid">
                              <FormattedMessage id={passwordFormError.password_confirmation} />
                            </Form.Control.Feedback>
                          }
                        </Form.Group>

                        <Button
                          className="full-width-button primary-button"
                          disabled={isFormLoading}
                          id="password-reset-button"
                          onClick={handlePasswordUpdateClick}
                          type="submit"
                          variant="primary"
                          width="100%"
                        >
                          <FormattedMessage id="setNewPassword" />
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>

          <Accordion activeKey={expandedPanel}>
            <StyledCard>
              <CardHeader
                isButtonVisible={expandedPanel !== 'creditCardList'}
                onClick={setExpandedPanel}
                titleId="creditCardList"
              />
              <Accordion.Collapse eventKey="creditCardList">
                <Card.Body>
                  {expandedPanel === 'creditCardList' &&
                    <CreditCardPanel
                      buttonTextId="save"
                      isDeleteEnabled
                      isSaveEnabled={false}
                    />
                  }
                </Card.Body>
              </Accordion.Collapse>
            </StyledCard>
          </Accordion>
        </Col>
      </Row>
    </Container>
  )
}

const CardHeader = ({ isButtonVisible, onClick, titleId }) => (
  <Card.Header>
    <Box alignItems="center" display="flex" id={titleId} justifyContent="space-between">
      <Text fontSize={3} fontWeight="bold" mb={0}>
        <FormattedMessage id={titleId} />
      </Text>

      <Box>
        {isButtonVisible &&
           <Button
             color="primary.dark"
             fontSize={18}
             fontWeight={700}
             onClick={() => onClick(titleId)}
             py={0}
             type="button"
             variant="link"
           >
             <FormattedMessage id="edit" />
           </Button>
        }
      </Box>
    </Box>
  </Card.Header>
)

CardHeader.propTypes = {
  isButtonVisible: PropTypes.bool,
  onClick: PropTypes.func,
  titleId: PropTypes.string,
}

const StyledCard= styled(Card)`
  margin-bottom: 24px;
  border: 1px solid ${props => props.theme.colors.gray.light}!important;
`

AccountSettings.propTypes = {
  user: userPropTypes,
}

export default withProfile(AccountSettings)
