import colors from '../variables.scss'

/* eslint-disable sort-keys */
const theme = {
  breakpoints: ['768px', '992px', '1024px', '1200px'],
  space: [0, 4, 8, 16, 20, 24, 32, 64, 128],
  colors: {
    background: {
      light: colors['newCb-bg'],
    },
    default: '#51add2',
    primary: {
      main: colors['newCb-primary'],
      dark: colors['newCb-secondary'],
    },
    box: {
      primary: colors['newCb-textbg'],
      secondary: colors['newCb-primary'],
    },
    secondary: '#f9c70d',
    alert: '#ff0000',
    black: colors['newCb-black'],
    dark: '#282828',
    gray: {
      light: colors['newCb-grey-light'],
      main: '#686058',
      dark: colors['newCb-grey-dark'],
    },
    white: '#fff',
  },
  fontSizes: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 86],
  weights: {
    light: 300,
    normal: 400,
    bold: 800,
  },
  radii: [0, 5, 10, 15, 20],
}

export default theme
