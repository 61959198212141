import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Col, Container, Row } from 'react-bootstrap'
import { Box, HeadingWithBg, Text } from '../../elements'
import { Loading } from '../../components'
import strapi from '../../api/strapi'

const TermsAndConditions = () => {
  const [isTermsAndConditionLoading, setTermsAndConditionLoading] = useState(true)
  const [strapiTermsAndConditionPage, setStrapiTermsAndConditionPage] = useState(null)

  useEffect(() => {
    const fetchTermsAndConditionsPage = async () => {
      const { data } = await strapi.get('/terms-and-condition?populate[content][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiTermsAndConditionPage(data.data.attributes)
      }

      setTermsAndConditionLoading(false)
    }

    fetchTermsAndConditionsPage()
  }, [])

  if (isTermsAndConditionLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiTermsAndConditionPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiTermsAndConditionPage.MetaData?.metaText , name: 'description' },
            { content: strapiTermsAndConditionPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiTermsAndConditionPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiTermsAndConditionPage.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container">
        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBgV4"
          my={30}
          p={70}
          position="relative"
          textAlign="center"
        >
          <FormattedMessage id="termsAndConditions" />
        </HeadingWithBg>

        <Row>
          <Col md={12}>
            <Text>
              {strapiTermsAndConditionPage.title}
            </Text>

            {React.Children.toArray(
              strapiTermsAndConditionPage?.content.map((data) =>
                <CustomContentStyle className="line2" pl={0}>
                  <BlocksRenderer content={data.content} />
                </CustomContentStyle>
              )
            )}
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )}

const CustomContentStyle = styled(Box)`
  margin-bottom: 16px;

  background-color: #fff;
  padding: 24px;

  h1, h2, h3, h4, h5, h6 {
    color: #444444;

  }

  .box {
    h1, h2, h3, h4, h5, h6 {
      color: #444444;

      margin-left: 0;
    }

    background-color: #fff;
    border-radius 4px;
  }

  @media (max-width: 756px) {
    h1 {
      font-size: 24px;
    }
  
  }
`

export default TermsAndConditions
