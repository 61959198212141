import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form, Row } from 'react-bootstrap'
import { compact, isEmpty } from '../../lodash'
import { TextField } from '../'
import { States } from '../../components'
import { Col, Text, Select, Separator } from '../../elements'
import client from '../../api/api'

const AccountInformation = ({ addressesList, addressType, defaultAddress, disabledFields=[], errors={}, listenAddresList=false, onChange, onReset, showErrors=false }) => {
  const initState = {
    apartment: '',
    city: '',
    country: 'USA',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    state: '',
    streetAddress: '',
    zipCode: '',
  }

  const [formError, setFormErrors] = useState({})
  const [formState, setFormState] = useState({
    apartment: defaultAddress?.attributes?.address2 || '',
    city: defaultAddress?.attributes?.city || '',
    country: defaultAddress?.attributes?.country_iso3 || 'USA',
    firstName: defaultAddress?.attributes?.firstname || '',
    id: defaultAddress?.id || null,
    lastName: defaultAddress?.attributes?.lastname || '',
    phoneNumber: defaultAddress?.attributes?.phone || '',
    state: defaultAddress?.attributes?.state_code || '',
    streetAddress: defaultAddress?.attributes?.address1 || '',
    zipCode: defaultAddress?.attributes?.zipcode || '',
  })

  const [selectedActiveAddress, setSelectedActiveAddress] = useState(isEmpty(defaultAddress) ? 'new' : 0)
  const [countryList, setCountryList] = useState([])

  const formRef = useRef(null)
  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (listenAddresList) {
      handlAddressChange({
        target: {
          value: 'new',
        },
      })
    }
  }, [addressesList])

  useEffect(() => {
    onChange({
      [addressType]: formState,
      [`${addressType}FormIsValid`]: validateForm(),
    })
  }, [formState, showErrors])

  useEffect(() => {
    if (onReset) {
      setFormState(initState)
      formRef.current.reset()
    }
  }, [onReset])

  const getCountries = async () => {
    const { data } = await client.fetcher.fetch({
      method: 'GET',
      url: 'api/v2/storefront/countries?sort=name',
    })

    setCountryList(data.data)
  }

  const handleInputChange = (event) => {
    const { maxLength, name, type, value } = event.target

    const newValue = (type ==='text' && maxLength)
      ? value.slice(0, maxLength)
      : value

    setFormState(prevState => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const handlAddressChange = (event) => {
    const { value } = event.target

    const newFormValues = (value === 'new')
      ? initState
      : {
        apartment: addressesList[value].attributes.address2,
        city: addressesList[value].attributes.city,
        country: addressesList[value].attributes.country_iso3,
        firstName: addressesList[value].attributes.firstname,
        id: addressesList[value].id,
        lastName: addressesList[value].attributes.lastname,
        phoneNumber: addressesList[value].attributes.phone,
        state: addressesList[value].attributes.state_code,
        streetAddress: addressesList[value].attributes.address1,
        zipCode: addressesList[value].attributes.zipcode,
      }

    formRef.current.reset()

    setSelectedActiveAddress(value)
    setFormState(newFormValues)
  }

  const isDisabledField = (field) => !!~disabledFields.indexOf(field)

  const validateForm = () => {
    const errors = {}
    const variableKeys = [
      'firstName',
      'lastName',
      'country',
      'streetAddress',
      'city',
      'zipCode',
      'state',
      'phoneNumber',
    ]

    variableKeys.forEach((key) => {
      if (isEmpty(formState[key]))
        errors[key] = 'blank'

    })

    if (isEmpty(formState.phoneNumber))
      errors.phoneNumber = 'blank'

    if (showErrors)
      setFormErrors(errors)

    return isEmpty(errors)
  }

  const compactAddresList = compact(addressesList)
  const hasSavedAddressList = !isEmpty(compactAddresList)

  return (
    <Row className="justify-content-md-center">
      {hasSavedAddressList &&
        <Col md="12" px={[0, 15]}>
          <Form.Group controlId="addressesList">
            <Form.Label md="4">
              <FormattedMessage id="addressesList" />*
            </Form.Label>

            <Select
              as="select"
              id="addressesList"
              name="addressesList"
              onChange={handlAddressChange}
              value={selectedActiveAddress}
            >
              <FormattedMessage id="newAddress">
                {translatedMessage => (
                  <option value="new">
                    {translatedMessage}
                  </option>
                )}
              </FormattedMessage>

              <FormattedMessage id="orderSavedAddress">
                {translatedMessage => (
                  compactAddresList.map(({ id, type, attributes: { firstname, lastname, city, address1 }}, index) => (
                    <option key={id} value={index}>
                      {type === 'orderAddress'
                        ? translatedMessage
                        : `${firstname} ${lastname} - ${city}, ${address1}`
                      }
                    </option>
                  ))
                )}
              </FormattedMessage>
            </Select>
          </Form.Group>
        </Col>
      }

      <Col md="12">
        <Text fontWeight="bold">
          <FormattedMessage id={addressType} />
        </Text>

        <Separator borderColor="gray.light" />
      </Col>

      <Form className="mb-2" ref={formRef}>
        <Row className="mt-3">
          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="firstName">
              {translatedMessage => (
                <Form.Group controlId="firstName" mr={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('firstName')}
                    errorTranslationId={errors?.firstname || formError.firstName}
                    maxLength="30"
                    name="firstName"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.firstName}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="lastName">
              {translatedMessage => (
                <Form.Group controlId="lastName" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('lastName')}
                    errorTranslationId={errors?.lastname || formError.lastName}
                    maxLength="30"
                    name="lastName"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.lastName}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>
          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="country">
              {translatedMessage => (
                <Form.Group controlId="country">
                  <Form.Label md="4">
                    {translatedMessage}
                  </Form.Label>

                  <Select
                    as="select"
                    disabled={isDisabledField('country')}
                    id="country"
                    name="country"
                    onChange={handleInputChange}
                    value={formState.country}
                  >
                    {countryList.map(({ id, attributes }) => (
                      <option key={id} value={attributes.iso3}>{attributes.name}</option>
                    ))}
                  </Select>
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <States
              errorTranslationId={errors?.state || formError.state}
              onChange={handleInputChange}
              value={formState.state}
            />
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="streetAddress">
              {translatedMessage => (
                <Form.Group controlId="streetAddress" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('streetAddress')}
                    errorTranslationId={errors?.streetaddress || formError.streetAddress}
                    maxLength="30"
                    name="streetAddress"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.streetAddress}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="apartment">
              {translatedMessage => (
                <Form.Group controlId="apartment" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('apartment')}
                    errorTranslationId={errors?.apartment || formError.apartment}
                    maxLength="30"
                    name="apartment"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.apartment}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="city">
              {translatedMessage => (
                <Form.Group controlId="city" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('city')}
                    errorTranslationId={errors?.city || formError.city}
                    maxLength="30"
                    name="city"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.city}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="zipCode">
              {translatedMessage => (
                <Form.Group controlId="zipCode" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('zipCode')}
                    errorTranslationId={errors?.zipcode || formError.zipCode}
                    maxLength="30"
                    name="zipCode"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.zipCode}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>

          <Col md="6" px={[0, 15]}>
            <FormattedMessage id="phoneNumber">
              {translatedMessage => (
                <Form.Group controlId="phoneNumber" ml={[0, 2]}>
                  <Form.Label md="4">
                    {translatedMessage}*
                  </Form.Label>

                  <TextField
                    disabled={isDisabledField('phoneNumber')}
                    errorTranslationId={errors?.phonenumber || formError.phoneNumber}
                    maxLength="30"
                    name="phoneNumber"
                    onChange={handleInputChange}
                    type="text"
                    value={formState.phoneNumber}
                  />
                </Form.Group>
              )}
            </FormattedMessage>
          </Col>
        </Row>
      </Form>
    </Row>
  )
}

AccountInformation.propTypes = {
  addressesList: PropTypes.array,
  addressType: PropTypes.string,
  defaultAddress: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  disabledFields: PropTypes.array,
  errors: PropTypes.object,
  listenAddresList: PropTypes.bool,
  onChange: PropTypes.func,
  onReset: PropTypes.bool,
  showErrors: PropTypes.bool,
}

export default AccountInformation
