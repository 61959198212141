import headerLogo from './header-logo.webp'

import headerBg from './heading-bg.webp'
import headerBgV2 from './heading-bg-v2.webp'
import headerBgV3 from './heading-bg-v3.webp'
import headerBgV4 from './heading-bg-v4.webp'
import headerBgV5 from './heading-bg-v5.webp'
import headerBgV6 from './heading-bg-v6.webp'
import headerBgV7 from './heading-bg-v7.webp'

import curvedDarkArrow from './curved-dark-arrow.webp'
import curvedArrow from './curved-arrow.webp'
import headerInfoBoxBg from './header-info-box.webp'
import headerInfoBoxDarkBg from './header-info-box-dark.webp'

import bannerTextPimp from './home/banner-text-pimp.webp'
import bannerExtraTextBg from './home/banner-text-bg.webp'
import homeEnjoyIcon from './home/enjoy-icon.webp'
import homeRepeatIcon from './home/repeat-icon.webp'
import homeReturnIcon from './home/return-icon.webp'
import homeSelectIcon from './home/select-icon.webp'

import loopByCb from './footer/loop-by-cb.webp'
import loopWhite from './footer/loop-white.webp'
import loopSecondary from './footer/loop-secondary.webp'
import cbLogo from './footer/cb.webp'

import facebookIcon from './footer/social-icons/facebook.webp'
import instagramIcon from './footer/social-icons/instagram.webp'
import youtubeIcon from './footer/social-icons/youtube.webp'

import didYouKnowImg from './static/did-you-know.webp'
import staticImage1 from './static/1.webp'
import staticImage2 from './static/2.webp'
import staticImage3 from './static/cost-1.webp'
import staticImage4 from './static/cost-2.webp'
import staticImage5 from './static/cost-3.webp'
import compareImage from './static/compare.webp'
import productCompareImage from './static/product-compare.webp'
import productCompareText from './static/compare-text.webp'

import ogImage from './og-image.png'

const images = {
  bannerExtraTextBg,
  bannerTextPimp,
  cbLogo,
  compareImage,
  curvedArrow,
  curvedDarkArrow,
  didYouKnowImg,
  headerBg,
  headerBgV2,
  headerBgV3,
  headerBgV4,
  headerBgV5,
  headerBgV6,
  headerBgV7,
  headerInfoBoxBg,
  headerInfoBoxDarkBg,
  headerLogo,
  homeEnjoyIcon,
  homeRepeatIcon,
  homeReturnIcon,
  homeSelectIcon,
  icons: {
    facebookIcon,
    instagramIcon,
    youtubeIcon,
  },
  loopByCb,
  loopSecondary,
  loopWhite,
  ogImage,
  productCompareImage,
  productCompareText,
  staticImage1,
  staticImage2,
  staticImage3,
  staticImage4,
  staticImage5,
}

export default images
