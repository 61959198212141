import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { Container, Row } from 'react-bootstrap'
import { find, isEmpty } from '../../lodash'
import { FormattedMessage } from 'react-intl'
import { Box, Col, ContentBox, Currency, Heading, NoImage, Text } from '../../elements'
import { AddToCartButton, HeadLine, ResponsiveCarousel } from '../../components'
import { TranslatedMetaLoader, TranslatedTitleLoader, withProfile } from '../../modules'
import { userPropTypes } from '../../types'
import client from '../../api/api'
import './product-item.scss'

export const ProductPage = () => {
  const { data: { data } } = useLoaderData()

  let params = useParams()
  let navigate = useNavigate()

  const [ activeCarouselIndex, setActiveCarouselIndex ] = useState(0)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ product, setProduct ] = useState(null)
  const [ images, setProductImages ] = useState(null)

  useEffect(() => {
    getProduct()
  }, [])

  const getProduct = async () => {
    const response = await client.products.show(params.slug,
      {},
      { include: 'images' },
    )

    if (response.isSuccess()) {
      const { data, included } = response.success()

      const imagesWithSelectedUrl = included
        ?
        included.map(img => ({
          id: img.id,
          url: find(img.attributes.styles, { size: '600x600>' }).url,
        }))
        : []

      setProduct(data)
      setProductImages(imagesWithSelectedUrl)
      setIsLoading(false)
    } else {
      navigate('/products')
    }
  }

  if (isEmpty(params.slug))
    return <Navigate to="/products" />

  if (isLoading)
    return null

  return (
    <>
      <HeadLine />

      <Container className="my-5">
        <TranslatedTitleLoader titleId="pageTitleProduct" value={product.attributes.name} />

        <TranslatedMetaLoader
          data-test="product-meta-description"
          isTranslated
          metaArray={[
            { content: product.attributes.meta_description || '', name: 'description' },
            { content: product.attributes.meta_keywords || '', property: 'og:description' },
          ]}
        />

        <Row style={{ marginTop: '-150px' }}>
          <Col md="6">
            {isEmpty(images)
              ? <NoImage />
              : (
                <ResponsiveCarousel
                  images={images}
                  onClickThumb={(index) => setActiveCarouselIndex(index)}
                  selectedItem={activeCarouselIndex}
                />
              )
            }

          </Col>

          <Col md="6">
            <Heading as="h2" color="black" mt={[2, 6]}>
              {product.attributes.name}
            </Heading>

            <Text color="primary.dark" fontSize={6} fontWeight="bold" mb={1} mt={[2, 7]}>
              <Currency data-test="product-base-price" value={product.attributes.base_price} />
            </Text>

            <Text fontSize={2}>
              {' + '}
              <Currency data-test="product-deposit-price" value={product.attributes.deposit_price} />
              {' '}
              <FormattedMessage id="refundableDeposit" />
            </Text>

            <Text mt={2}>
              {product.attributes.description}
            </Text>

            <Box mt={2}>
              <Heading as="h3" color="black" fontSize={[3, 2]} fontWeight="bold" mb={2} mt={5}>
                <FormattedMessage id="includes" />
              </Heading>

              <Box id="plus-list">
                <ul>
                  {product.attributes.items.filter(item => item.quantity > 0).map(lineItem => (
                    <li key={lineItem.name}>
                      {lineItem.quantity < 2
                        ? lineItem.name
                        : `${lineItem.quantity} ${lineItem.name}`
                      }
                    </li>
                  ))}
                  <li>
                    <FormattedMessage id="freePickUp" />
                  </li>
                </ul>
              </Box>
            </Box>

            <AddToCartButton productId={product.relationships.default_variant.data.id} />
          </Col>

          <Col md="12" mt={5}>
            <ContentBox content={data?.attributes?.Content} type="shadowCard" />
          </Col>
        </Row>
      </Container>
    </>
  )
}

ProductPage.propTypes = {
  product: PropTypes.object,
  user: userPropTypes,
}

export default withProfile(ProductPage)
