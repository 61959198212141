import ReactGA from 'react-ga4'

/**
 * @typedef InitOptions
 * @type {object}
 * @property {boolean} anonymizeIp send IP addresses anonymized to GA
 * @property {boolean} testMode initialize GA in test mode (for auto tests)
 */

/**
 * Initialize the Google Analytics with plugins using the environment-spefic GA code
 * @param {InitOptions} options initialization options
 */

const ga4Code = 'G-9X9B4NRFB9'

const initializeGoogleAnalytics = ({ anonymizeIp, testMode }) => {
  ReactGA.initialize(ga4Code, {
    gaOptions: {
      cookieExpires: 31536000,
    },
    testMode,
  })

  anonymizeIp && ReactGA.set({ anonymizeIp })
}

export default initializeGoogleAnalytics
