import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { Loading } from '../../components'
import { Box, FAQPanel, HeadingWithBg } from '../../elements'
import strapi from '../../api/strapi'

const Faq = () => {
  const [isPageLoading, setPageLoading] = useState(true)
  const [strapiPage, setStrapiPage] = useState(null)

  useEffect(() => {
    const fetchFaqPage = async () => {
      const { data } = await strapi.get('/support-and-faq?populate[faqs][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiPage(data.data.attributes)
      }

      setPageLoading(false)
    }

    fetchFaqPage()
  }, [])

  if (isPageLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return(
    <React.Fragment>
      {strapiPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiPage.MetaData?.metaText , name: 'description' },
            { content: strapiPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiPage.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container">
        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBgV4"
          my={30}
          p={30}
          position="relative"
          textAlign="center"
        >
          {strapiPage?.title}
        </HeadingWithBg>

        <Row>
          <Col md={12}>
            <CustomContentStyle className="line2 faqPage" pl={0}>
              {React.Children.toArray(
                strapiPage?.faqs.map((data) =>
                  <>
                    <h2>{data.category}</h2>
                    {React.Children.toArray(
                      data?.items.map((item) =>
                        <CustomContentStyle className="line2" pl={0}>
                          <FAQPanel question={item} />
                        </CustomContentStyle>
                      ))}
                  </>
                )
              )}
            </CustomContentStyle>
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )}

const CustomContentStyle = styled(Box)`
  h1, h2, h3, h4, h5, h6 {
    color: #444444;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
`

export default Faq
