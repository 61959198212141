import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import forEach from 'lodash/forEach'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Box } from '../../'
import { Heading, Image, Link } from '../../../elements'

const EqualLayout = ({
  content,
  custom={
    imgBox: 1/2,
    textBox: 1/2,
  },
  className,
  hideModal,
  hideTitle=false,
  highlightedLinkText,
  highlightedLinkTo,
  id,
  image,
  index,
  isImgRight,
  title,
  wrapperFlexProps,
}) => {

  useEffect(() => {
    let links = document.querySelectorAll('.shadowed-card-layout a')
    forEach(links, (link) => {
      if (link.hostname !== window.location.hostname) {
        link.target = '_blank'
      }
    })
  }, [])

  return (
    <>
      <Box
        alignItems="center"
        className={`box box_${id}`}
        display="flex"
        flexDirection={wrapperFlexProps.flexDirection}
        justifyContent="space-between"
      >
        <Box
          className="text textWrapper"
          pb={[3, 0]}
          pl={isImgRight ? [3, 5] : [3, 5]}
          pr={isImgRight ? [3, 5] : [3, 5]}
          width={[1, custom.textBox]}
        >
          {title && !hideTitle && (
            <Box className="boxTitle">
              <Heading as="h3" color="gray.dark">
                {index && <span className={`title${index} titleIndex`}>{index}</span>}
                {title}
              </Heading>
            </Box>
          )}

          <BlocksRenderer content={content} />
        </Box>

        <Box className={`imageBox imageBox_${id}`} width={[1, custom.textBox]}>
          <Image
            alt={image.data.attributes.alternativeText}
            className={className}
            src={image.data.attributes.url}
          />
        </Box>
      </Box>
      {highlightedLinkTo && (
        <Link
          color="primary.dark"
          display="block"
          fontSize={2}
          fontWeight="bold"
          mt={3}
          onClick={hideModal}
          style={{ textDecoration: 'underline' }}
          to={highlightedLinkTo}
          width={1}
        >
          {highlightedLinkText}
        </Link>
      )}
    </>
  )}

EqualLayout.propTypes = {
  className: PropTypes.string,
  content: PropTypes.array.isRequired,
  custom: PropTypes.object,
  hideModal: PropTypes.func,
  hideTitle: PropTypes.bool,
  highlightedLinkText: PropTypes.string,
  highlightedLinkTo: PropTypes.string,
  id: PropTypes.number,
  image: PropTypes.object,
  index: PropTypes.number,
  isImgRight: PropTypes.bool.isRequired,
  isTextOnly: PropTypes.bool,
  title: PropTypes.string,
  wrapperFlexProps: PropTypes.object,
}

export default EqualLayout
