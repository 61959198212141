import styled from 'styled-components'
import {
  border,
  color,
  flexbox,
  layout,
  typography,
  position,
  space,
} from 'styled-system'

const Box = styled.div`
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${typography}
  ${position}
  ${space}
`

Box.displayName = 'Box'

export default Box
