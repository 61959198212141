import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { Row } from 'react-bootstrap'
import { PaymentLogoList } from '../../'
import { Box } from '../../../elements'
import { withStoreConfig } from '../../../modules'
import './credit-card.scss'

export const CreditCard = ({ cardType, cardNumber, name, expiration, store }) => (
  <Box
    bg={['transparent', 'background.light']}
    borderRadius={[0, 4]}
    className="credit-card d-flex flex-column justify-content-between"
    color={['dark', 'gray.main']}
    height={['auto', 350]}
    mb={[0, 40]}
    px={[0, 5]}
    py={[0, 5]}
    width={['100%', 530]}
  >
    <Box
      className="credit-card-number"
      color="gray.dark"
    >
      <Box
        bg="background.light"
        border="1px solid"
        borderColor="gray.main"
        borderRadius={1}
        className="credit-card-field"
        color="dark"
        fontSize={4}
        fontWeight={['normal', 'bold']}
        height={['auto', 52]}
        lineHeight="22px"
        p={14}
      >
        ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;{cardNumber}
      </Box>
    </Box>

    <Row className="credit-card-logo">
      <PaymentLogoList hasSelected selected={cardType.toLowerCase()} supportedPaymentMethods={store.config?.accepted_credit_cards} />
    </Row>

    <div className="credit-card-info-name">
      <Box
        alignItems={['initial', 'flex-end']}
        className="credit-card-info-label"
        color={['primary.dark', 'gray.dark']}
        fontSize={3}
        lineHeight="20px"
        mb={2}
        mt={[15, 0]}
      >
        <FormattedMessage id="cardHolder" />
      </Box>
      <Box
        bg="background.light"
        border="1px solid"
        borderColor="gray.main"
        borderRadius={1}
        className="credit-card-field"
        color="dark"
        fontSize={4}
        fontWeight={['normal', 'bold']}
        height={['auto', 52]}
        lineHeight="21px"
        p={14}
      >
        {name}
      </Box>
    </div>

    <Row className="credit-card-info justify-content-between">
      <div className="d-flex flex-column justify-content-between credit-card-info-expiry">
        <Box
          alignItems={['initial', 'flex-end']}
          className="credit-card-info-label"
          color={['primary.dark', 'gray.dark']}
          fontSize={3}
          lineHeight="20px"
          mb={2}
          mt={[15, 0]}
        >
          <FormattedMessage id="expirationDate" />
        </Box>
        <Row>
          <Box
            bg="background.light"
            border="1px solid"
            borderColor="gray.main"
            borderRadius={1}
            className="credit-card-field"
            color="dark"
            fontSize={4}
            fontWeight={['normal', 'bold']}
            height={['auto', 52]}
            lineHeight="24px"
            p={[10, 14, 14]}
          >
            {expiration.month}
          </Box>
          <div className="d-flex align-items-center">
            &nbsp;/&nbsp;
          </div>
          <Box
            bg="background.light"
            border="1px solid"
            borderColor="gray.main"
            borderRadius={1}
            className="credit-card-field"
            color="dark"
            fontSize={4}
            fontWeight={['normal', 'bold']}
            height={['auto', 52]}
            lineHeight="24px"
            p={[10, 14, 14]}
          >
            {expiration.year}
          </Box>
        </Row>
      </div>

      <div className="d-flex flex-column justify-content-between credit-card-info-security-code">
        <Box
          alignItems={['initial', 'flex-end']}
          className="credit-card-info-label"
          color={['primary.dark', 'gray.dark']}
          fontSize={3}
          lineHeight="20px"
          mb={2}
          mt={[15, 0]}
        >
          <FormattedMessage id="securityCode" />
        </Box>
        <Box
          bg="background.light"
          border="1px solid"
          borderColor="gray.main"
          borderRadius={1}
          className="credit-card-field"
          color="dark"
          fontSize={4}
          fontWeight={['normal', 'bold']}
          height={['auto', 52]}
          p={[10, 14, 14]}
        >***
        </Box>
      </div>
    </Row>
  </Box>
)

CreditCard.propTypes = {
  cardNumber: PropTypes.string,
  cardType: PropTypes.string,
  expiration: PropTypes.object,
  name: PropTypes.string,
  store: PropTypes.object,
}

CreditCard.displayName = 'CreditCard'

export default withStoreConfig(CreditCard)
