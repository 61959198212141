import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from '../../'
import client from '../../../api/api'

class StoreProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      config: null,
      isLoading: true,
    }
  }

  componentDidMount() {
    this.getStoreConfig()
  }

  getStoreConfig = async () => {
    const { data } = await client.fetcher.fetch({
      method: 'GET',
      url: 'api/v2/storefront/configs',
    })

    this.setState({
      config: data,
      isLoading: false,
    })
  }

  render() {
    const { children } = this.props

    return (
      <StoreContext.Provider value={this.state}>
        {children}
      </StoreContext.Provider>
    )
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StoreProvider
