export const scrollToElement = (elementName = 'scrollToElement', dir, inModalScroll=false) => {
  let scrollAnchorElement = document.getElementById(elementName) || document.getElementsByClassName(elementName)[0]

  if (inModalScroll) {
    scrollAnchorElement.parentElement.parentElement.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    })

    return null
  }

  let newPosition = 0

  if (scrollAnchorElement) {
    const elementDOMRect = scrollAnchorElement.getBoundingClientRect()

    newPosition = elementDOMRect.top + window.scrollY || window.pageYOffset
    if (dir === 'bottom') {
      newPosition += scrollAnchorElement.offsetHeight
    } else if (dir === 'aboveElement') {
      newPosition -= 130
    }
  }

  window.scroll({
    behavior: 'smooth',
    right: 0,
    top: newPosition,
  })
}

export default scrollToElement
