import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { kebabCase, omit } from '../../lodash'
import paymentIcons from '../../../assets/images/payment-icons'

const PaymentLogoList = ({ hasSelected, selected, supportedPaymentMethods }) => (
  <>
    {(supportedPaymentMethods || []).map(type => (
      <Logo
        alt={type}
        className="payment-card-logo"
        hasSelected={hasSelected}
        isSelected={type === kebabCase(selected)}
        key={type}
        src={paymentIcons[type]}
      />
    ))}
  </>
)

PaymentLogoList.propTypes = {
  hasSelected: PropTypes.bool,
  selected: PropTypes.string,
  supportedPaymentMethods: PropTypes.array,
}

export const Logo = styled(
  ({ ...restProps }) => <img alt="" {...omit(restProps, ['hasSelected', 'isSelected'])} />,
)`
  filter: grayscale(100%);
  opacity: .3;
  transition: opacity 0.3s;

  ${props => !props.hasSelected && css`
    filter: none;
    opacity: 1;
  `}

  ${props => props.isSelected && css`
    filter: none;
    opacity: 1;
  `}
`

Logo.displayName = 'Logo'

export default PaymentLogoList
