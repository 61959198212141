import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { color, space, layout } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Col, Row } from 'react-bootstrap'
import { Heading, Image } from '../../elements'

const HowItWorks = ({ steps = [] }) => (
  <RelativeRow>
    <ShadowBorderBox bg="white" px={['30px']} py={['40px']}>
      <Heading as="h2" pb={6} textAlign="center">
        <FormattedMessage id="howItWorks" />
      </Heading>

      <Row>
        {steps.map(({ content, id, image, title }) => (
          <CenteredCol key={id}>
            <Col>
              <Image height={110} src={image?.data?.attributes?.url} width="auto" />
            </Col>

            <UpperCaseHeading as="h6" color="black" m={0} py={5} textAlign="center">
              {title}
            </UpperCaseHeading>

            <BlocksRenderer content={content} />
          </CenteredCol>
        ))}
      </Row>
    </ShadowBorderBox>
  </RelativeRow>
)

const RelativeRow = styled(Row)`
  position: relative;

  margin-top: -70px;

  z-index: 2;


  @media (max-width: 400px) {
    margin-top: 10px;
  }
`

const UpperCaseHeading = styled(Heading)`
  font-weight: 600;
  font-family: 'Nunito', sans-serif !important;

  text-transform: uppercase;
`

const ShadowBorderBox = styled(Col)`
  ${color}
  ${space}
  ${layout}

  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.15);
  border-radius: 48px;
`

const CenteredCol = styled(Col)`
  text-align: center;
`

HowItWorks.propTypes = {
  steps: PropTypes.array,
}

export default HowItWorks
