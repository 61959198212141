import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import App from './App'
import {
  CustomPage,
  ContactUsPage,
  CostCalculatorPage,
  CheckoutPage,
  ConfirmationPage,
  DsarPage,
  Error404Page,
  FaqPage,
  HomePage,
  HowItWorksPage,
  LoginPage,
  LogoutPage,
  OurMissionPage,
  ProductsPage,
  PrivacyPolicyPage,
  RecoveryPage,
  TermsAndConditionsPage,
  ResetPasswordPage,
  ShoppingCartPage,
  SignUpPage,
  UserPage,
} from 'pages'
import {
  LocaleSwitchProvider,
  ResizeProvider,
  StoreProvider,
  ThemeProvider,
} from './modules'
import strapi from './api/strapi'

const Root = () => {
  useEffect(() => {
    window.rakutenDataLayer = false
    let dln = window.rakutenDataLayer || 'DataLayer'
    if(!window[dln]) {
      window[dln] = {}
    }
    if(!window[dln].events) {
      window[dln].events = {}
    }
    window[dln].events.SPIVersion = window[dln].events.SPIVersion || 'v3.4.1'
  }, [])

  const router = createBrowserRouter([
    {
      children: [
        {
          element: <HomePage />,
          index: true,
          loader: async () => await strapi.get('/home-page?populate[HowItWorks][populate]=*&populate[HowItWorks][image][populate]=*&populate[carouselImage][populate]=*&populate[Content][image][populate]=*&populate[Content][populate]=*&populate[getStartedBtn][populate]=*&populate[details][populate]=*'),
          shouldRevalidate: () => false,
        },
        {
          element: <LoginPage />,
          path: '/login',
        },
        {
          element: <RecoveryPage />,
          path: '/login/recovery',
        },
        {
          element: <ResetPasswordPage />,
          path: '/login/reset-password/:token',
        },
        {
          element: <LogoutPage />,
          path: '/logout',
        },
        {
          element: <SignUpPage />,
          path: '/signup',
        },
        {
          element: <ProductsPage />,
          path: '/products',
          shouldRevalidate: () => false,
        },
        {
          element: <ShoppingCartPage />,
          path: '/cart',
          shouldRevalidate: () => false,
        },
        {
          element: <CheckoutPage />,
          path: '/checkout',
          shouldRevalidate: () => false,
        },
        {
          element: <ConfirmationPage />,
          path: '/confirmation/:token/:number',
          shouldRevalidate: () => false,
        },
        {
          element: <UserPage />,
          path: '/user',
          shouldRevalidate: () => false,
        },
        {
          element: <CustomPage id="ccpa" />,
          path: '/consumer-privacy',
          shouldRevalidate: () => false,
        },
        {
          element: <PrivacyPolicyPage />,
          path: '/privacy-policy',
          shouldRevalidate: () => false,
        },
        {
          element: <TermsAndConditionsPage />,
          path: '/terms-and-conditions',
          shouldRevalidate: () => false,
        },
        {
          element: <HowItWorksPage />,
          path: '/how-it-works',
          shouldRevalidate: () => false,
        },
        {
          element: <OurMissionPage />,
          path: '/our-mission',
          shouldRevalidate: () => false,
        },
        {
          element: <FaqPage />,
          path: '/support-and-faq',
          shouldRevalidate: () => false,
        },
        {
          element: <CostCalculatorPage />,
          path: '/cost-comparison',
          shouldRevalidate: () => false,
        },
        {
          element: <ContactUsPage />,
          path: '/contact-us',
          shouldRevalidate: () => false,
        },
        {
          children: [
            {
              element: <UserPage />,
              path: ':userMenu',
            },
          ],
          element: <UserPage />,
          path: 'user',
        },
        {
          element: <DsarPage />,
          path: '/dsar',
          shouldRevalidate: () => false,
        },
        {
          element: <Error404Page />,
          path: '*',
        },
      ],
      element: <App />,
      path: '/',
    },
  ])

  return (
    <ThemeProvider>
      <StoreProvider>
        <ResizeProvider>
          <LocaleSwitchProvider>
            <RouterProvider router={router} />
          </LocaleSwitchProvider>
        </ResizeProvider>
      </StoreProvider>
    </ThemeProvider>
  )
}

export default Root
