import americanExpress from './american-express.png'
import discover from './discover.png'
import jcb from './jcb.png'
import maestro from './maestro.png'
import mastercard from './mastercard.png'
import visa from './visa.png'

export default {
  'american-express': americanExpress,
  discover,
  jcb,
  maestro,
  master: mastercard,
  mastercard,
  visa,
}
