import React, { useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Navigate } from 'react-router-dom'
import { isEmpty } from '../../lodash'
import { TextField } from '../../components'
import { Button, Heading, Link, Text } from '../../elements'
import { Auth, TranslatedMetaLoader, TranslatedTitleLoader, withProfile } from '../../modules'
import { addNotification, isValidEmail } from '../../utils'
import { userPropTypes } from '../../types'

import './login.scss'

export const LoginPage = ({ user }) => {
  const hasRedirectParam = !!~window.location.search.indexOf('checkout')
  const [ isLoading, setIsLoading ] = useState(false)
  const [ formError, setFormError ] = useState(null)
  const [ formState, setFormState ] = useState({
    email : '',
    password :  '',
  })

  if(user.me)
    return <Navigate to={hasRedirectParam ? '/checkout' : '/'} />

  const handleInputChange = (event) => {
    const { id , value } = event.target

    setFormState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const handleClick = (event) => {
    event.preventDefault()

    setFormError(null)

    const { email, password } = formState

    if (!isValidEmail(email)) {
      setFormError({
        email: 'emailIsInvalid',
      })

      return false
    }

    setIsLoading(true)

    Auth.signIn({
      email,
      password,
    })
      .then((response) => {
        if (response.isSuccess()) {
          const tokens = response.success()

          Auth.saveToken(tokens)

          addNotification({
            messageId: 'notificationLoginMsg',
            titleId: 'notificationSuccessTitle',
            type: 'success',
          })

          user.associateGuestCart()

          user.refetch()
        } else {
          addNotification({
            messageId: 'notificationLoginErrorMsg',
            titleId: 'notificationErrorTitle',
            type: 'danger',
          })

          setIsLoading(false)

          handleInputChange({ target: { id: 'password', value: '' }})
        }
      })
      .catch((response) => {
        setIsLoading(false)

        addNotification({
          backendError: response,
          titleId: 'notificationErrorTitle',
          type: 'danger',
        })
      })
  }

  return (
    <Container className="mt-5 mb-5">
      <TranslatedTitleLoader titleId="pageTitleLogin" />

      <TranslatedMetaLoader
        metaArray={[
          { content: 'pageTitleLogin', property: 'og:title' },
        ]}
      />

      <Row className="login-page">
        <Col md={{ offset:2, span:8 }} xl={{ offset:4, span:4 }}>
          <Heading className="login-page-title" fontSize="2.3rem" mb={3} textAlign="center">
            <FormattedMessage id="login" />
          </Heading>

          <Form id="login-form">
            <FormattedMessage id="email">
              {translatedMessage => (
                <TextField
                  errorTranslationId={formError?.email}
                  label={translatedMessage}
                  name="email"
                  onChange={handleInputChange}
                  type="email"
                  value={formState.email}
                />
              )}
            </FormattedMessage>

            <FormattedMessage id="password">
              {translatedMessage => (
                <TextField
                  label={translatedMessage}
                  name="password"
                  onChange={handleInputChange}
                  type="password"
                  value={formState.password}
                />
              )}
            </FormattedMessage>

            <Button
              className="primary-button"
              disabled={isLoading || isEmpty(formState.email) || isEmpty(formState.password)}
              id="loginButton"
              onClick={handleClick}
              type="submit"
              variant="primary"
              width="100%"
            >
              <FormattedMessage id="loginButton" />
            </Button>

            <Text className="login-link underline d-flex justify-content-center" fontSize="18px" my={3} textAlign="center">
              <Link className="link d-flex" id="recoveryLink" to="/login/recovery">
                <FormattedMessage id="forgotPasswordButton" />
              </Link>
            </Text>

            <Text className="login-link underline d-flex justify-content-center" fontSize="18px" my={3} textAlign="center">
              <Link className="link" id="signupLink" to={`/signup${window.location.search}`}>
                <FormattedMessage id="newAccount" />
              </Link>
            </Text>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

LoginPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(LoginPage)
