import { Col } from 'react-bootstrap'
import styled from 'styled-components'
import {
  color,
  border,
  layout,
  typography,
  position,
  space,
} from 'styled-system'

const StyledCol = styled(Col)`
  ${color}
  ${border}
  ${layout}
  ${typography}
  ${position}
  ${space}
`

StyledCol.displayName = 'Col'

export default StyledCol
