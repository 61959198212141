import React from 'react'
import { Store } from 'react-notifications-component'
import { FormattedMessage } from 'react-intl'

export const addNotification = ({ backendError, messageId, titleId, type }) => {
  Store.addNotification({
    animationIn: ['animate__animated', 'animate__bounceInLeft'],
    animationOut: ['animate__animated', 'animate__bounceOutLeft'],
    container: 'top-left',
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
    insert: 'top',
    message: backendError || (messageId ? <FormattedMessage id={messageId} /> : null),
    title: <FormattedMessage id={titleId} />,
    type: type,
  })
}

export default addNotification
