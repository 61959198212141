import styled from 'styled-components'
import { border, layout, space } from 'styled-system'
import { Image as OriginalImage } from 'react-bootstrap'

const Image = styled(OriginalImage)`
  ${border}
  ${layout}
  ${space}

  width: ${props => props.width ? `${props.width}px` : 'auto'};
`

Image.displayName = 'Image'

export default Image
