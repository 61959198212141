import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Accordion, Card } from 'react-bootstrap'
import { Text } from '../../elements'
import Box from '../../elements/box/Box'

const FAQPanel = ({ question }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Accordion activeKey={isOpen}>
      <CustomCard>
        <Card.Header onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
          <Box alignItems="center" display="flex" justifyContent="space-between" pl={0}>
            <Text fontSize={2} mb={0}>
              {question.question}
            </Text>

            <Arrow border="2px solid" borderColor="primary.dark" status={isOpen} />
          </Box>
        </Card.Header>
        <Accordion.Collapse eventKey={true}>
          <Card.Body>
            <div dangerouslySetInnerHTML={{ __html: question.answer }} />
          </Card.Body>
        </Accordion.Collapse>
      </CustomCard>
    </Accordion>
  )
}

FAQPanel.propTypes = {
  question: PropTypes.object,
}

const Arrow = styled(Box)`
  height: 7px;
  width: 7px;
  border-right: 0;
  border-bottom: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${props => props.status ? 'rotate(45deg)' : 'rotate(-135deg)'};
}
`

const CustomCard = styled(Card)`
  margin-bottom: 15px;
`

export default FAQPanel

