import styled from 'styled-components'
import { Button as DefaultButton } from 'react-bootstrap'
import {
  color,
  display,
  layout,
  space,
  typography,
} from 'styled-system'

const Button = styled(DefaultButton)`
  ${color}
  ${display}
  ${layout}
  ${space}
  ${typography}

  font-size: ${props => props.fontSize || 14}px;
  font-weight: ${props => props.fontWeight || 400};
  padding-left: ${props => props.px || 30}px;
  padding-right: ${props => props.px || 30}px;
  padding-top: ${props => props.py || 10}px;
  padding-bottom: ${props => props.py || 10}px;
  width: ${props => props.width || '100%'};

  @media (min-width: 768px) {
    width: auto;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`

Button.displayName = 'Button'

export default Button
