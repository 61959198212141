import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { LineTo, Loading, GetStarted } from '../../components'
import { Arrow, Box, ContentBox, Heading, HeadingWithBg, Hide, Text } from '../../elements'
import strapi from '../../api/strapi'

const HowItWorks = () => {
  const [isHowItWorksPageLoading, setHowItWorksPageLoading] = useState(true)
  const [strapiHowItWorksPage, setStrapiHowItWorksPage] = useState(null)

  useEffect(() => {
    const fetchHowItWorksPage = async () => {
      const { data } = await strapi.get('/how-it-work?populate[howItWorksList][populate]=*&populate[howItWorksList][image][populate]=*populate[populate]=*&populate[content][image][populate]*&populate[content][populate]=*&populate[button][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiHowItWorksPage(data.data.attributes)
      }

      setHowItWorksPageLoading(false)
    }

    fetchHowItWorksPage()
  }, [])

  if (isHowItWorksPageLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiHowItWorksPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiHowItWorksPage.MetaData?.metaText , name: 'description' },
            { content: strapiHowItWorksPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiHowItWorksPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiHowItWorksPage.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container howItWorksPage">
        <HeadingWithBg
          as="h4"
          className="line1"
          color="white"
          imageName="headerBgV2"
          mb={[100, 50]}
          ml={[0, 200]}
          mt={[20, 50]}
          p="30px"
          pl="70px"
          textAlign="center"
        >
          {strapiHowItWorksPage?.title}
        </HeadingWithBg>

        <ModifiedImageBox className="how-it-works-main-block" mt={[0, -40, -40, -40, -90]}>
          <ContentBox content={strapiHowItWorksPage?.content} isLoading={isHowItWorksPageLoading} type="imageAndText" />
        </ModifiedImageBox>

        <Row>
          <Col md={12}>
            <Heading as="h4" color="black" textAlign="center">
              {strapiHowItWorksPage?.secondTitle}
              <Arrow mt={30} mx="auto" />
            </Heading>
          </Col>

          <Col md={{ offset: 1, span: 10 }}>
            <RoundedImageBox className="line2">
              <ContentBox content={strapiHowItWorksPage?.howItWorksList} custom={{ imgBox: '30%', textBox: '70%' }} isLoading={isHowItWorksPageLoading} type="imageAndText" />
            </RoundedImageBox>
          </Col>

          <Col>
            <Text mt={4} textAlign="center">
              {strapiHowItWorksPage?.button &&
                <GetStarted
                  link={strapiHowItWorksPage?.button?.link}
                  text={strapiHowItWorksPage?.button?.text}
                />
              }
            </Text>
          </Col>
        </Row>

        <Hide below={770}>
          <LineTo borderTopLeftRadius="300px" borderTopRightRadius="500px" custom delay={1200} from="line1" fromAnchor="top center" to="imageBox_27" toAnchor="top right" />
          <LineTo borderRight borderRightStyle="0" borderTopLeftRadius="100%" delay={1400} from="imageBox_27" fromAnchor="bottom left" to="imageBox_28" toAnchor="top right" />
          <LineTo borderLeftStyle="0" borderTopRightRadius="100%" delay={1600} from="imageBox_28" fromAnchor="bottom right" to="imageBox_29" toAnchor="top left" />
          <LineTo borderRight borderRightStyle="0" borderTopLeftRadius="100%" delay={2000} from="imageBox_29" fromAnchor="bottom left" to="imageBox_30" toAnchor="top right" />
        </Hide>
      </Container>
    </React.Fragment>
  )
}

const ModifiedImageBox = styled(Box)`
  img {
    border-radius: 20px;

    padding: 80px;
    margin-top: -120px;
    width: 100%;
  }


  @media (max-width: 750px) {
    img {
      padding: 15px;
      margin-top: -90px;
    }
  }
`

const RoundedImageBox = styled(Box)`
  img {
    border-radius: 20px;
  }
`

export default HowItWorks
