import styled from 'styled-components'
import { space } from 'styled-system'
import { Form } from 'react-bootstrap'
import assets from '../../../assets/images'

const StyledSelect = styled(Form.Control)`
  ${space}

  height: ${props => props.height || '52px'};
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  border-radius: 5px;
  width: 100%;

  background: url(${assets.arrowDown}) no-repeat right;
  background-position-x: 98%;

  &:disabled {
    -webkit-appearance: none;
    background-color: ${props => props.theme.colors.gray.light};
    color: ${props => props.theme.colors.gray.dark} !important;

    cursor: not-allowed;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`

export default StyledSelect
