import styled from 'styled-components'
import {
  background,
  color,
  border,
  space,
} from 'styled-system'

const Divider = styled.hr`
  ${background}
  ${color}
  ${border}
  ${space}

  border-color: ${props => props.borderColor || 'primary.dark'};
`

Divider.displayName = 'Divider'

export default Divider
