import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../elements'

const HeadLine = ({ children }) => (
  <Box bg="box.primary" height="180px" width="100%">
    {children}
  </Box>
)

HeadLine.displayName = 'HeadLine'

HeadLine.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
}

export default HeadLine
