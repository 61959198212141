import styled from 'styled-components'
import { color, layout, lineHeight, textAlign, typography, space } from 'styled-system'

const Text = styled.p`
  ${color}
  ${layout}
  ${typography}
  ${space}
  ${lineHeight}
  ${textAlign}

  color: ${props => props.color || 'black'};
`

Text.displayName = 'Text'

export default Text
