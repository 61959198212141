const userMenu = [
  {
    linkTo: '/user/whats-in-my-loop',
    name: 'whats-in-my-loop',
    translationId: 'whatsInMyLoop',
  },
  {
    linkTo: '/user/schedule-pickup',
    name: 'schedule-pickup',
    translationId: 'schedulePickup',
  },
  {
    linkTo: '/user/order-history',
    name: 'order-history',
    translationId: 'orderHistory',
  },
  {
    linkTo: '/user/manage-deposits',
    name: 'manage-deposits',
    translationId: 'manageDeposits',
  },
  {
    linkTo: '/user/settings',
    name: 'settings',
    translationId: 'accountSettings',
  },
  {
    linkTo: '/logout',
    name: 'logout',
    translationId: 'signOut',
  },
]

export default userMenu
