/**
 * Checks whether the provided string has protocol or not
 *
 * @param {String}    url  The string to check.
 *
 * @returns {Boolean} true when url has protocol or false when it has not
 */
const hasProtocol = (url) => (
  typeof url === 'string'
    ? url.split('//').some(element => element === 'https:' || element === 'http:')
    : false
)

export default hasProtocol
