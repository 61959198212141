import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Col, Container, Row } from 'react-bootstrap'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { Loading } from '../../components'
import { Box, HeadingWithBg, Text } from '../../elements'
import strapi from '../../api/strapi'

const PrivacyPolicy = () => {
  const [isPrivacyLoading, setPrivacyLoading] = useState(true)
  const [strapiPrivacyPage, setStrapiPrivacyPage] = useState(null)

  useEffect(() => {
    const fetchPrivacyPage = async () => {
      const { data } = await strapi.get('/privacy-policy?populate[content][populate]=*&populate[MetaData][populate]=*')

      if (data) {
        setStrapiPrivacyPage(data.data.attributes)
      }

      setPrivacyLoading(false)
    }

    fetchPrivacyPage()
  }, [])

  if (isPrivacyLoading)
    return (
      <Box id="home-carousel" mb={4}>
        <Loading />
      </Box>
    )

  return (
    <React.Fragment>
      {strapiPrivacyPage?.MetaData &&
        <Helmet
          metaArray={[
            { content: strapiPrivacyPage.MetaData?.metaText , name: 'description' },
            { content: strapiPrivacyPage.MetaData?.metaText , property: 'og:description' },
            { content: strapiPrivacyPage.MetaData?.metaTitle , property: 'og:title' },
          ]}
          title={strapiPrivacyPage.MetaData?.pageTitle}
        />
      }

      <Container className="mb-5 content-container">
        <HeadingWithBg
          as="h4"
          color="white"
          imageName="headerBgV4"
          my={30}
          p={40}
          position="relative"
          textAlign="center"
        >
          <FormattedMessage id="privacyPolicy" />
        </HeadingWithBg>

        <Row>
          <Col md={12}>
            <Text>
              {strapiPrivacyPage.title}
            </Text>

            {React.Children.toArray(
              strapiPrivacyPage?.content.map((data) =>
                <CustomContentStyle className="line2" pl={0}>
                  <BlocksRenderer content={data.content} />
                </CustomContentStyle>
              )
            )}
          </Col>
        </Row>

      </Container>
    </React.Fragment>
  )}

const CustomContentStyle = styled(Box)`
  margin-bottom: 16px;

  background-color: #fff;
  padding: 24px;

  h1, h2, h3, h4, h5, h6 {
    color: #444444;
  }

  h2 {
    margin-bottom: 20px;
  }

  .box {
    h1, h2, h3, h4, h5, h6 {
      color: #444444;

      margin-left: 0;
    }

    background-color: #fff;
    border-radius 4px;
  }

  @media (max-width: 756px) {
    h2 {
      font-size: 24px;
    }
  }
`

export default PrivacyPolicy
