import React, { PureComponent } from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { Box, IntersectionVisible } from '../../elements'

class LazyLoad extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
    }
  }

  componentWillUnmount() {
    this.originalSizedImage.onload = null
  }

  updateLoadingState = () => {
    this.setState({
      isLoaded: true,
    })
  }

  originalSizedImage = new Image()

  getImage = () => {
    this.originalSizedImage.src = this.props.imageSrc
    this.originalSizedImage.onload = this.updateLoadingState
  }

  render() {
    const { isLoaded } = this.state

    return (
      <IntersectionVisible onShow={this.getImage}>
        <Box loaded={isLoaded} style={{ overflow: 'hidden', ...this.props.boxStyle }}>
          {isLoaded
            ? this.props.children({ imageSrc: this.props.imageSrc, isLoaded })
            : <AnimationOverlay />
          }
        </Box>
      </IntersectionVisible>
    )
  }
}

const slideAnimation = keyframes`
  from { margin-left: -160px; }
  to { margin-left: 100%; }
`

const AnimationOverlay = styled.div`
  height: 100%;
  animation: ${slideAnimation} 1.5s ease-in-out infinite;
  background-image: linear-gradient(
    90deg, rgba(255,255,255,0) 0px, rgba(255,255,255,0.2) 80px, rgba(255,255,255,0) 160px
  );
`

LazyLoad.propTypes = {
  boxStyle: PropTypes.object,
  children: PropTypes.func,
  imageSrc: PropTypes.string,
}

export default LazyLoad
