/*eslint no-template-curly-in-string: "off"*/

const translates = {
  accept: 'Accept',
  accountInformation: 'Account Information',
  accountSettings: 'Account Settings',
  addCreditCard: 'Add credit card',
  addPayment: 'Add payment',
  address: 'Address',
  address2: 'Address 2',
  addressesList: 'Addresses list',
  addToCart: 'Add to Cart',
  addYourPayment: 'Add your payment',
  aggreTermsAndConditions: 'Agree to {termsAndConditions} & {privacyPolicy}',
  allocatedDeposits: 'Allocated Deposits',
  allRightsReserved: '© {year} All rights reserved',
  amountYouPayNow: 'Amount you pay now:',
  analyticsCookies: 'Analytics cookies',
  apartment: 'Apartment/Suite',
  applied: 'Applied',
  atHomeCount: '{quantity} at home {quantity, plural,  one {item}  other {items}}',
  atLeastLetter: 'At least 1 lowercase letter',
  atLeastNumber: 'At least 1 number',
  atLeastUpperLetter: 'At least 1 uppercase letter',
  availability: 'Availability',
  averageCost: '*$0.40 average cost of name brand disposable diaper',
  backToHomePage: 'Back to homepage',
  billingAddress: 'Billing address',
  billingAddressSameAsShippingAddress: 'My billing address is the same as my shipping address',
  billingPreferences: 'Billing Preferences',
  blank: 'Field is required',
  blankTextId: '{text}',
  byClicking : 'By clicking the "Place Order" button, you confirm that you have read, understand, and accept our Terms of Use, Terms of Sale, Privacy Policy, and Return Policy.',
  cancel: 'Nevermind',
  cancelBtn: 'Cancel',
  cancellationReasonFeedbackByMail: 'If you have any additional feedback, please reach out to {link}',
  cancellationReasons: 'As our reusable diaper service is new, we would love to hear your feedback on the top reason(s) you are cancelling:',
  cancellationSummaryTitle: 'We’ll miss you!',
  cancellationTitle: 'Confirm your cancellation',
  cancelPlan: 'Cancel your plan',
  cancelSubscription: 'Or, you can cancel your current subscription',
  cardHolder: 'Name',
  cardNumber: 'Card Number',
  cardsToAccept: 'We accept the following cards.',
  changeOrCancel: 'Change or Cancel',
  changePassword: 'Change password',
  changePlan: 'Change Plan',
  checkout: 'Checkout',
  checkOutYourSubscription: 'Check out your subscription’s details below.',
  CheckOutYourSubscriptionAndDeliverySettings: 'Check out your subscription & delivery settings',
  checkSubscriptionSettings: 'Check out your subscription & delivery settings',
  chooseBoundle: 'Choose a bundle',
  chooseDiaper: 'Choose a Diaper Bundle',
  city: 'City',
  clickHereToScheduleYourPickUp: 'Click here to schedule your UPS pick up',
  comparePrice: 'Compare to the {calculatedPrice} average lifetime cost of disposable diapers!',
  confirm: 'Confirm',
  confirmationTitle: 'Confirm your new plan',
  confirmPassword: 'Confirm Password',
  confirmYourChange: 'Confirm your change',
  confirmYourSubscription: 'Confirm your subscription',
  consumerPrivacy: 'Do Not Sell Or Share My Personal Information',
  contactSupport: 'contact our customer service.',
  contactUs: 'Contact Us',
  continueToBilling: 'Continue to Billing',
  continueToPayment: 'Continue to payment',
  continueToShippingMethod: 'Continue to Shipping Method',
  cookieBannerDesc: 'This site uses cookies to provide you with a good browsing experience. Functional cookies are essential to make our site work. Cookies also let us analyze our traffic and site performance, both on and off the site. You are in control - {link} about our privacy.',
  cookieBannerLink: 'Find out more',
  cookieBannerTitle: 'Our Cookie Policy',
  cookiePageBottomLine: 'For more information about our use of personal data, {link} about our privacy.',
  cookiePageBottomLineLink: 'find out more',
  cookiePageContentLine1: 'You can manage your cookies on 3 levels.',
  cookiePageContentLine2: 'Select the cookies level you are willing to accept:',
  cookiePageDetailsAnalyticsAllow1: 'To keep your session and details about your order',
  cookiePageDetailsAnalyticsAllow2: 'To keep your profile data',
  cookiePageDetailsAnalyticsAllow3: 'To keep user data to allow us to analyze and continuously improve the customer experience.',
  cookiePageDetailsAnalyticsDisallow1: 'To share session information with advertisers on other websites which allows you to offer relevant advertisements and announcements, which are linked to your browsing history.',
  cookiePageDetailsFunctionalAllow1: 'To keep your session and details about your order',
  cookiePageDetailsFunctionalAllow2: 'To keep your profile data',
  cookiePageDetailsFunctionalDisallow1: 'To keep user data to allow us to analyze and continuously improve the customer experience.',
  cookiePageDetailsFunctionalDisallow2: 'To share session information with advertisers on other websites which allows you to offer relevant advertisements and announcements, which are linked to your browsing history.',
  cookiePageDetailsMarketingAllow1: 'To keep your profile data',
  cookiePageDetailsMarketingAllow2: 'To keep user data to allow us to analyze and continuously improve the customer experience.',
  cookiePageDetailsTitleAllow: 'The website allows:',
  cookiePageDetailsTitleDisallow: 'The website doesn’t allow:',
  cookiePageSaveButton: 'Save changes',
  costComparison: 'Cost Comparison',
  couldNotFindUser: 'User with this email is not registered',
  country: 'Country',
  createPassword: 'Create Password',
  creditCardInfoText: ' * Loop will securely process your credit card information in order to process deposit refunds and any auto-refills you have selected.',
  creditCardList: 'Credit card list',
  creditCardNumber: 'Credit card number',
  creditCardTypeIsNotAcceptedByThisMerchantAccount: 'Credit card type is not accepted by this merchant account',
  currentPlanActiveTill: 'Your current plan will be active until {date}. We will stop charging you thereafter.',
  cvv: 'CVV',
  cvvMustBe4DigitsForAmericanExpressAnd3DigitsForOtherCardTypes: 'CVV must be 4 digits for American Express and 3 digits for other card types',
  dateOfPayment: 'Date of payment',
  delete: 'Delete',
  delivered: 'Delivered',
  delivery: 'Delivery:',
  deliveryHistory: 'Delivery History',
  deliveryInformation: 'Delivery Information',
  deliveryStatus: 'Delivery Status',
  deposit: 'deposit',
  depositAccount: 'Deposit Account',
  depositPrice: 'Deposit price',
  diapersAreProfessionallyCleaned: 'Diapers are professionally cleaned and recirculated, so nothing goes to landfill',
  disposables: 'Disposables',
  doAllTheCleaningYourself: 'Do all the cleaning yourself',
  dueAt: 'Due at',
  edit: 'Edit',
  email: 'Email',
  emailAddress: 'Email address',
  emailIsInvalid: 'Email is invalid',
  enjoy: 'Enjoy',
  enterCoupon: 'Enter coupon code here',
  enterYourEmail: 'Enter your email',
  enterYourNewPassword: 'Enter your new password',
  enterYourNewPasswordOneMoreTime: 'Enter your new password one more time',
  error404Description: 'The page you were looking for could not be found',
  error404Title: '404 - Not found',
  everyWeek: 'every week',
  expensiveUpfrontInvestment: 'Expensive upfront investment',
  expiration: 'Expiration',
  expirationDate: 'Expiration date',
  expiredDate: 'Date is expired',
  faq: 'FAQ',
  fieldIsRequired: 'Field is required',
  firstDelivery: 'First Delivery',
  firstName: 'First name',
  follow: 'Follow',
  forgotPasswordButton: 'Forgot Password',
  free: 'FREE',
  freePickUp: 'Free pick-up for return',
  freePickUpDescription: 'Enjoy a free pick-up from anywhere in the continental U.S. when you\'re ready to return your diapers for cleaning.',
  fullNameOnCard: 'Full name as displayed on card',
  functionalCookies: 'Functional cookies',
  getFamiliar: 'Get familiar with our mission',
  getStarted: 'Get Started',
  great: 'Great!',
  heresHowToStart: 'Here’s how to get started.',
  heresHowWeMakeItEasy: 'Here’s how we make it easy to get started:',
  homePageBannerText: 'Try Loop Diapers today for 15% off your first order with code NEW15',
  howDeliveryWorks: 'See how delivery works',
  howItWorks: 'How it Works',
  howItWorksEnjoyDesc: 'Receive delivery to your doorstep and start enjoying premium cloth diapers.',
  howItWorksRepeatDesc: 'Get your deposit refunded to your account, and apply it to your next diaper bundle so you never run out.',
  howItWorksReturnDesc: 'Send us back only the diapers you want professionally cleaned, whenever you want, from anywhere in the U.S.',
  howItWorksSelectDesc: 'Choose a bundle of reusable diapers.  Pick our Starter Pack for part-time cloth diapering, or our Pro Pack for full time coverage.',
  howToReachUs: 'How to reach us',
  inboundTrackingNumber: 'Inbound tracking number',
  includes: 'Includes:',
  invalid: 'Field is invalid',
  invalidCartHolderName: 'Please enter your first and last name',
  invalidCreditCardUpdate: 'Credit Card update was invalid',
  item: 'item',
  itemInYourCart: '{quantity} {quantity, plural,  one {product}  other {products}} in your cart',
  items: 'items',
  iWantNewPack: 'I want a new pack every week on*:',
  keepInTouch: 'Keep in touch',
  lastDelivery: 'Last Delivery',
  lastName: 'Last name',
  latestDelivery: 'Latest Delivery',
  latestDeliveryDateAndValue: '${value} on {date}',
  learnMoreAbout: 'Learn more about',
  leaveAllTheToughClearning: 'Leave all the tough cleaning to us!',
  leftWithHardToResellDiapers: 'Left with hard-to-resell used diapers at the end of your diapering phase',
  lifetimeCostOfDiapers: 'lifetime cost of diapers',
  loading: 'Loading',
  login: 'Login',
  loginButton: 'Login',
  logout: 'Logout',
  loopDiapers: 'Loop Diapers',
  manageCookies: 'Manage cookies',
  manageCookiesButton: 'Manage',
  manageDeposits: 'Manage Deposits',
  marketingCookies: 'Marketing cookies',
  metaText: 'Trying to decide what type of diapers will be best for your baby? Cloth diapering is Easy, Healthier, Economical, Eco Conscious and so much Cuter!',
  metaTextContent: 'Questions about how it works, or need help with your account? Drop us a line below.',
  metaTextCostCalculator: 'Enjoy premium reusable cloth diapers, for less than the cost of disposables - and never clean another blowout!',
  metaTextFaq: 'Loop Diapers is the first nationally available cloth diaper service that takes the work and the upfront cost out of cloth diapering, making it simple, easy and enjoyable.',
  metaTextHowItWorks: 'Loop’s diaper service is different - you can actually save money on diapers (compared to disposables or your own reusables).',
  metaTextOurMission: 'Loop Diapers is on a mission to make cloth diapering affordable, convenient, and accessible to all.',
  metaTextPp: 'This “Privacy Policy” describes the privacy practices of LOOP Global Holdings LLC (together with our affiliates, “Loop”, “Company” “we”, or “us”).',
  metaTextProducts: 'Pick our Starter Pack for part-time cloth diapering, or our Pro Pack for full time coverage.',
  metaTextSignUp: 'Loop’s cloth diaper service is now available nationwide! Ready to start? Sign up for a free account',
  metaTextTos: 'LOOP Global Holdings LLC a Delaware limited liability company (together with our affiliates, “Loop”, “Company” “we”, or “us”) offers a diaper service accessed via the website located at: www.loopdiapers.com wherein diaper, wipes, diaper inserts and other supplies can be provided to a consumer in two different size options, details below.',
  minPwChar: 'Minimum 8 character',
  modifyOrder: 'Modify Order',
  month: 'month',
  monthShortcode: 'Month',
  moreAboutDiapers: 'Read more about cloth diapers',
  myAccount: 'My account',
  nameOfPlan: 'Name of subscription plan',
  nameOnCard: 'Name on card',
  newAccount: 'Create a new account',
  newAddress: 'New address',
  newCreditCard: 'New credit card',
  newPackEveryWeek: 'I want a new pack {period} on:',
  newPlanOneTimeSummary: 'Your new plan will become active on {date}. You will be charged ${price} every month thereafter, until you switch to a new plan or cancel your current one.',
  newPlanRefreshWeeklySummary: 'Your new plan will become active on {date}. You will be charged ${price} every month thereafter, until you switch to a new plan or cancel your current one.',//TODO: correct the text
  newsletter: 'Newsletter',
  newsletterButton: 'Sign up',
  newsletterEmailAddress: 'Email Address',
  newsletterSignUp: 'Newsletter Sign Up',
  nextDelivery: 'Next Delivery',
  nextPackDate: 'You’ll get a new pack {period} on {date}',
  nextPayment: 'Next Payment',
  nextPaymentDateAndValue: '${value} due on {date}',
  noImageAvailable: 'No image available',
  noInformation: 'No information',
  notificationCreatePaymentMsg: 'Credit card was added successfully!',
  notificationErrorTitle: 'Error!',
  notificationItemAdded: '1 Item added to the cart',
  notificationItemRemoved: '1 Item removed from the cart',
  notificationLoginErrorMsg: 'The email address or password is incorrect. Please try again',
  notificationLoginMsg: 'Login was successful!',
  notificationLogoutMsg: 'Log out was successful!',
  notificationNewsletterMsg: 'You signed up to the newslettter successfully!',
  notificationPleaseLoginForContinue: 'Please login to continue',
  notificationRecoveryMsg: 'We will send you an email with instructions on how to reset your password',
  notificationResetPasswordMsg: 'Now you can log in with your new password',
  notificationResgistrationMsg: 'Registration was succesfull.',
  notificationSomethingWentWrong: 'Something went wrong. Try again later.',
  notificationSuccessTitle: 'Success!',
  notificationUpdatePaymentMsg: 'Credit card was updated successfully!',
  notificationUpdateUserMsg: 'User details updated was successfull!',
  notificationYourTokenExpired: 'Your token is expired',
  noUpfrontInvestment: 'No upfront investment - pay a fully refundable deposit to borrow diapers',
  oneTimeRental: 'One time rental',
  optIntoCiResearch: 'I am interested in helping Loop and Charlie Banana create a zero waste future by sharing my input and feedback.',
  or: '- or -',
  orderDate: 'Order date',
  orderElementBundleServiceFee:'Diaper Use Fee',
  orderElementCharged: 'Charged to credit card',
  orderElementDeducted: 'Deducted from deposit balance',
  orderElementRefundable: 'Refundable Deposit',
  orderElementShipping: 'Shipping',
  orderElementTotal: 'Total',
  orderHistory: 'Order History',
  orderNumber: 'Order number',
  orderReview: 'Order review',
  orderSavedAddress: 'Saved address for this order',
  orderUpdated: 'Order updated',
  ourMission: 'Our Mission',
  ourMissionHeader: 'Loop Diapers is on a mission to make cloth diapering affordable, convenient, and accessible to all.',
  ourServiceIsAvailable: 'Great! Our service is available in your area.',
  ourServiceIsNotAvailable: 'Our service is not available in your area, but you can subscribe to our waiting list, so we can notify you when it becomes available.',
  pageTitle404: 'Loop Diapers - 404 not found',
  pageTitleCancellationSummary: 'Loop Diapers - Cancellation Summary',
  pageTitleCart: 'Loop Diapers - Shopping cart',
  pageTitleChangeConfirmation: 'Loop Diapers - Subscription Confirmation',
  pageTitleChangeSubscription: 'Loop Diapers - Subscription Change',
  pageTitleCheckout: 'Loop Diapers - Checkout',
  pageTitleConfirmation: 'Loop Diapers - Order confirmation',
  pageTitleContactUs: 'Loop Diapers - Contact Us',
  pageTitleCostCalculator: 'Loop Diapers - Cost Comparison',
  pageTitleDefault: 'Loop Diapers',
  pageTitleFaq: 'Loop Diapers - Support and FAQ',
  pageTitleHome: 'Loop Diapers - Home',
  pageTitleHowItWorks: 'Loop Diapers - How It Works?',
  pageTitleLogin: 'Loop Diapers - Login',
  pageTitleOurMission: 'Loop Diapers - Our Mission',
  pageTitlePrivacyPolicy: 'Loop Diapers - Privacy Policy',
  pageTitleProduct: 'Loop Diapers - {value}',
  pageTitleProducts: 'Loop Diapers - Products',
  pageTitleRecovery: 'Loop Diapers - Password recovery',
  pageTitleResetPassword: 'Loop Diapers - Reset password',
  pageTitleSignup: 'Loop Diapers - Sign up',
  pageTitleTermsAndConditions: 'Loop Diapers - Terms and Conditions',
  pageTitleUserAccount: 'Loop Diapers - User Account settings',
  pageTitleUserDelivery: 'Loop Diapers - User Delivery history',
  pageTitleUserManageDeposits: 'Loop Diapers - User Manage deposits',
  pageTitleUserPaymentHistory: 'Loop Diapers - User Payment history',
  pageTitleUserScheduleAPickup: 'Schedule a pickup',
  pageTitleUserSubscription: 'Loop Diapers - User Subscription',
  password: 'Password',
  passwordConfirmation: 'Password confirmation',
  passwordDoesntMatch: 'Password does not match',
  passwordIsInvalid: 'Password is invalid',
  passwordRecovery: 'Password recovery',
  passwordResetSuccess: 'Now you can log in with your new password',
  payment: 'Payment',
  paymentHistory: 'Payment History',
  paymentInformation: 'Payment Information',
  paymentMethod: 'Payment Method',
  paymentMethodChange: 'Change payment method',
  paymentMethodTokenPaymentInstrumentTypeIsNotAcceptedByThisMerchantAccount: 'We do not accept Credit Card type currently',
  paymentSettings: 'Payment Settings',
  phone: 'Phone',
  phoneNumber: 'Phone number',
  pickYourPlan: 'Pick your plan',
  placeOrder: 'Place order',
  plan: 'Plan',
  planRemainActive: 'Your current plan will remain active until {date}. We will stop charging you and you receive no new packs thereafter.',
  pleaseAddPayment: 'Please add payment method',
  pleaseNote: 'Please note',
  pleaseNoteThatYourCurrentSub: 'Please note that your current subscription ({subscriptionName}) will remain active until {date}',
  pleaseSelect: 'Please select',
  priceOfPayment: 'Price of payment',
  privacyPolicy: 'Privacy Policy',
  productCompare1: 'More expensive over time',
  productCompare2: 'Go straight to landfill',
  productName: 'Product name',
  products: 'Products',
  promoText: 'Get Your first month for only {price}/week',
  promoTextAfter: 'Then {price}/week after the first 4 weeks, billed monthly. Cancel anytime.',
  promotion: 'Promotion',
  promotionAdded: 'Promotion successfully activated',
  quantity: 'Quantity',
  readMoreAboutClothDiapers: 'Read more about cloth diapers',
  readOurFaq: 'Read our FAQ',
  readyToStart: 'Ready to start?',
  readyToStartEasy: 'Ready to start easy, affordable cloth diapering?',
  redeem: 'Redeem',
  refundableDeposit: 'refundable deposit',
  refundableProductDeposit: 'Refundable product deposit',
  register: 'Register',
  remainingDeliveries: 'Your remaining deliveries:',
  remainingDeliveriesLong: 'You still have {quantity} remaining deliveries which you can check out below.',
  removeThisAddress: 'Remove this address from the list',
  removeThisCard: 'Remove this card from the list',
  rent: 'Rent',
  repeat: 'Repeat',
  replenishYourProductsText: 'When you\'re ready to return your Loop Tote, simply schedule a pick up using the form below or drop off at any UPS location.  You may also schedule a pick up by calling Customer Care at {storePhoneNumber}.',
  resetMyPassword: 'Reset my password',
  resetPasswordTokenInvalid: 'The link for resetting the password is incorrect or it was already used',
  return: 'Return',
  returnDate: 'Return Date',
  returnedItemCount: '{quantity} returned {quantity, plural,  one {item}  other {items}}',
  returnedItemNumber: 'Number of returned items',
  reviewAndAuthOrder: 'Review and authorize order',
  reviewOrder: 'Review order',
  save: 'Save',
  savedAddresses: 'Saved addresses',
  saveSettings: 'Save settings',
  schedulePickup: 'Schedule Pick Up',
  securityCode: 'Security code',
  seeHow: 'See how',
  seeHowDeliveryWorks: 'See how delivery works',
  select: 'Select',
  sendMeRecoveryLink: 'Send me a recovery link',
  setNewPassword: 'Set a new password',
  settings: 'Settings',
  setupPlan: 'Setup Plan',
  setupYourPlan: 'Setup your plan',
  shipping: 'Shipping',
  shippingAddress: 'Shipping Address',
  shippingMethod: 'Shipping Method',
  shop: 'Shop',
  shopMoreProducts: 'Shop more products',
  shoppingCart: 'Shopping cart',
  shortFriday: 'Fri',
  shortMonday: 'Mon',
  shortSaturday: 'Sat',
  shortSunday: 'Sun',
  shortThursday: 'Thu',
  shortTuesday: 'Tue',
  shortWednesday: 'Wed',
  signInOrRegister: 'Sign In / Register',
  signOut: 'Sign Out',
  signUp: 'Sign-up',
  signUpAvailabilityText: 'Before we proceed, we need to check if our service is available in your area. Please fill your zip code below.',
  signUpForAFreeAccount: 'Sign up for a free account',
  signUpOrLogin: 'Login / Register',
  signUpToNewsletter: 'Sign up to Newsletter',
  somethingWentWrong: 'Something went wrong. Try again later.',
  state: 'State',
  stayInTheLoop: 'Stay in the Loop',
  stayInTouch: 'Stay in touch',
  streetAddress: 'Street address',
  subscribe: 'Subscribe',
  subscribeToWaitingList: 'Subscribe to our waiting list',
  subscriptionAndDelivery: 'Subscription & Delivery',
  subscriptionDetails: 'Check out your subscription’s details below.',
  subscriptionOneTimeRental: 'One time rental for ${price}/month',
  subscriptionOneTimeRentalShort: 'One time rental',
  subscriptionRefreshWeekly: 'Refresh weekly for ${price}/month',
  subscriptionRefreshWeeklyShort: 'Refresh every week',
  subscriptionThankYou: 'Thank you for subscribing!',
  subtotal: 'Subtotal',
  summary: 'Summary',
  supportAndFaq: 'Support and FAQ',
  taken: 'Already taken',
  termsAndConditions: 'Terms and Conditions',
  thankYou: 'Thank you!',
  thankYouPurchase: 'Thank you for your purchase!',
  thereIsNoDataToShow: 'There is no data to show',
  theStoredCreditCardHasExpired: 'Card has expired',
  toChangeAddress: 'To change your shipping address please',
  totalAmount: 'Total amount',
  totalPrice: 'Total price of shipment',
  toteDepositApplicableDiscount: 'Deposits, tax and applicable discounts will be calculated at checkout.',
  toUpdateYourShippingAddress: 'Updating your Billing Address does not update your Shipping Address. To update your Shipping Address, please reach out to our Customer Care team at {link}',
  traditionalClothDiapers: 'Traditional cloth diapers',
  traditionalVsLoop: 'Traditional cloth diapers vs. Loop Diapers',
  transactions: 'Transactions',
  unableToDeliverToPOBoxes: 'At this time, Loop is unable to deliver to P.O. Boxes',
  update: 'Update',
  verificationFailed: 'Recaptcha verification failed',
  visitOurMainSites: 'Loop x Charlie Banana is an exclusive partnership.{br}For more information on either Loop or Charlie Banana, visit our main sites at:',
  weArentAcceptingNewSubs: 'We aren’t accepting new subscriptions right now, but you can subscribe to our waiting list, so we can notify you when new sign-ups are available again.',
  weDoNotSupportTheBrowser: 'We do not support the browser you are using. To use our application, please switch to a supported browser:',
  week: 'week',
  weekly: 'every week',
  weeklyDelivery: 'Weekly',
  weWillNotifyYou: 'We will notify you via email when you can sign-up',
  whatsInMyLoop: 'What’s in my Loop',
  whatToDoNext: 'Wondering what to do next?',
  whyLoopDiaperService: 'Why Loop Diaper Service?',
  withdrawMyDeposits: 'Withdraw my deposits',
  wrongZipCodeFormat: 'Wrong format. Example: 10004, 10004-2345',
  yearShortcode: 'Year',
  youCanAddPromoCode: 'You can add your promo code during checkout.',
  youDontHaveSavedCreditCard: 'You dont have saved credit card',
  yourBrowserIsNotSupported: 'Your browser is not supported.',
  yourCartIsEmpty: 'Your cart is unfortunately empty.',
  yourCookieSettingsHaveBeenSaved: 'Your cookie settings have been saved',
  yourCurrentPlan: 'Your current plan:',
  yourLoopIsEmpty: 'Your Loop is empty. {link} to fill your Loop.',
  yourNewPlan: 'Your new plan:',
  yourNextSubscriptionWillBe: 'Your next subscription will be',
  yourSelectedPlanWillStart: 'Your new subscription ({subscriptionName}) will start on {date}',
  youWillReceiveDepositBack: 'You will receive {value} of deposits back when you return your products',
  zipCode: 'Zip Code',
}

export default translates

