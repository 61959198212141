import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'
import { InfoCircle } from 'react-bootstrap-icons'
import './info-box.scss'

const renderInfoIcon = (variant) => {
  switch (variant) {
  case 'info':
    return <InfoCircle className="alert-info-icon" />
  default:
    return null
  }
}

const InfoBox = ({ variant, children }) => (
  <Alert variant={variant}>
    {children}
    {renderInfoIcon(variant)}
  </Alert>
)

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
}

export default InfoBox
