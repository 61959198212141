import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import 'intersection-observer'

class IntersectionVisible extends PureComponent {
  componentDidMount() {
    const { active = true, options } = this.props
    this.observer = new IntersectionObserver(this.handleObserverUpdate, options)

    if (active) {
      this.startObserving()
    }
  }

  componentDidUpdate(prevProps) {
    const { active = true } = this.props

    if (active && !prevProps.active) {
      this.startObserving()
    }

    if (!active && prevProps.active) {
      this.stopObserving()
    }
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  handleObserverUpdate = entries => {
    const { onIntersect = () => null, onShow = () => null, onHide = () => null } = this.props
    const { intersectionRect } = entries[0]
    const { top, left, bottom, right } = intersectionRect

    if ([top, bottom, left, right].some(Boolean) && onShow) {
      onShow(entries)
    }
    else if (onHide) {
      onHide(entries)
    }

    onIntersect(entries)
  }

  startObserving() {
    this.observer.observe(this.node)
  }

  stopObserving() {
    this.observer.unobserve(this.node)
  }

  render() {
    return (
      <div ref={node => this.node = node}>
        {this.props.children}
      </div>
    )
  }
}

IntersectionVisible.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  onHide: PropTypes.func,
  onIntersect: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  options: PropTypes.shape({
    root: PropTypes.node,
    rootMargin: PropTypes.string,
    threshold: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  }),
}

export default IntersectionVisible
