import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, space, typography } from 'styled-system'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../elements'

const GetStarted = ({ link = '/products', text = <FormattedMessage id="getStarted" />, ...props }) => (
  <StyledLink
    bg="primary.dark"
    color="gray.light"
    external={props.isExternal}
    mr={[0, 2]}
    onClick={props.onClick}
    to={link}
    {...props}
  >
    {text}
  </StyledLink>
)

const StyledLink = styled(Link)`
  font-family: 'Urbanist', sans-serif;
  color: ${props => props.theme.colors.gray.light};

  ${color}
  ${space}
  ${typography}
  
  color: ${props => props.theme.colors.gray.light};

  border-radius: 30px;

  font-weight: 400;

  line-height: 24px;

  cursor: pointer;

  font-size: ${props => props.fontSize || 14}px;

  padding: ${props => `${props.py || 10}px ${props.px || 60}px`};

  &:hover {
    color: ${props => props.theme.colors.gray.light};
    text-decoration: underline !important;
  }
`

GetStarted.propTypes = {
  isExternal: PropTypes.bool,
  link: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default GetStarted
