import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './dsar.scss'

const DsarPage = () => (
  <Container className="mb-5 content-container">
    <Row>
      <Col display="flex" md={{ span: 12 }}>
        <div className="ot-form-wrapper">
          <iframe id="dsar" src="https://privacyportal.onetrust.com/webform/b8c23cad-e1f0-4b9a-b984-072a1c303c05/3f3721d9-c426-4efb-ab32-08b02db53492" title="dsar" />
        </div>
      </Col>
    </Row>
  </Container>
)

export default DsarPage
