import { PureComponent } from 'react'
import ReactPixel from 'react-facebook-pixel'

export class FacebookPixel extends PureComponent {
  static options = {
    autoConfig: true,
    debug: false,
  }

  componentDidMount() {
    ReactPixel.init('713083803484976', {}, this.options)

    ReactPixel.pageView()
  }

  render() {
    return null
  }
}

export default FacebookPixel
