import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { isEmpty } from '../../../lodash'
import { equals } from 'validator'
import { FormattedMessage } from 'react-intl'
import { PasswordInput } from '../../../components'
import { Button, Heading } from '../../../elements'
import { TranslatedTitleLoader, withProfile } from '../../../modules'
import { addNotification, passwordValidator, scrollToElement, trackEvent } from '../../../utils'
import { userPropTypes } from '../../../types'
import client from '../../../api/api'

export const ResetPasswordPage = ({ user }) => {
  let params = useParams()
  let navigate = useNavigate()

  const [ formError, setFormError ] = useState(null)
  const [ formState, setFormState ] = useState({
    password: '',
    password_confirmation: '',
  })

  useEffect(() => {
    scrollToElement()
  }, [])

  useEffect(() => {
    if (!isEmpty(formError)) {
      scrollToElement('invalid-feedback', 'aboveElement')
    }
  }, [formError])

  if (user.me || isEmpty(params.token))
    return <Navigate to="/" />

  const handleInputChange = (event) => {
    const { id , value } = event.target

    setFormState(prevState => ({
      ...prevState,
      [id]: value,
    }))
  }

  const checkFormValidation = () => {
    const errors = {}

    if (!passwordValidator(formState.password).isValidPassword) {
      errors.password = 'passwordIsInvalid'
    }

    if (!equals(formState.password, formState.password_confirmation)) {
      errors.password_confirmation = 'passwordDoesntMatch'
    }

    setFormError(errors)

    return isEmpty(errors)
  }

  const handleClick = (event) => {
    event.preventDefault()

    setFormError(null)

    const isValidForm = checkFormValidation()

    if (isValidForm) {
      const { password, password_confirmation } = formState
      const { token } = params

      client.account.resetPassword(token, {
        user: {
          password: password,
          password_confirmation: password_confirmation,
        },
      })
        .then((response) => {
          if (response.isSuccess()) {
            addNotification({
              messageId: 'notificationResetPasswordMsg',
              titleId: 'notificationSuccessTitle',
              type: 'success',
            })

            trackEvent('User', 'Changed the password', 'Reset password Page')

            navigate('/login', { replace: true })
          } else {
            addNotification({
              backendError: response.fail().serverResponse.data.error,
              messageId: 'somethingWentWrong',
              titleId: 'notificationErrorTitle',
              type: 'danger',
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  return (
    <Container className="my-5">
      <TranslatedTitleLoader titleId="pageTitleResetPassword" />

      <Row className="password-reset-page">
        <Col lg={{ offset:4, span:4 }} md={{ offset:3, span:6 }}>
          <Heading className="password-reset-page-title" fontSize="2.3rem" mb={3} textAlign="center">
            <FormattedMessage id="setNewPassword" />
          </Heading>

          <Form id="password-reset-form">
            <Form.Group controlId="password">
              <Form.Label md="4">
                <FormattedMessage id="enterYourNewPassword" />*
              </Form.Label>
              <PasswordInput
                onChange={handleInputChange}
                value={formState.password}
              />

              {formError?.password &&
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id={formError.password} />
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Form.Group controlId="password_confirmation">
              <Form.Label md="4">
                <FormattedMessage id="enterYourNewPasswordOneMoreTime" />*
              </Form.Label>

              <PasswordInput
                name="password_confirmation"
                onChange={handleInputChange}
                showHint={false}
                value={formState.password_confirmation}
              />

              {formError?.password_confirmation &&
                <Form.Control.Feedback type="invalid">
                  <FormattedMessage id={formError.password_confirmation} />
                </Form.Control.Feedback>
              }
            </Form.Group>

            <Button
              className="full-width-button primary-button"
              id="password-reset-button"
              onClick={handleClick}
              type="submit"
              variant="primary"
              width="100%"
            >
              <FormattedMessage id="resetMyPassword" />
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

ResetPasswordPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(ResetPasswordPage)
