import { createContext } from 'react'

export const defaultValues = {
  error: null,
  loading: false,
  me: null,
  refetch: () => {},
}

export default createContext(defaultValues)
