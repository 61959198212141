import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Image = styled.img`
  object-fit: cover;
`

Image.displayName = 'Image'

export const ResponsiveImage = ({ classes='', desktopImages, mobileImages }) => {
  const hasImageOnMobile = mobileImages?.length > 0

  return (
    <>
      <Image className={`img-fluid ${classes} ${hasImageOnMobile ? 'd-none d-sm-block' : ''}`} name="desktop-image" src={desktopImages[0].url} />
      {hasImageOnMobile && <img alt="" className={`img-fluid ${classes} d-block d-sm-none`} name="mobile-image" src={mobileImages[0].url} />}
    </>

  )
}

export default ResponsiveImage

ResponsiveImage.propTypes = {
  classes: PropTypes.string,
  desktopImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
  mobileImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
}
