/**
 * Detect whether an object of errors has any non-null value.
 *
 * @param {String} errors - object of errors, where key is the field name, value is the error
 *
 * @returns {Boolean} true when object has error, false when it does not have any
 */
const hasAnyError = (errors) => (
  Object.keys(errors).some((objKey) => (errors[objKey] !== null))
)

export default hasAnyError
