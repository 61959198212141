import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { Box, Button, Currency, Divider, Link, SummaryElement, Text } from '../../elements'

const SummaryBox = ({ cart: { adjustment_total, sum_fee, total, sum_deposit }, readOnly=false }) => (
  <Box>
    <Box border="1px solid" borderColor="gray.light" borderRadius={2} p={3} style={{ boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.15)' }}>
      <SummaryElement contentTranslationId="products" currencyValue={sum_fee} isHighlighted />
      {adjustment_total !== '0.0' && <SummaryElement contentTranslationId="promotion" currencyValue={adjustment_total} isBold />}
      <SummaryElement contentTranslationId="refundableProductDeposit" currencyValue={sum_deposit} />
      <SummaryElement contentTranslationId="shipping" currencyValue={0} isFree />

      {!readOnly &&
          <>
            <Text color="dark" my={4}>
              <FormattedMessage id="toteDepositApplicableDiscount" />
            </Text>

            <Text color="dark" my={4}>
              <FormattedMessage id="youCanAddPromoCode" />
            </Text>
          </>
      }

      <Divider />

      <SummaryElement
        contentTranslationId="amountYouPayNow"
        currencyValue={total}
        isHighlighted
      />

      <Text color="dark" fontWeight="bold" my={4}>
        <FormattedMessage
          id="youWillReceiveDepositBack"
          values={{
            value: <StyledSpan><Currency value={sum_deposit} /></StyledSpan>,
          }}
        />
      </Text>

      {!readOnly &&
          <Divider />
      }

      {!readOnly &&
        <Link data-test="checkoutLink" to="/checkout">
          <Button className="begin-checkout" mt={3} width="100%">
            <FormattedMessage id="checkout" />
          </Button>
        </Link>
      }
    </Box>
  </Box>
)

const StyledSpan = styled.span`
  color: ${props => props.theme.colors.primary.dark}
`

SummaryBox.propTypes = {
  cart: PropTypes.shape({
    adjustment_total: PropTypes.string,
    item_total: PropTypes.string,
    sum_deposit: PropTypes.string,
    sum_fee: PropTypes.string,
    total: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
}

export default SummaryBox

