import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import forEach from 'lodash/forEach'
import { BlocksRenderer } from '@strapi/blocks-react-renderer'
import { border, color, layout } from 'styled-system'
import { Col, Row } from 'react-bootstrap'
import Box from '../../box/Box'

const ShadowedCardLayout = ({
  custom,
  image,
  isImgRight,
  isTextOnly = false,
  content,
  wrapperFlexProps,
}) => {

  useEffect(() => {
    let links = document.querySelectorAll('.shadowed-card-layout a')
    forEach(links, (link) => {
      if (link.hostname !== window.location.hostname) {
        link.target = '_blank'
      }
    })
  }, [])

  return (
    <ShadowedBox
      borderRadius={30}
      className="shadowed-card-layout"
      flexDirection={wrapperFlexProps.flexDirection}
      ml={[0, 0, isImgRight ? 40 : '150px']}
      mr={[0, 0, isImgRight ? '150px' : 40]}
      my={7}
    >
      <RowHeight height={['auto']}>
        <ColorBox
          bg={custom?.boxBg || (isImgRight ? 'box.secondary' : 'box.primary')}
          md={{ order: isImgRight ? 1 : 2, span: isTextOnly ? 12 : 7 }}
        >
          <Box>
            <BlocksRenderer content={content} />
          </Box>
        </ColorBox>

        {!isTextOnly &&
        <ColWithBg
          bg={custom?.boxBg || (isImgRight ? 'box.secondary' : 'box.primary')}
          height={[200, 400, 'auto']}
          md={{ order: isImgRight ? 2 : 1, span: 5 }}
          style={{ padding: 0 }}
        >
          <ImageBg
            bgimage={`${image?.data?.attributes?.url}`}
          />
        </ColWithBg>
        }
      </RowHeight>
    </ShadowedBox>
  )}

const RowHeight = styled(Row)`
  ${layout}

  min-height: 350px;
`

const ColorBox = styled(Col)`
  ${border}
  ${color}

  display: flex;
  align-items: center;

  padding: 0 70px;

  @media (max-width: 1200px) {
    padding-top: 10px;
    padding-bottom: 10px;

  }

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`

const ColWithBg = styled(Col)`
  ${layout}
  ${color}
`

const ImageBg = styled(Box)`
  background-image: url(${props => props.bgimage});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  width: auto;
  height: auto;

  min-height: 100%;
`

const ShadowedBox = styled(Box)`
  overflow: hidden;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
`

ShadowedCardLayout.propTypes = {
  content: PropTypes.array,
  custom: PropTypes.object,
  image: PropTypes.object,
  isImgRight: PropTypes.bool,
  isTextOnly: PropTypes.bool,
  wrapperFlexProps: PropTypes.object,
}

export default ShadowedCardLayout
