import React, { useState } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Col, Container, Image, Navbar, Row } from 'react-bootstrap'
import { Cart } from '../../components'
import { Hide } from '../../elements'
import { scrollToElement } from '../../utils'
import { Banner, NavigationMenu, UserMenu, UserMenuDropdown } from '../'
import newImages from '../../../assets/images/newcb'
import { handleClickOutside } from './header-utils'
import './header.scss'

const Header = () => {
  let location = useLocation()
  const [navExpanded, setNavExpanded] = useState(false)

  React.useEffect(() => {
    scrollToElement()
  }, [location])

  let domNode = handleClickOutside(() => {
    setNavExpanded(false)
  })

  const closeNav = () => {
    setNavExpanded(false)
    scrollToElement()
  }

  return (
    <React.Fragment>
      <header className="header-outer">
        <Container className="container-header">
          <Row>
            <Col>
              <Navbar bg="light" className="header-inner" expand="lg" expanded={navExpanded} onToggle={setNavExpanded}>
                <Navbar.Brand>
                  <Link className="navbar-brand logo" onClick={scrollToElement} to="/">
                    <Image alt="Loop and Charlie Banana logo" className="header-logo" fluid src={newImages.headerLogo} />
                  </Link>
                </Navbar.Brand>

                <div ref={domNode} style={{ width: '100%' }}>
                  <Hide above={992}>
                    <CartOnMobile>
                      <Cart />
                    </CartOnMobile>
                  </Hide>

                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse className={!navExpanded && 'closed'} id="basic-navbar-nav">
                    <NavigationMenu closeNav={closeNav} />
                    <UserMenu expanded={navExpanded} handleMenuClick={setNavExpanded} />
                  </Navbar.Collapse>
                </div>
              </Navbar>

              <UserMenuDropdown expanded={navExpanded} handleMenuClick={setNavExpanded} />
            </Col>
          </Row>
        </Container>
      </header>

      <Banner data-testid="banner" />
    </React.Fragment>
  )
}

const CartOnMobile = styled.div`
  position: absolute;

  top: 18px;
  right: 80px;

  @media (min-width: 992px) {
    display: none; 
  }

`

export default Header
