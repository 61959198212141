import React, { createContext, PureComponent } from 'react'
import PropTypes from 'prop-types'

const { Consumer, Provider } = createContext()

class ResizeProvider extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      innerWidth: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    clearTimeout(this.onResizeTimeout)
    window.removeEventListener('resize', this.onResize)
  }

  onResizeTimeout = null

  onResize = () => {
    if (!this.onResizeTimeout) {
      this.onResizeTimeout = setTimeout(() => {
        this.onResizeTimeout = null
        this.setState({ innerWidth: window.innerWidth })
      }, 66)
    }
  }

  render() {
    const { innerWidth } = this.state

    return (
      <Provider value={{ innerWidth }}>
        {this.props.children}
      </Provider>
    )
  }
}

ResizeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Consumer as ResizeConsumer }
export default ResizeProvider
