import React from 'react'
import { Navigate } from 'react-router-dom'
import { Auth, withProfile } from '../modules'
import { userPropTypes } from '../types'
import { addNotification, trackEvent } from '../utils'

export const LogoutPage = ({ user }) => {
  if (user?.isLoading) {
    return null
  }

  if (user.me && !user.isLoading) {
    Auth.removeToken()

    user.refetch()

    addNotification({
      messageId: 'notificationLogoutMsg',
      titleId: 'notificationSuccessTitle',
      type: 'success',
    })

    trackEvent('User', 'Logged out', 'Logout Page')
  }

  return <Navigate to="/" />
}

LogoutPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(LogoutPage)
