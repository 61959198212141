import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'
import { unionBy } from '../../lodash'
import { withProfile } from '../../modules'
import { Box, Col, Checkbox, Currency, Text } from '../../elements'
import { userPropTypes } from '../../types'
import client from '../../api/api'

const ShippingMethods = ({ defaultSelectedId, onChange, selectedShippingMethodId, user }) => {
  const [shippings, setShippings] = useState(null)

  useEffect(() => {
    getShippingMethods()
  }, [defaultSelectedId])

  const getShippingMethods = async () => {
    const { data, included } = (await client.checkout.shippingRates({ orderToken: user.cart.token })).success()

    const shippingMethodList = {
      methods: unionBy(included.filter(item => item.type === 'shipping_rate'), 'attributes.cost'),
      ...data[0],
    }
    setShippings(shippingMethodList)

    if (defaultSelectedId) {
      const defaultMethod = shippingMethodList.methods.filter(method => method.id === defaultSelectedId.toString())[0]

      if (defaultMethod?.id)
        onChange(shippingMethodList.id, defaultMethod.id)
    }
  }

  return (
    <Form.Group controlId="shippingMethods">
      {shippings && shippings.methods.map(method => (
        <Col key={method.id} md="6" mx="auto" my={3}>
          <Box border="1px solid" borderColor="black" borderRadius={5} px={3}>
            <Checkbox
              checked={selectedShippingMethodId === method.id}
              labelTextId="blankTextId"
              labelTextIdValue={{
                text: (
                  <Text as="span" fontWeight="bold" key={method.id}>
                    {method.attributes.name} - {method.attributes.cost === '0.0' ? <FormattedMessage id="free" /> : <Currency value={method.attributes.cost} />}
                  </Text>
                ),
              }}
              name={`shipping_method_${method.id}`}
              onChange={() => onChange(shippings.id, method.id)}
            />
          </Box>
        </Col>
      ))}
    </Form.Group>
  )
}

ShippingMethods.propTypes = {
  defaultSelectedId: PropTypes.number,
  onChange: PropTypes.func,
  selectedShippingMethodId: PropTypes.string,
  user: userPropTypes,
}

export default withProfile(ShippingMethods)
