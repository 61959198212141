import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button } from '../../elements'
import { Auth, withProfile } from '../../modules'
import { userPropTypes } from '../../types'
import { addNotification, trackEvent } from '../../utils'
import client from '../../api/api'

export const AddToCart = ({ user, productId }) => {
  const handleClick = async (variantId) => {
    const bearerToken = await Auth.getAndCheckAccessToken()
    const hasUser = !!user.me
    const hasCart = !!user.cart

    if (hasUser && !bearerToken) {
      user.refetch(true)

      user.redirectToLogin()

      return false
    }

    if (!hasCart) {
      const responseCreateCart = await client.cart.create({ bearerToken })

      if (responseCreateCart.isSuccess()) {
        const { data } = responseCreateCart.success()

        if (!hasUser) {
          Auth.setCartToken(data.attributes.token)

          user.refetchCart()
        }
      }
    }

    const addItemToCartResponse = await client.cart.addItem(
      user.getCartToken(),
      {
        quantity: 1,
        variant_id: variantId,
      },
    )

    if (addItemToCartResponse.isSuccess()) {
      user.refetchCart()

      addNotification({
        messageId: 'notificationItemAdded',
        titleId: 'notificationSuccessTitle',
        type: 'success',
      })

      trackEvent('User', 'Added item to the cart', 'Products Page')
    } else {
      addNotification({
        backendError: addItemToCartResponse.fail().serverResponse.data.error,
        messageId: 'notificationSomethingWentWrong',
        titleId: 'notificationErrorTitle',
        type: 'danger',
      })
    }
  }

  return (
    <Button
      className="add-to-cart"
      id="recovery-button"
      onClick={() => handleClick(productId)}
      type="submit"
      variant="primary"
    >
      <FormattedMessage id="addToCart" />
    </Button>
  )
}

AddToCart.propTypes = {
  productId: PropTypes.string,
  user: userPropTypes,
}

export default withProfile(AddToCart)
