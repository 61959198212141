import React from 'react'
import styled from 'styled-components'
import { Navigate } from 'react-router-dom'
import { isEmpty } from '../../lodash'
import { position } from 'styled-system'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import { Box, Heading, HeadingInfoBox, Hide, Text } from '../../elements'
import { CartItem, SummaryBox } from '../../components'
import { TranslatedTitleLoader, withProfile } from '../../modules'
import { userPropTypes } from '../../types'
import images from '../../../assets/images/newcb'

export const ShoppingCartPage = ({ user }) => {
  if (user.isLoading)
    return null

  if (!user.cart || isEmpty(user.cart.line_items))
    return <Navigate to="/products" />

  return (
    <Container className="mt-5 mb-5">

      <TranslatedTitleLoader titleId="pageTitleCart" />

      <Row className="shopping-cart-page">
        <Col md="12">
          <Heading as="h2" className="shopping-cart-page-title" my={[0, 5]} textAlign="left">
            <FormattedMessage id="shoppingCart" />
          </Heading>
        </Col>
      </Row>

      <Row>
        <Box alignItems="flex-start" display="flex" flexWrap={['wrap', 'nowrap']}>
          <Box flex="1 1 auto" mr={[0, 6, 7]} my={[4, 6]}>
            {user.cart.line_items.map(item =>
              <CartItem item={item} key={item.id} />,
            )}

            <Alert variant="info">
              <Text mb={0} p={3}>
                <FormattedMessage id="freePickUpDescription" />
              </Text>
            </Alert>
          </Box>

          <StickyBox flex="1 1 500px" mt={[1, -80]} position="sticky" top={[0, 90]} zIndex={1}>
            <HeadingInfoBox fontWeight="normal" maxWidth={350} mb={-20} mx="auto" px={50} py={40}>
              <Hide below={500}>
                <CustomArrowBox />
              </Hide>

              <FormattedMessage
                id="comparePrice"
                values={{
                  calculatedPrice: <b><FormattedNumber currency="USD" style="currency" value={2800} /></b>,
                }}
              />
            </HeadingInfoBox>

            <SummaryBox cart={user.cart} />
          </StickyBox>
        </Box>
      </Row>
    </Container>
  )
}

const StickyBox = styled(Box)`
  ${position}
`

const CustomArrowBox = styled(Box)`
  position: absolute;

  top: 60px;
  right: -60px;

  background-image: url(${props => images.curvedDarkArrow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;

  width: 120px ;
  height: 120px;
`

ShoppingCartPage.propTypes = {
  user: userPropTypes,
}

export default withProfile(ShoppingCartPage)
