import { Row } from 'react-bootstrap'
import styled from 'styled-components'
import {
  background,
  border,
  layout,
  position,
  typography,
  space,
} from 'styled-system'

const StyledRow = styled(Row)`
  ${background}
  ${border}
  ${layout}
  ${position}
  ${typography}
  ${space}
`

StyledRow.displayName = 'Row'

export default StyledRow
