import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

export const Currency = ({ value }) => (
  <FormattedNumber currency="USD" style="currency" value={value} />
)

Currency.displayName = 'Currency'

Currency.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
}

export default Currency
