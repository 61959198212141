import React from 'react'
import getDisplayName from '../../../hoc-utils/getDisplayName'
import { StoreContext } from '../../'

export const withStoreConfig = (Component) => {
  const WithConfig = (props) => (
    <StoreContext.Consumer>
      {data => <Component {...props} store={data} />}
    </StoreContext.Consumer>
  )

  WithConfig.displayName = `withStoreConfig(${getDisplayName(Component)})`

  return WithConfig
}
