import axios from 'axios'

const strapiInstance = axios.create({
  baseURL: 'https://loopdiapers.loopcms.app/api',
})

strapiInstance.defaults.headers.post['Content-Type'] = 'application/json'
strapiInstance.defaults.headers.common['Authorization'] = `bearer ${process.env.STRAPI_TOKEN}`

export const getData = async (request) => {
  await strapiInstance.get(`/${request}`) //?locale=${locale}
}

export default strapiInstance

