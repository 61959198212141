import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Form } from 'react-bootstrap'

const hasWhiteSpace = (string) => /\s/g.test(string)

const ErrorMessage = ({ error }) => (
  <Form.Control.Feedback className="textfield-form-control-feedback" type="invalid">
    {hasWhiteSpace(error) ? error : <FormattedMessage id={error} />}
  </Form.Control.Feedback>
)

ErrorMessage.propTypes = {
  error: PropTypes.string,
}

export default ErrorMessage
