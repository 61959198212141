import PropTypes from 'prop-types'

export default PropTypes.shape({
  creditCard: PropTypes.shape({
    card_type: PropTypes.string,
    cardholder_name: PropTypes.string,
    expiration_month: PropTypes.string,
    expiration_year: PropTypes.string,
    last_4: PropTypes.string,
  }),
  getTokenForRequest: PropTypes.func,
  isLoading: PropTypes.bool,
  me: PropTypes.shape({
    billing_address: PropTypes.shape({
      city: PropTypes.string,
      country_code: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.number,
      last_name: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      street1: PropTypes.string,
      street2: PropTypes.string,
      zip_code: PropTypes.string,
    }),
    email: PropTypes.string,
    id: PropTypes.string.isRequired,
    shipping_address: PropTypes.shape({
      city: PropTypes.string,
      country_code: PropTypes.string,
      first_name: PropTypes.string,
      id: PropTypes.number,
      last_name: PropTypes.string,
      phone: PropTypes.string,
      state: PropTypes.string,
      street1: PropTypes.string,
      street2: PropTypes.string,
      zip_code: PropTypes.string,
    }),
  }),
  redirectToLogin: PropTypes.func,
  refetch: PropTypes.func,
})
