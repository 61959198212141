import React from 'react'
import styled from 'styled-components'
import { Bag, BagFill } from 'react-bootstrap-icons'
import { Box, Link, Text } from '../../elements'
import { withProfile } from '../../modules'
import { userPropTypes } from '../../types'

const Cart = ({ user }) => (
  <StyledLink to="/cart">
    <Box color="gray.dark" fontSize={6} mx="15px">
      {
        !user.loading && user.cart
          ? (
            <>
              <BagFill style={{ marginTop: '-5px' }} />
              <Text as="span" color="gray.dark" ml={3}>
                {user.cart.item_count}
              </Text>
            </>
          )
          : <Bag style={{ marginTop: '-5px' }} />
      }
    </Box>
  </StyledLink>
)

const StyledLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

Cart.propTypes = {
  user: userPropTypes,
}

export default withProfile(Cart)
